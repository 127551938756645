import { Component, OnInit } from '@angular/core';
import { AddressDetails } from 'src/app/model/memberAddressInfo.model';
import { MyProfileModel } from 'src/app/model/myProfile.model';
import { MemberAddressService } from 'src/app/services/memberAddress.service';
import { MyProfileService } from 'src/app/services/myProfile.service';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-my-info-dashboard',
  templateUrl: './my-info-dashboard.component.html',
  styleUrls: ['./my-info-dashboard.component.css']
})
export class MyInfoDashboardComponent implements OnInit {

  myProfile:MyProfileModel;
  addressDetails:AddressDetails;
    localModel: any;
    constructor(private myProfileService: MyProfileService, private memberAddressService: MemberAddressService,
        private localizationService: LocalizationService) {
        
        }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });

    this.myProfileService.getMemberProfile().subscribe(res =>{
      //console.log(this.myProfileService.getProfile().Age);
      this.myProfileService.setProfile(res.Data.MemberInfo);
      //console.log(this.myProfileService.getProfile());
      });
    
      this.myProfileService.myProfileChanged.subscribe(res => {
        this.myProfile = res;
        this.memberAddressService.getMemberAddress(this.myProfile.MNO);
        //console.log(res);
      });  
      
    
    this.memberAddressService.addressChanged.subscribe(res => {
      this.addressDetails = res;
     // console.log(res);
    });
  }

}
