<!-- Main Content Here -->
<section class="my-phone" *ngIf="myProfile">
    <img
        src="../../../assets/images/people/bg_change_phone_numbers.png"
        alt=""
        class="my-phone__bg-img"
    />

    <div class="container">
        <div class="container--form">
            <h1 [innerHtml]="localModel.lbl_MyProfile_title"></h1> 	

            <h2 [innerHtml]="localModel.lbl_ChangeNameEmail_Title" ></h2>

            <form class="form" [formGroup]="changeNameOrEmail" (ngSubmit)="onSubmit()">
                <div class="form-control">
                    <label for="firstName" class="form-control__label" [innerHtml]="localModel.lbl_ChangeNameEmail_Fname"></label>
                    <input type="text" id="firstName" formControlName="firstName" name="firstName"  />
                    <span *ngIf="!changeNameOrEmail.get('firstName').valid && changeNameOrEmail.get('firstName').touched">
                        <span *ngIf="changeNameOrEmail.get('firstName').errors?.pattern">{{localModel.lbl_changenameandemail_valid_1}}</span>
                        <span *ngIf="changeNameOrEmail.get('firstName').errors?.maxlength">{{localModel.lbl_changenameandemail_valid_fname_2}}</span>
                        <span *ngIf="changeNameOrEmail.get('firstName').errors?.required">{{getRequiredErrorMessage('fname')}}</span>
                    </span>
                </div>
                <div class="form-control">
                    <label for="lastName" class="form-control__label" [innerHtml]="localModel.lbl_ChangeNameEmail_Lname"></label>
                    <input type="text" id="lastName" formControlName="lastName" name="lastName" />
                    <span *ngIf="!changeNameOrEmail.get('lastName').valid && changeNameOrEmail.get('lastName').touched">
                        <span *ngIf="changeNameOrEmail.get('lastName').errors?.pattern">{{localModel.lbl_changenameandemail_valid_1}}</span>
                        <span *ngIf="changeNameOrEmail.get('lastName').errors?.maxlength">{{localModel.lbl_changenameandemail_valid_lname_2}}</span>
                        <span *ngIf="changeNameOrEmail.get('lastName').errors?.required">{{getRequiredErrorMessage('lname')}}</span>
                    </span>
                </div>
                <hr />
                <div class="form-control">
                    <label for="emailAddress" class="form-control__label"
                    [innerHtml]="localModel.lbl_ChangeNameEmail_EmailAdd" > </label
                    >
                    <input
                        type="email"
                        id="emailAddress" formControlName="emailAddress"
                        />
                    <span *ngIf="changeNameOrEmail.get('emailAddress').errors?.pattern">{{lbl_changenameandemail_valid_3}}</span><br/>    
                    <span *ngIf="!changeNameOrEmail.get('emailAddress').valid && changeNameOrEmail.get('emailAddress').touched">{{getRequiredErrorMessage('mail')}}</span>    
                </div>
                <div class="form-control">
                    <label for="confirmEmailAddress" class="form-control__label"
                    [innerHtml]="localModel.lbl_ChangeNameEmail_ConfirmEmailAdd"></label>
                    <input
                        type="email"
                        id="confirmEmailAddress" formControlName="confirmEmailAddress" 
                        />
                        
                        <span *ngIf="changeNameOrEmail.get('confirmEmailAddress').errors?.maxlength">First Name can be max 50 characters long.</span><br/> 
                        <span *ngIf="isConfirmEmaildisable && changeNameOrEmail.controls.confirmEmailAddress?.errors?.incorrect && (changeNameOrEmail.get('confirmEmailAddress').touched || changeNameOrEmail.get('confirmEmailAddress').dirty)">{{localModel.lbl_ChangeNameEmail_ConfirMailMatch}}</span>
                        <br/>
                        <span *ngIf="isConfirmEmaildisable && !changeNameOrEmail.get('confirmEmailAddress').valid && changeNameOrEmail.get('confirmEmailAddress').touched">{{getRequiredErrorMessage('cnfrmmail')}}</span>        
                </div>
                <div class="form__actions">
                    <button type="submit" class="btn btn__primary" [disabled]="!changeNameOrEmail.valid"   [innerHtml]="localModel.btn_ChangeNameEmail_Save" >    </button>
                    <button
                        type="button"
                        class="btn btn__secondary"
                        routerLink="/myprofile"
                        [innerHtml]="localModel.btn_ChangeNameEmail_Cancel"
                    >
                         
                    </button>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- loading spinner -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.4)"></ngx-spinner>