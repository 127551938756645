import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AddressInfoData } from 'src/app/model/addressInfoData.model';
import { StateDetails } from 'src/app/model/stateList.model';

@Component({
  selector: 'app-change-address-secondary',
  templateUrl: './change-address-secondary.component.html'
})
export class ChangeAddressSecondaryComponent implements OnInit {

  secondaryAddressDetails: AddressInfoData = new AddressInfoData();
  stateList: Array<StateDetails> = [];
  constructor(public data: AddressInfoData) { 
    this.secondaryAddressDetails = data;
  }

  ngOnInit(): void {
  }

  onSubmit(form : NgForm){
   // console.log(form);
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  getRequiredErrorMessage(fieldName: string) {
    var msg = '';

    switch (fieldName) {
      case 'add1':
        msg = "Address required max length should be 250"
        break;
      case 'city':
        msg = "City is required field"
        break;
      case 'state':
        msg = "State is required field"
        break;
      case 'zip':
        msg = "Zip is required with numbres only and max length should be 10"
        break;
      default:
        break;
    }

    return msg;
  }

}
