<div class="panel earning-rewards">
    <h2>{{localModel.lbl_hereswhatyoubeenearning}}</h2>
    <p>{{localModel.lbl_Asoflastsaturday}}</p>
    <div class="cards-rewards">
        <!-- Router -->
        <div class="card-reward">
            <div class="card-reward--icon">
                <img src="assets/images/devices/router.svg" alt="" />
            </div>
            <div class="card-reward--content">
                <h3>{{localModel.lbl_reward_router}</h3>
                <p>
                    {{localModel.lbl_connectallyourwifi}}
                </p>
            </div>
            <div class="card-reward--amount">
                <div class="reward-value">1000</div>
                <div class="reward-units">{{localModel.lbl_reward_points}}</div>
            </div>
        </div>

        <!-- Card 2 -->
        <div class="card-reward">
            <div class="card-reward--icon">
                <img src="assets/images/devices/meter.svg" alt="" />
            </div>
            <div class="card-reward--content">
                <h3>{{localModel.lbl_reward_tv}}</h3>
                {{localModel.lbl_checkintoscreenwisetvmeter}}
            </div>
            <div class="card-reward--amount">
                <div class="reward-value">1000</div>
                <div class="reward-units">{{localModel.lbl_reward_points}}</div>
            </div>
        </div>

        <!-- Card 3 -->
        <div class="card-reward card-reward--disabled">
            <div class="card-reward--icon">
                <img src="assets/images/devices/tv.svg" alt="" />
            </div>
            <div class="card-reward--content">
                <h3>{{localModel.lbl_reward_computer}}</h3>
                {{localModel.lbl_useabrowser_screenwisemeterbrowser}}
            </div>
            <div class="card-reward--amount">
                <div class="reward-value">1000</div>
                <div class="reward-units">{{localModel.lbl_reward_points}}</div>
            </div>
        </div>

        <!-- Card 4 -->
        <div class="card-reward">
            <div class="card-reward--icon">
                <img src="assets/images/devices/smartphone.svg" alt="" />
            </div>
            <div class="card-reward--content">
                <h3>{{localModel.lbl_reward_phone}}</h3>
                {{localModel.lbl_reward_useaphonescreenwisemeter}}
            </div>
            <div class="card-reward--amount">
                <div class="reward-value">1000</div>
                <div class="reward-units">{{localModel.lbl_reward_points}</div>
            </div>
        </div>

        <!-- Card 5 -->
        <div class="card-reward">
            <div class="card-reward--icon">
                <img src="assets/images/devices/tablet.svg" alt="" />
            </div>
            <div class="card-reward--content">
                <h3>Tablet</h3>
                Use a tablet with the Screenwise Meter app set up
            </div>
            <div class="card-reward--amount">
                <div class="reward-value">1000</div>
                <div class="reward-units">{{localModel.lbl_reward_points}}</div>
            </div>
        </div>

        <!-- Card 6 -->
        <div class="card-reward">
            <div class="card-reward--icon">
                <img src="assets/images/icons/icon_surprise.svg" alt="" />
            </div>
            <div class="card-reward--content">
                <h3>{{localModel.lbl_reward_bonus}}</h3>
                {{localModel.lbl_reward_receiveabonus}}
            </div>
            <div class="card-reward--amount">
                <div class="reward-value">2000</div>
                <div class="reward-units">{{localModel.lbl_reward_points}}</div>
            </div>
        </div>
    </div>
</div>  