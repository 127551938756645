import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LocalizationService } from '../services/localization.service';

@Component({
  selector: 'app-donot-sell-info',
  templateUrl: './donot-sell-info.component.html',
  styleUrls: ['./donot-sell-info.component.css']
})
export class DonotSellInfoComponent implements OnInit {

  localModel : any;

  constructor(private titleService: Title, private localizationService: LocalizationService) { }

  ngOnInit(): void {
    
    this.localModel = this.localizationService.getLocalization();
    this.titleService.setTitle(this.localModel.lbl_DNSMI_browsertitle)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_DNSMI_browsertitle)
      })
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
  }

}
