import { Directive, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[appAutoMoveNext]'
})
export class AutoMoveNextDirective {

  constructor() { }

  @Input('appAutoMoveNext')  appAutoMoveNext;

  @HostListener('input',['$event.target']) onInput(input){
    const length = input.value.length
    const maxlength = input.attributes.maxlength.value
    if(length>=maxlength)
    {
      this.appAutoMoveNext.focus()
    }
  }

}
