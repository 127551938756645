import { Injectable } from '@angular/core';
import { ResourcesModel } from './ResourcesModel';

@Injectable()
export class AppGlobals {
    readonly baseAppUrl: string = 'http://localhost:57431/';
    readonly baseAPIUrl: string = 'https://api.github.com/';
    // readonly enroll : string = "enroll";
    // readonly agree:string = "agree";
    localization:ResourcesModel[];
}