import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { AddressDetails } from 'src/app/model/memberAddressInfo.model';
import { MyProfileModel } from 'src/app/model/myProfile.model';
import { MemberAddressService } from 'src/app/services/memberAddress.service';
import { MyProfileService } from 'src/app/services/myProfile.service';
import { LocalizationService } from 'src/app/services/localization.service';


@Component({
  selector: 'app-my-address-info',
  templateUrl: './my-address-info.component.html'
})
export class MyAddressInfoComponent implements OnInit {

  myProfile:MyProfileModel;
  memberAddress:AddressDetails;
  localModel:any;
  constructor(private myProfileService:MyProfileService,private memberAddressService:MemberAddressService,
      private router: Router, private localizationService: LocalizationService) { 
      
  }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
    this.myProfileService.myProfileChanged.subscribe(res => {
      this.myProfile = res;
      //console.log(this.myProfile);
      
    });

    
    this.memberAddressService.addressChanged.subscribe((res:AddressDetails) => {
       this.memberAddress = res;
       
    });
    
  }

  onChangeAddress(){
    //routerLink="myProfile.IsPrimary == true ? '/change-address' : '/change-s-address'"
   //this.myProfile.IsPrimary == true ? this.router.navigate(['change-address']) : this.router.navigate(['change-s-address']);
   this.router.navigate(['change-address']);
  }
}
