import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sow-panel-hardware-usage-return-agreement',
  templateUrl: './sow-panel-hardware-usage-return-agreement.component.html'
})
export class SowPanelHardwareUsageReturnAgreementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
