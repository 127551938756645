<footer class="footer">
    <div class="footer__contact">
      <img src="assets/images/logos/SCREENWISE COLOR.svg" class="footer__logo" alt="footer" />

      <p>
        <strong  [innerHtml]="localModel.lbl_footer_Phone_Heading"></strong>
        <a href="tel:1-888-355-9555"  [innerHtml]="localModel.lbl_footer_phone"></a>
      </p>
      <p>
        <strong  [innerHtml]="localModel.lbl_footer_Email_Heading"></strong>
        <a href="mailto:support@screenwisepanel.com"  [innerHtml]="localModel.lbl_footer_email"></a>
      </p>
    </div>

    <div class="footer__nav">
      <h4  [innerHtml]="localModel.Lbl_footer_navigation"></h4>
      <ul>
        <li *ngIf="isUserSignIn"><a routerLink="/dashboard"  [innerHtml]="localModel.Lbl_footer_mydashTitle"></a></li>
        <li *ngIf="isUserSignIn"><a routerLink="/myrewards"  [innerHtml]="localModel.Lbl_footer_myrewaTitle"></a></li>
        <li *ngIf="isUserSignIn"><a routerLink="/myprofile"  [innerHtml]="localModel.Lbl_footer_myprofileTitle"></a></li>
        <li *ngIf="isUserSignIn"><a routerLink="/helpcenter"  [innerHtml]="localModel.Lbl_footer_helpcenterTitle"></a></li>
        <li><a routerLink="/contact-us"  [innerHtml]="localModel.Lbl_footer_contactTitle"></a></li>
      </ul>
    </div>

    <div class="footer__copyright">
      <h4  [innerHtml]="localModel.Lbl_footer_helpinfo"></h4>
      <a routerLink="/policies-legal"  [innerHtml]="localModel.Lbl_footer_policyTitle"></a>
      <a routerLink="/donot-sell-info"  [innerHtml]="localModel.Lbl_footer_dnsinfoTitle"></a>     
      
    </div>

    <div class="footer__language">
      <h4  [innerHtml]="localModel.lbl_footer_language"></h4>
      <select [(ngModel)]="selected1" name="lang" placeholder="select" (ngModelChange)="onLanguageChange($event)">
        <option *ngFor="let l of Langs" [ngValue]="l.languageId">{{l.languageName}}
        </option>
    </select>
    </div>
  </footer>
  <div class="sponsor">
    <p id="macfeesponsor">{{localModel.lbl_a_consumer_research}} <span class="nowrap">© {{fullyear }} Ipsos</span></p>
  </div>