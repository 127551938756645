import { Component, Input,OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mcafee',
  templateUrl: './mcafee.component.html'
})
export class McafeeComponent implements OnInit {

  @Input() floatValue: string;

  appDomainName: string;
  antiVirusIntLink: string;


  constructor() {
    // this.appDomainName = AppConfig.configurations.appSettings.AppDomainName;
    this.appDomainName = environment.AppDomainName;
    this.getAppDomainName();

   }

  ngOnInit(): void {
  }
  getAppDomainName() {
    this.antiVirusIntLink = `//www.mcafeesecure.com/RatingVerify?ref=${this.appDomainName}`;
  }
}
