import { Component, OnInit, Output,EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ForgotPassword } from 'src/app/model/forgotPassword.model';
import { ForgotPasswordService } from 'src/app/services/forgotPassword.service';
import { LocalizationService } from 'src/app/services/localization.service';


@Component({
  selector: 'app-check-email-id',
  templateUrl: './check-email-id.component.html'
})
export class CheckEmailIdComponent implements OnInit {
  @Output() closeCheckEmailId = new EventEmitter<void>();
  email:string;
  localModel : any;
  returnUrl: any;
  forgotPassword: ForgotPassword;
  constructor(private localizationService:LocalizationService,private forgotPasswordService:ForgotPasswordService,private router:Router,private route:ActivatedRoute,) {   
  }
  

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
    this.scrollOnTheView();
    var emailfromsession=sessionStorage.getItem('email');
    if(emailfromsession!==null){
      var censorWord = function (str) {
        if(str.length>3){
          return str[0]+str[1]+str[2] + "*".repeat(str.length-3) 
        }
        return str[0] + "*".repeat(str.length-1) 
     }
     
     
     var censorEmail = function (email){
          var arr = email.split("@");         
          var arr2= arr[1].split(".");
          return censorWord(arr[0]) + "@" + censorWord(arr2[0]) +"."+arr2[1];
     }

      this.email=censorEmail(emailfromsession)
    }   
  //  this.email = this.forgotPasswordService.forgotPassword.email;
   
  }


  onCloseCheckEmailId(){
    this.forgotPassword = new ForgotPassword();
    this.forgotPassword.isSharedEmailId=false
    this.forgotPasswordService.checkEmailIdChanged.next(this.forgotPassword);
    //this.router.navigate(['/'])
  }
  scrollOnTheView(){
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
}
}
