import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-help-centre-header',
  templateUrl: './help-centre-header.component.html',
  styleUrls: ['./help-centre-header.component.css']
})
export class HelpCentreHeaderComponent implements OnInit {

  localModel : any;
  constructor(private localizationService:LocalizationService) { 
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
  
  }

  ngOnInit(): void {
  }

}
