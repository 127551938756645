import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ForgotPassword } from 'src/app/model/forgotPassword.model';
import { IResponse } from 'src/app/model/Response/IResponse';
import { ForgotPasswordService } from 'src/app/services/forgotPassword.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { ReCaptcha2Component } from 'ngx-captcha';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {
  @ViewChild('captchaElem', { static: true }) recaptcha: ReCaptcha2Component
  @Output() closeForgorPassword = new EventEmitter<void>();
  public forgotPassword:ForgotPassword;
  response: IResponse;
  localModel : any;
  forgotPasswordChanged = new Subject<ForgotPassword>();
  showCaptchaValidation:boolean=false;
  sitekey:string;
  screenwidth:number;
  public theme: 'light' | 'dark' = 'light';
  public size: string;
  public lang = 'en';
  public type: 'image' | 'audio';

  constructor(private localizationService:LocalizationService,private forgotPasswordService:ForgotPasswordService,private router:Router,
    private route:ActivatedRoute,private spinner:NgxSpinnerService,private titleService:Title) {
      if(localStorage.getItem("SiteValue")!==null) 
      this.sitekey = this.localizationService.decryptValue(localStorage.getItem("SiteValue"));
     }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    this.titleService.setTitle(this.localModel.lbl_home_title)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_home_title)
      })

    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
      this.lang=this.localizationService.getlocalizationfromSession();
      this.lang=this.lang=='english'?'en':'es';
    });
    this.screenwidth = window.innerWidth;
    this.size = this.screenwidth <= 400 ? "compact" : "normal";
    this.scrollOnTheView()
  }
  handleReload(){
    this.lang = this.localizationService.getlocalizationfromSession();
    if(this.lang==null){
      this.lang='en';
      return;
    }
    this.lang=this.lang=='english'?'en':'es';
  }
  onClose(){
  this.closeForgorPassword.emit();
  //this.router.navigate(['signin'])
  }

  onSendMeLink(passwordin:NgForm){
    if(passwordin.invalid){
      return;
    }
    if(passwordin.value.recaptcha==="" || passwordin.value.recaptcha=== undefined){
      this.showCaptchaValidation = true;
        window.scrollTo(0, 0);
        this.spinner.hide();
        return;
    }
    this.forgotPassword = new ForgotPassword();
    this.forgotPassword.email=passwordin.value.email;
    sessionStorage.setItem('email',this.forgotPassword.email);
    this.forgotPassword.panelName=environment.PanelName;
    this.forgotPassword.captcha=passwordin.value.recaptcha;

    this.getForgotPasswordData(this.forgotPassword);
    //call http service to check if it is shared email id else send email
  }

  async getForgotPasswordData(data:any){
    this.spinner.show();
    this.response= await this.forgotPasswordService.getForgotPassword(data);
    if (this.response != null && this.response.Data["isshared"] == true){
      this.forgotPassword.isSharedEmailId=false;
    }else{
      this.forgotPassword.isSharedEmailId=this.response.Data["IsInvalid"]==true?false:true; 
    }
    this.spinner.hide();
    this.forgotPasswordService.forgotPasswordChanged.next(this.forgotPassword);
  }
  // onContactUs(){
  //   this.router.navigate(['../contact-us'])
  // }

  scrollOnTheView(){
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
}

onCloseSection(){
  this.showCaptchaValidation = false;
  this.recaptcha.resetCaptcha();
}

@HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = window.innerWidth;
    this.size = this.screenwidth <= 400 ? "compact" : "normal";
  }
}
