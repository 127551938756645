export class UpdatePhoneInfo{
    MNO:number;
    PanelMemberId:string;
    HHNO:number;
    NewNumber:number;
    ExistingNumber:number;
    ExitingPhoneType:number;
    NewPhoneType:number;
    IsPreferred:number;
    PersonalPhoneSameasHH:boolean;
    OperationType:number=5;
    OperationMessage:string = "Phone Info updated via UMP self-service";

    constructor(mno:number,panelmemberid:string,hhno:number,newNumber:number,existingNumber:number,
        exitingPhoneType:number,newPhoneType:number,isPreferred:number,personalPhoneSameasHH:boolean){
            this.MNO = mno;
            this.PanelMemberId = panelmemberid;
            this.HHNO = hhno;
            this.NewNumber = newNumber;
            this.ExistingNumber = existingNumber;
            this.ExitingPhoneType = exitingPhoneType;
            this.NewPhoneType = newPhoneType;
            this.IsPreferred = isPreferred;
            this.PersonalPhoneSameasHH = personalPhoneSameasHH;
        }

  }
  