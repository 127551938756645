import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-redeem-rewards',
  templateUrl: './redeem-rewards.component.html'
})
export class RedeemRewardsComponent implements OnInit {
  localModel: any;
  

  constructor(private localizationService: LocalizationService) { }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();

    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
      
    });
  }

}
