import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Globals } from "src/app/shared/shared.global";
import { environment } from "src/environments/environment";



@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    apiBasePath: string;
    apiKey: string;
    httpOptions: { headers: HttpHeaders; };
    notificationRead=new Subject<boolean>();
    notification = new Subject<boolean>();

    constructor(private http: HttpClient) {
        this.apiBasePath = environment.CustomMsg_API_Base_Path;
        this.httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            })
          };
    }
    getNotifications(mno: number) {
        return this.http.post(this.apiBasePath + Globals.GetCustomMessage,mno , this.httpOptions);
    }
    setReadNotificationFlag(panelistNotificationsId: number) {       
        this.http.post(this.apiBasePath + Globals.UpdateReadNotificationFlag, panelistNotificationsId, this.httpOptions).subscribe((res) => {
           if(res==1){
            this.notificationRead.next();
           }           
        });
    }
    
}
