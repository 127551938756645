<!-- Policies Preferences-->
<section class="policies-legal angular-1">
    <div class="container">
        <div class="policy-cards-wrapper">
            <!-- Card 1 -->
            <div class="card" routerLink='/google-panel-privacy-policy'>
                <div  *ngIf="isGooglePrivacyPolicyConsented" class="card__img">
                    <img [hidden]="isUserLogin"
                        src="assets/images/icons/icon_consented.svg"
                        alt="Consented to Google Panel Privacy Policy"
                    />
                </div>
                <div  *ngIf="!isGooglePrivacyPolicyConsented" class="card__img">
                    <img [hidden]="isUserLogin" src="assets/images/icons/icon_not_consented.svg" 
                    alt="Not Consented to Google Panel Privacy Policy"/>                
                </div>
                <div class="card__title">
                    <h3 [innerHtml]="localModel.lbl_MyProfile_GooglePrivacyPolicy"></h3>
                </div>
                <div class="card__body">
                    <p  [innerHtml]="localModel.lbl_MyProfile_GooglePrivacyPolicy_Details">                         
                    </p>
                     
                </div>
                <div [hidden]="isUserLogin" *ngIf="isGooglePrivacyPolicyConsented" class="card__footer card__footer--wavy" [innerHtml]="localModel.lbl_MyProfile_Consented" ></div>
                <div [hidden]="isUserLogin" *ngIf="!isGooglePrivacyPolicyConsented" class="card__footer card__footer--needs-consent">{{localModel.lbl_MyProfile_Not_Consented}}</div>
 
            </div>

            <!-- Card 2 -->
            <div class="card" routerLink='/google-panel-terms-condition'>
                <div  *ngIf="isTNCPolicyConsented" class="card__img">
                    <img [hidden]="isUserLogin"
                        src="assets/images/icons/icon_consented.svg"
                        alt="Consented to Google Panel Terms and Conditions"
                    />
                </div>
                <div  *ngIf="!isTNCPolicyConsented" class="card__img">
                    <img  [hidden]="isUserLogin" src="assets/images/icons/icon_not_consented.svg" 
                    alt="Not Consented to Google Panel Privacy Policy"/>
              
                </div>
                <div class="card__title">
                    <h3 [innerHtml]="localModel.lbl_MyProfile_GoogleTandC" ></h3> 
                </div>
                <div class="card__body">
                    <p  [innerHtml]="localModel.lbl_MyProfile_GoogleTandC_Details"	>                         
                    </p>
                </div>
                <div [hidden]="isUserLogin" *ngIf="isTNCPolicyConsented" class="card__footer card__footer--wavy" [innerHtml]="localModel.lbl_MyProfile_Consented"> </div>
                <div [hidden]="isUserLogin" *ngIf="!isTNCPolicyConsented" class="card__footer card__footer--needs-consent">{{localModel.lbl_MyProfile_Not_Consented}}</div>
 
            </div>

            <!-- Card 3 -->
            <div class="card" routerLink='/ipsos-Sow-privacy-policy'>
                <div  *ngIf="isPrivacyPolicyConsented" class="card__img">
                    <img [hidden]="isUserLogin"
                        src="assets/images/icons/icon_consented.svg"
                        alt="Consented to Ipsos Screenwise Panel Privacy Policy"
                    />
                </div>
                <div  *ngIf="!isPrivacyPolicyConsented" class="card__img">
                    <img [hidden]="isUserLogin" src="assets/images/icons/icon_not_consented.svg" 
                    alt="Not Consented to Ipsos Screenwise Panel Privacy Policy"/>
              
                </div>
                <div class="card__title">
                    <h3 [innerHtml]="localModel.lbl_MyProfile_IpsosScreenwisePanelPrivacyPolicy"> </h3>
                </div>
                <div class="card__body">
                    <p [innerHtml]="localModel.lbl_MyProfile_IpsosScreenwisePanelPrivacyPolicy_Dt">
                        
                    </p>
               </div>
                <div [hidden]="isUserLogin" *ngIf="isPrivacyPolicyConsented"class="card__footer card__footer--wavy" [innerHtml]="localModel.lbl_MyProfile_Consented"></div>
                <div [hidden]="isUserLogin" *ngIf="!isPrivacyPolicyConsented" class="card__footer card__footer--needs-consent">{{localModel.lbl_MyProfile_Not_Consented}}</div>
 
            </div>

            <!-- Card 4 -->
            <div class="card" routerLink='/cookie-policy'>
                <div *ngIf="isCookiePolicyConsented" class="card__img">
                    <img [hidden]="isUserLogin"
                        src="assets/images/icons/icon_consented.svg"
                        alt="Consented to Ipsos Screenwise Panel Cookie Policy"
                    />
                </div>
                <div  *ngIf="!isCookiePolicyConsented" class="card__img">
                    <img [hidden]="isUserLogin" src="assets/images/icons/icon_not_consented.svg" 
                    alt="Not Consented to Ipsos Screenwise Panel Cookie Policy"/>
            
                </div>
                <div class="card__title">
                    <h3 [innerHtml]="localModel.lbl_MyProfile_IpsosScreenwisePanelCookiePolicy"> </h3>
                </div>
                <div class="card__body">
                    <p [innerHtml]="localModel.lbl_MyProfile_IpsosScreenwisePanelCookiePolicy_Dt">
                        
                    </p>
                </div>
                <div [hidden]="isUserLogin" *ngIf="isCookiePolicyConsented" class="card__footer card__footer--wavy" [innerHtml]="localModel.lbl_MyProfile_Consented"></div>
                <div [hidden]="isUserLogin" *ngIf="!isCookiePolicyConsented" class="card__footer card__footer--needs-consent">{{localModel.lbl_MyProfile_Not_Consented}}</div>
 
            </div>

            <!-- Card 5 -->
            <div class="card" routerLink='/hardware-policy'>
                <div  *ngIf="isEulaPolicyConsented" class="card__img">
                    <img [hidden]="isUserLogin" 
                        src="assets/images/icons/icon_consented.svg"
                        alt="Consented to Ipsos Screenwise Panel Hardware Usage and Return Agreement"
                    />
                </div>
                <div  *ngIf="!isEulaPolicyConsented" class="card__img">
                    <img  [hidden]="isUserLogin"  src="assets/images/icons/icon_not_consented.svg" 
                    alt="Not Consented to Ipsos Screenwise Panel Hardware Usage and Return Agreement"/>         
                </div>
                <div class="card__title">
                    <h3 [innerHtml]="localModel.lbl_MyProfile_IpsosScreenwisePanelReturnAgreement" > </h3>
                </div>
                <div class="card__body">
                    <p [innerHtml]="localModel.lbl_MyProfile_IpsosScreenwisePanelReturnAgreement_" >
                         
                    </p>
                </div>
                <div [hidden]="isUserLogin"  *ngIf="isEulaPolicyConsented" class="card__footer card__footer--wavy" [innerHtml]="localModel.lbl_MyProfile_Consented"> </div>
                <div  [hidden]="isUserLogin"  *ngIf="!isEulaPolicyConsented" class="card__footer card__footer--needs-consent">{{localModel.lbl_MyProfile_Not_Consented}}</div>
 
            </div>

            <!-- Card 6 -->
            <div  *ngIf="beforeLogin1" class="card card--optout" routerLink="/contact-us">
                <div  class="card__img">
                    <img 
                        src="assets/images/icons/icon_optout.svg"
                        alt="Opt out of Google and Ipsos Screenwise Policies"
                    />
                </div>
                <div  class="card__title">
                    <h3 [innerHtml]="localModel.lbl_MyProfile_OptOut" > </h3>
                </div>
                <div class="card__body">
                    <p   [innerHtml]="localModel.lbl_MyProfile_OptOut_Dt" >
                       
                    </p>
                     
                </div>
            </div>
        </div>
    </div>
</section>