import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserLogInfo } from '../model/UserInfo';
import { SignInService } from './signin.service';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor{
  userDetails: UserLogInfo;
  constructor(private loginservice:SignInService) { }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {  
  //  console.log("Intercept method called from JWTInterceptor..");  
    // add authorization header with jwt token if available  
    var token = this.loginservice.gettoken();
    if (token) {
      var userDataFromSession= localStorage.getItem('currentuser');
      //var userDataFromSession=sessionStorage.getItem('myProfile')
       this.userDetails = JSON.parse(userDataFromSession);
       request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + this.userDetails.token) });
       }
       return next.handle(request).pipe(catchError(x=> this.handleAuthError(x)));
     
}

private handleAuthError(err: HttpErrorResponse): Observable<any> {
  //handle your auth error or rethrow
  if(err.status===401 && err.message.match("Authorization has been denied for this request.")){
  }
  else if(err.status === 401 ) {     
    this.loginservice.logout();
  }
  this.loginservice.navigateHomepage();
  return throwError(err);
}
}
