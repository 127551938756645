<section class="messaging important-notification">
    <div class="container">

        <div class="messaging__body">
            <p [innerHTML]="messageBody"></p>
            <br />
        </div>

        <div class="messaging__actions">           
            <button class="btn btn__important" data-messaging-action="close" (click)="navigateToMyProfileLink()">
              {{ messageButtonTextOk }}
            </button>
        </div>

    </div>
</section>