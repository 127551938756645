<!-- Main Content Here -->
<section class="my-phone">
    <img src="assets/images/people/bg_change_phone_numbers.png" alt="" class="my-phone__bg-img"/>

    <div class="container">
        <div class="container--form">
            <h1 [innerHtml]="localModel.lbl_MyProfile_title"> </h1>

            <h2 [innerHtml]="localModel.lbl_ChangePhone_Title"> </h2>

            <form class="form" (ngSubmit)="onSubmit(f)" #f="ngForm">
                <div class="form--2col">
                    <div class="form--2col-col">
                        <h3 [innerHtml]="localModel.lbl_MyProfile_HouseholdPhone"> </h3>

                        <div class="form--2col-col" *ngIf="!myProfile.IsPrimary">
                            
    
                            <div class="form-control">
                                <div class="form-control__label"[innerHtml]="localModel.lbl_ChangePhone_PhoneN"> </div>
                                <p id="householdPhone"><strong>{{myProfile.HHPhoneNumber}}</strong></p>
                            </div>
                            <div class="form-control">
                                <div class="form-control__label" [innerHtml]="localModel.lbl_ChangePhone_mobileCell">
                                     
                                </div>
                                <p id="isHouseholdMobilePhoneChecked">
                                    <strong>{{myProfile.HouseholdIsMobile == 1 ? "Yes" : "No"}}</strong>
                                </p>
                            </div>
                            <p [innerHtml]="localModel.lbl_ChangePhone_PrimaryCanCng">                                 
                            </p>
                        </div>

                        <div class="form-control" *ngIf="myProfile.IsPrimary">
                            <label for="homePhoneNumber" class="form-control__label" [innerHtml]="localModel.lbl_ChangePhone_PhoneN"></label>
                            <input
                                type="text"
                                id="homePhoneNumber"
                                [(ngModel)]="myProfile.HHPhoneNumber"  
                                name="homePhoneNumber"
                                maxlength="14"
                                required
                                
                                #homePhoneNumber                                 
                                (input)="validatePhoneNo($event.target)" placeholder="(___) ___-____" 
                            />
                            <span class="ng-star-inserted" *ngIf="!isHHValidFlg" [innerHtml]="localModel.lbl_ChangePhone_FormatInv" > </span><br/>
                           
                        </div>
                        <div class="form-control" *ngIf="myProfile.IsPrimary">
                            <div class="form-control__label" [innerHtml]="localModel.lbl_ChangePhone_mobileCell">
                                
                            </div>
                            <div class="btn-group-radio" >
                                <input
                                    type="radio"
                                    name="isMobilePhone"
                                    id="isMobilePhoneYes"
                                   [checked]="myProfile.HouseholdIsMobile == 1 ? 'checked' : ''"
                                   (change)="onItemChange(1,1)"              
                                    value="Yes" 
                                />
                                <label for="isMobilePhoneYes" [innerHtml]="localModel.lbl_myprofile_yes"> </label>
                                <input
                                    type="radio"                                    
                                    name="isMobilePhone"
                                    id="isMobilePhoneNo"                                                       
                                    value="No"
                                    (change)="onItemChange(2,1)"
                                    [checked]="myProfile.HouseholdIsMobile == 2 ? 'checked' : ''"
                                    checked                                                                    
                                                                    
                                />
                                
                                <label for="isMobilePhoneNo" [innerHtml]="localModel.lbl_myprofile_no"></label>                                
                            </div>
                        </div>
                    </div>
                    <div class="form--2col-col">
                        <h3 [innerHtml]="localModel.lbl_ChangePhone_PersonalPhoneN"></h3>
                        <div class="form-control">
                            <div [ngClass]="{'custom-checkbox custom-checkbox--right': true, 'disabled': isPersonalPhoneEmpty}">
                                <input type="checkbox" id="sameAsHouseholdPhone" [(ngModel)]="myProfile.PersonalPhoneSameasHH" [disabled]="isPersonalPhoneEmpty" name="sameAsHouseholdPhone"  />
                                <label for="sameAsHouseholdPhone" class="custom-checkbox__label" [innerHtml]="localModel.lbl_ChangePhone_PersonalPhoneN_Chk">
                                
                                </label>
                            </div>
                            <label for="personalPhoneNumber"  class="form-control__label" 
                            [ngClass]="myProfile.PersonalPhoneSameasHH  ? 'disabled' : ''"
                               [innerHtml]="localModel.lbl_ChangePhone_PhoneN" ></label
                            >
                            <input
                                type="text"
                                id="personalPhoneNumber"
                                [(ngModel)]="myProfile.PersonalPhoneSameasHH ? myProfile.HHPhoneNumber : myProfile.PPPhoneNumber"  
                                name="personalPhoneNumber"                                
                                maxlength="14"
                                required
                                (keypress)="numberOnly($event)"                               
                                #personalPhoneNumber="ngModel"
                                [disabled]="myProfile.PersonalPhoneSameasHH || isPersonalPhoneEmpty"
                                (input)="validatePhoneNo($event.target)" placeholder="(___) ___-____"
                            />
                            <span class="ng-star-inserted" *ngIf="!isPPValidFlg" [innerHtml]="localModel.lbl_ChangePhone_FormatInv" ></span><br/>
                           
                        </div>

                        <div class="form-control">
                            <div class="form-control__label" [ngClass]="myProfile.PersonalPhoneSameasHH  ? 'disabled' : ''" [innerHtml]="localModel.lbl_ChangePhone_mobileCell">
                                
                            </div>
                            <div class="btn-group-radio" [ngClass]="myProfile.PersonalPhoneSameasHH || isPersonalPhoneEmpty ? 'disabled' : ''">
                                <input
                                    type="radio"
                                    name="isPersonalMobile"
                                    id="isPersonalMobileYes"
                                    value="Yes"
                                    [checked]="myProfile.PersonalPhoneSameasHH ? myProfile.HouseholdIsMobile === 1 : myProfile.PersonalIsMobile === 1"
                                    (change)="onItemChange(1,2)"
                                    [disabled]="myProfile.PersonalPhoneSameasHH || isPersonalPhoneEmpty"
                                />
                                <label for="isPersonalMobileYes" [innerHtml]="localModel.lbl_myprofile_yes"></label>
                                <input 
                                    type="radio"                                    
                                    name="isPersonalMobile"                                    
                                    id="isPersonalMobileNo"  
                                    value="No"                                  
                                    [checked]="myProfile.PersonalPhoneSameasHH ? myProfile.HouseholdIsMobile === 2 : myProfile.PersonalIsMobile === 2"
                                    (change)="onItemChange(2,2)"
                                    [disabled]="myProfile.PersonalPhoneSameasHH || isPersonalPhoneEmpty"
                                />
                                <label for="isPersonalMobileNo" [innerHtml]="localModel.lbl_myprofile_no"> </label>
                            </div>
                        </div>
                    </div>
                </div>

                <h3  [innerHtml]="localModel.lbl_ChangePhone_CommunicationPreferences"></h3>

                <h4  [innerHtml]="localModel.lbl_ChangePhone_SMS"></h4>

                <div class="form-control__group">
                    <div class="btn-group-radio" [ngClass]="{'disabled': isPersonalPhoneEmpty}">
                        <input type="radio" name="isSMS" id="isSMSYes" value="Yes"
                         [checked]="myProfile.PersonalConsentSMS === 1" 
                         (change)="onItemChange(1,3)" 
                         [disabled]="isPersonalPhoneEmpty"/>
                        <label for="isSMSYes" [innerHtml]="localModel.lbl_myprofile_yes"></label>
                        <input
                            type="radio"
                            name="isSMS"
                            id="isSMSNo"
                            value="No" 
                            [checked]="myProfile.PersonalConsentSMS === 2"
                            (change)="onItemChange(2,3)"
                            [disabled]="isPersonalPhoneEmpty"
                        />
                        <label for="isSMSNo" [innerHtml]="localModel.lbl_myprofile_no"></label>
                    </div>
                    <p [innerHtml]="localModel.lbl_ChangePhone_SMS_Info">                        
                    </p>
                </div>

                <h4  [innerHtml]="localModel.lbl_ChangePhone_AutoDialer"></h4>

                <div class="form-control__group" >
                    <div class="btn-group-radio" [ngClass]="{'disabled': isPersonalPhoneEmpty}">
                        <input
                            type="radio"
                            name="isAutoDialer"
                            id="isAutoDialerYes"
                            value="Yes"
                            [checked]="myProfile.PersonalConsentIVR === 1"
                            (change)="onItemChange(1,4)"
                            [disabled]="isPersonalPhoneEmpty"
                        />
                        <label for="isAutoDialerYes" [innerHtml]="localModel.lbl_myprofile_yes"></label>
                        <input
                            type="radio"
                            name="isAutoDialer"
                            id="isAutoDialerNo"
                            value="No"
                            [checked]="myProfile.PersonalConsentIVR === 2"
                            (change)="onItemChange(2,4)"
                            [disabled]="isPersonalPhoneEmpty"
                        />
                        <label for="isAutoDialerNo" [innerHtml]="localModel.lbl_myprofile_no"></label>
                    </div>
                    <p [innerHtml]="localModel.lbl_ChangePhone_AutoDialer_Info" >
                         
                    </p>
                </div>

                <div class="form__actions">
                    <button type="submit" class="btn btn__primary" [disabled] ="!isHHValidFlg || !isPPValidFlg || isPersonalPhoneEmpty" [innerHtml]="localModel.btn_ChangeNameEmail_Save" ></button>
                    <button
                        type="button"
                        class="btn btn__secondary"
                        routerLink="/myprofile"
                        [innerHtml]="localModel.btn_ChangeNameEmail_Cancel"
                    >
                         
                    </button>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- loading spinner -->
<ngx-spinner></ngx-spinner>
