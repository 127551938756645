import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ProfilePhoneNumber } from 'src/app/model/phone-number.model';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-change-phone-numbers-secondary',
  templateUrl: './change-phone-numbers-secondary.component.html'
})
export class ChangePhoneNumbersSecondaryComponent implements OnInit {

  phoneNumberDetails : ProfilePhoneNumber;
  localModel : any;
  constructor(private phoneNumberInfo : ProfilePhoneNumber,private localizationService:LocalizationService) {
    this.phoneNumberDetails = phoneNumberInfo;  
    this.localModel = this.localizationService.getLocalization();  
   }

  ngOnInit(): void {
  
  }

  onSubmit(form : NgForm){         
  
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  getRequiredErrorMessage(fieldName: string) {
    var msg = '';

    switch (fieldName) {
      case 'homePhone':
        msg = "Home phone number required max length should be 10"
        break;
      case 'personalphone':
        msg = "Personal phone number required max length should be 10"
        break;
      
      default:
        break;
    }

    return msg;
  }


}
