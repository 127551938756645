import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-viewfaqpopup',
  templateUrl: './viewhelpcenterpopup.component.html',
  styleUrls: ['./viewhelpcenterpopup.component.css']
})
export class ViewHelpCenterPopupComponent implements OnInit {

  localModel: any;

  constructor(private dialogRef: MatDialogRef<ViewHelpCenterPopupComponent>,
    private localizationService:LocalizationService,private router: Router,) { 
  }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
  }

  onClose(){
    this.dialogRef.close(true);
    this.localizationService.setViewHelpCenterSession('false');
  }
  redirectToHelpCenter(){
    this.dialogRef.close(true);
    this.router.navigateByUrl('/helpcenter');
    this.localizationService.setViewHelpCenterSession('false');
  }

}
