<section class="messaging important-notification">
    <div class="container">
        
        <div class="messaging__close" >     
             <a class="point_type" (click)="onCloseButton()" data-messaging-action="close">
                <img src="assets/images/icons/icon_close.svg" alt="" />
            </a>
        </div>

        <div class="messaging__body">
            <h2>{{ header }}</h2>
            <p class="line-break" [innerHtml]="content"></p>
        </div>

        <div class="messaging__actions">           
            <button class="btn btn__important" data-messaging-action="close" (click)="onCloseButton()">
                {{ localModel.btn_Notification_One }}
            </button>

            <button class="btn btn__important" data-messaging-action="close" *ngIf="showExtraButton" (click)="onCloseButton()">
                {{ localModel.btn_Notification_Two }}
            </button>
        </div>

    </div>
</section>
