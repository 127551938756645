import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Device } from '../model/device.model';

@Injectable({providedIn:'root'})
export class DeviceService{

    private devices: Device[] = []; 
    private yourDevices: Device[] = [];
    devicesChange = new Subject<Device>();
    
    showAddNewDeviceModel = new BehaviorSubject<number>(null);
  

    getDevices(){
      const device = new Device(true,
            "assets/images/devices/router.svg",
            "Screenwise Router",
            "Last seen today at 10:31 AM",
            "<strong>Get Help</strong> with this Device");
        const device1 = new Device(true,
            "assets/images/devices/meter.svg",
            "Screenwise Meter app on iOS",
            "Last seen today at 10:31 AM",
            "<strong>Get Help</strong> with this Device");
        
        const device2 = new Device(false,
            "assets/images/devices/laptop.svg",
            "Screenwise Meter Browser Extension on Computer",
            "Not seen in the last week",
            "<strong>Get Help</strong> with this Device");
                

        this.devices.push(device);    
        this.devices.push(device1);
        this.devices.push(device2);
        return this.devices.slice();    
    }

    getYourDevices(){
        const device = new Device(true,
              "assets/images/devices/router.svg",
              "Screenwise Router",
              "Last seen today at 10:31 AM",
              "<strong>Get Help</strong> with this Device");
          const device1 = new Device(true,
              "assets/images/devices/meter.svg",
              "Screenwise Meter app on iOS",
              "Last seen today at 10:31 AM",
              "<strong>Get Help</strong> with this Device");
          
          const device2 = new Device(false,
              "assets/images/devices/laptop.svg",
              "Screenwise Meter Browser Extension on Computer",
              "Not seen in the last week",
              "<strong>Get Help</strong> with this Device");
                  
  
          this.yourDevices.push(device);    
          this.yourDevices.push(device1);
          this.yourDevices.push(device2);
          return this.yourDevices.slice();    
      }
}