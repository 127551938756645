
<div class="container">
    <div data-toggle="tooltip" data-placement="top" [title]="localModel.lbl_ChatBotToolTipMsg">
        <button (click)="getIframeContent()" class="chtBtn">
            <img src="assets/images/icons/talk-male.png" alt="icon" >             
            </button> 
    </div>    
</div>






