import { Directive, ElementRef, HostBinding, HostListener } from "@angular/core";
@Directive({
    selector:'[appHeaderShowHide]'
})
export class HeaderShowHideDirective{
    @HostBinding('class.open') isOpen = false;
    header = document.querySelector(".header");
    headerHeight = this.header.clientHeight;
    scrollHeaderHeightdelta = 40;
    lastScrollTop = 0;

    @HostListener('window:scroll',['$event']) showHideHeader(event:Event){
        var currentScrollTop = event.target['scrollingElement'].scrollTop;    
        
        if (Math.abs(this.lastScrollTop - currentScrollTop) <= this.scrollHeaderHeightdelta) {
            return; // hide or show header
        }
        let header = document.getElementById('navHeader');
        
        if (currentScrollTop > this.lastScrollTop && currentScrollTop > this.headerHeight) {
            header.classList.add("header__scrolled");
        } else {
            header.classList.remove("header__scrolled");
          }
      
        this.lastScrollTop = currentScrollTop;
    }

    constructor(private elRef:ElementRef){
        
    }
}