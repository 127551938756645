<!-- Panel: My Info -->
<div class="panel" *ngIf="myProfile">
    <div class="panel__header">
       <h2>{{localModel.lbl_dash_myInfoHeader}}</h2>
       <div class="panel__cta">
          <a routerLink="/myprofile" class="btn btn__primary btn--small">{{localModel.lbl_dash_myInfoRouteLink}}</a>
       </div>
    </div>
    <div class="panel__body myinfo">
       
       <div class="myinfo__basic">
          <div class="myinfo__img">
             <img src="assets/images/icons/icon_profile.svg" alt="myinfoicon"/>
          </div>
          <div class="myinfo__name-email">
             <h3 class="heading__name">{{myProfile.FullName}}</h3>
             <a href="mailto:{{ myProfile.EmailAddress1 }}">{{ myProfile.EmailAddress1 }}</a>
          </div>
       </div>
       
       <h3 class="heading__phone">{{localModel.lbl_dash_myInfoPerPhone}}</h3>
       <div class="myinfo__phone">
          <div class="myinfo__phone-home">
             <h4>{{localModel.lbl_dash_myInfoHHPhone}}</h4>
             <p *ngIf="myProfile.HHPhoneNumber;else hhPhone">{{myProfile.HHPhoneNumber}}</p>
             <ng-template #hhPhone>
               <p>{{localModel.lbl_myprofile_phone_placeholder}}</p>
             </ng-template>
          </div>
          <div class="myinfo__phone-personal">
             <h4>{{localModel.lbl_dash_myInfoPhone}}</h4>
             <p *ngIf="myProfile.PPPhoneNumber;else ppPhone">{{myProfile.PPPhoneNumber}}</p>
             <ng-template #ppPhone>
                <p>{{localModel.lbl_myprofile_phone_placeholder}}</p>
             </ng-template>
          </div>
       </div>
       
       <h3 class="heading__address">{{localModel.lbl_dash_myInfoAdd}}</h3>
       <div class="myinfo__address">
          <div class="myinfo__address-item" *ngIf="addressDetails">
              <h4>{{localModel.lbl_dash_myInfoResAdd}}</h4>
             <p [innerHtml]="addressDetails?.Residential?.CompleteAddress">
             </p>
          </div>
          
          <div class="myinfo__address-item">
              <h4>{{localModel.lbl_dash_myInfoShipAdd}}</h4>
              <p *ngIf="addressDetails?.Shipping?.SameAsResidenceForShipping">
                  {{localModel.lbl_dashboard_Same_Resd}}
              </p>
             <p *ngIf="addressDetails?.Shipping?.SameAsMailingForShipping">
               {{localModel.lbl_sameasmailing}}
            </p>
             <p *ngIf="!addressDetails?.Shipping?.SameAsResidenceForShipping && !addressDetails?.Shipping?.SameAsMailingForShipping"
              [innerHtml]="addressDetails?.Shipping?.CompleteAddress">
            </p>
          </div>
          
          <div class="myinfo__address-item">
              <h4>{{localModel.lbl_dash_myInfoMailAdd}}</h4>
              <p *ngIf="addressDetails?.Mailing?.SameAsResidenceForMailing">
                  {{localModel.lbl_dashboard_Same_Resd}}
              </p>
             <p *ngIf="!addressDetails?.Mailing?.SameAsResidenceForMailing"
              [innerHtml]="addressDetails?.Mailing?.CompleteAddress">
            </p>
          </div>
          
       </div>
          
          
    </div>   
 </div>