import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-genericerror',
  templateUrl: './genericerror.component.html',
  styleUrls: ['./genericerror.component.css']
})
export class GenericerrorComponent implements OnInit {

  localModel: any;
  constructor(    private localizationService: LocalizationService,private titleService:Title,private spinner: NgxSpinnerService) { 
    this.localizationService.localizationChanged.subscribe(res => {
      this.spinner.show();
            setTimeout(() => {
              /** spinner ends after 5 seconds */
              this.spinner.hide();
          }, 3000);
      this.localModel = res;

    });
  }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    this.titleService.setTitle(this.localModel.lbl_home_title)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_home_title)
      })  
  }

}
