import { Component, OnInit, ContentChildren, QueryList } from '@angular/core';
 import { HelpCenterQuestionAnwser } from 'src/app/model/help-center.model';
import { HelpCenterService } from 'src/app/services/helpcenter.service';
import { ModalService } from 'src/app/_modal';
import { Router } from '@angular/router';
import { LocalizationService } from 'src/app/services/localization.service';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-help-centre-content',
  templateUrl: './help-centre-content.component.html',
  styleUrls: ['./help-centre-content.component.css']
})
export class HelpCentreContentComponent implements OnInit {
  private window :any =window;
   helpcenterQuestionAnswerModels : HelpCenterQuestionAnwser[];
  toggle : boolean =false;
  isHidden = false;
  @ContentChildren('btn2') panels : QueryList<'HelpCentreContentComponent'>;
  opened : string;
  btnvalue : string;
  indexes : number[] = [];
  sections : string[];
  
  isOpenIndex:number;
  show:string;
  localModel : any;
  constructor(private helpcenterservice : HelpCenterService,private modalService: ModalService,private route:Router,private localizationService:LocalizationService,private titleService:Title) {
    this.localModel = this.localizationService.getLocalization();
    this.titleService.setTitle(this.localModel.lbl_helpcenter_browsertitle)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_helpcenter_browsertitle)
      })

    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
   }

   ngOnInit(): void {
    this.helpcenterQuestionAnswerModels = this.helpcenterservice.getQuestions();
 }

 onClick(index:number,event){
  this.isOpenIndex = index;
 }

 onAnsClick(index:number,event){
    document.getElementById("hcQuestion").innerHTML=event.target.innerHTML;
    document.getElementById("hcAnswer").innerHTML=document.getElementById("ans"+index).innerHTML;
    document.getElementById("myModal").style.display="block";  
    let top = document.getElementById('custom-model-content');
    if (top !== null) {
      top.scrollIntoView();
      //top == null;
    }
 }

 onCloseModel(){
  document.getElementById("myModal").style.display="none";  
 }

 openModal(id: string) {
  this.modalService.open(id);
}

 ontoggleitem(index){
  if(this.indexes.includes(index)){
    this.toggle = !this.toggle;  
  }
  else
  {
    this.toggle = false;    
  }
}

toggleAccordion(index:number){
  if(this.indexes.includes(index)){
    const removedIndex = this.indexes.indexOf(index);
    this.indexes.splice(removedIndex,1);
  }
  else
  {
  this.indexes.push(index);  
  }
}
}