<section class="do-not-sell">
    <div class="container">

      <div class="container--info">
        <h1 >{{localModel.lbl_DoNotSell_title}}</h1>

        <div class="form-layout">
          <div class="col-form">
            <h1>{{localModel.lbl_DoNotSell_OptOut}}</h1>
            <p  [innerHtml]="localModel.lbl_DoNotSell_OptOut_para1"></p>
            <p  [innerHtml]="localModel.lbl_DoNotSell_OptOut_para2"></p>
            <p class="opt-out-cta" [innerHtml]="localModel.lbl_DoNotSell_OptOut_para3"></p>
            <p  [innerHtml]="localModel.lbl_DoNotSell_OptOut_para4"></p>
          </div>

          <div class="sidebar">
            <h3>{{localModel.lbl_DoNotSell_title}}</h3>
            <p  [innerHtml]="localModel.lbl_DoNotSell_btnDoesntWork"></p>
            <p  [innerHtml]="localModel.lbl_DoNotSell_btnDoesntWork_para3_1"></p>
            <p  [innerHtml]="localModel.lbl_DoNotSell_btnDoesntWork_para1"></p>
            <p  [innerHtml]="localModel.lbl_DoNotSell_btnDoesntWork_para2"></p>
          </div>
        </div>
      </div>

    </div>    
  </section>