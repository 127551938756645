export class PolicyData{
    privacyPolicyAccepted:number;
    PrivacyPolicyAcceptDate:Date;
    cookiePolicyAccepted:number;
    CookiePolicyAcceptDate :string;
    termsnConditionAccepted :number;
    TermsnConditionAcceptDate :string;
    googlePrivacyPolicyAccepted:number;
    GooglePrivacyPolicyAcceptDate:Date;
    eulaAccepted:number;
    EulaAcceptDate:Date;
    withdrawnrequestinitiated :boolean;
    Withdrawnrequestinitiateddate:string;
    Ips_withdrawnstatus :boolean;
    IsPrivacyPolicyUpdated :boolean;
    IsCookiePolicyUpdated :boolean;
    IsTermsNConditionPolicyUpdated :boolean;
    IsGooglePrivacyPolicyUpdated:boolean;
    IsEulaPolicyUpdated :boolean;
    LastUpdateMessage :string;
    EnforcePolicy:number;
    FrameLink:string;
    lang : number;
    MNo:number;
    PanelName:string;
    Flag:string;
    consenttype:string;
    RequestFromAppl:string;
    consent:number;
    respondentID:number;
    Localization:string;
    enforcepolicies:boolean;
}
