<div class="rewards-info">

    <h3 [innerHtml]="this.localModel.lbl_Myreward_howyearnmonthly"></h3>

      
        <p [innerHtml]="this.localModel.lbl_Myreward_routernew"></p>

        <p [innerHtml]="this.localModel.lbl_Myreward_browsernew"></p>

        <p [innerHtml]="this.localModel.lbl_Myreward_mobilenew"></p>

        <p [innerHtml]="this.localModel.lbl_Myreward_tabletnew"></p>

        <p [innerHtml]="this.localModel.lbl_Myreward_bonusnew"></p>

        <h4 [innerHtml]="this.localModel.lbl_Myreward_Note"> </h4>


</div>