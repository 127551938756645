import { Component, OnInit } from '@angular/core';
import { MyProfileModel } from 'src/app/model/myProfile.model';
import { MemberAddressService } from 'src/app/services/memberAddress.service';
import { MyProfileService } from 'src/app/services/myProfile.service';
import { LocalizationService } from 'src/app/services/localization.service';


@Component({
  selector: 'app-my-info',
  templateUrl: './my-info.component.html',
  styleUrls: ['./my-info.component.css']
})
export class MyInfoComponent implements OnInit {

    myProfile: MyProfileModel;
    localModel: any;
    constructor(private myProfileService: MyProfileService, private memberAddress: MemberAddressService, private localizationService: LocalizationService) {
        
    }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
    this.myProfileService.myProfileChanged.subscribe(res => {
      this.myProfile = res;
      
    });
  }

}
