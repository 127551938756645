import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/shared/shared.global';
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AdrService {

  apiBasePath: string;
  apiKey: string;

  constructor(private http: HttpClient) {
    this.apiBasePath = environment.AdrUrl;
   }


  getADRPINURL(MNO: number){  
    let httpOptions:Object  = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'    
      }),
      responseType: 'text'
    };
     var urladr = Globals.GetAdrUrl + '?unique_id='+MNO + '&PanelName=SOW2';
     return this.http.get(this.apiBasePath + urladr,httpOptions);
  }
}
