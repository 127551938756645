import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EmailVerificationData } from "src/app/model/myProfile.model";
import { Globals } from "src/app/shared/shared.global";
import { environment } from "src/environments/environment";



@Injectable({
    providedIn: 'root'
})
export class EmailVerificationService {

    apiBasePath: string;   
    httpOptions: { headers: HttpHeaders; };

    constructor(private http: HttpClient) {

        this.apiBasePath = environment.GoogleIntegration_API_Base_Path;    
        this.httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',           
            })
          };
    }

    getEmailVerificationLink(data: EmailVerificationData) {

      return this.http.post(this.apiBasePath + Globals.GetEmailVerificationLink, data, this.httpOptions);
    }

}