<div class="my-info">
    <div class="my-info__profile-img">
        <img src="assets/images/icons/icon_profile.svg" alt="" />
    </div>
    <div class="my-info__name">
        <h3 class="fullname">{{myProfile.FullName}}</h3>
        <p>
            <a href="mailto:{{ myProfile.EmailAddress1 }}">{{ myProfile.EmailAddress1 }}</a>
        </p>
        <a routerLink="/change-name-email" class="btn btn__primary btn--small"
        [innerHtml]="localModel.btn_MyProfile_ChangeName" ></a
        >
    </div>
    <div class="my-info__password">
        <h3 [innerHtml]="localModel.lbl_MyProfile_Password"></h3>
        <p>*********</p>

        <a routerLink="/changepassword" class="btn btn__primary btn--small" [innerHtml]="localModel.btn_MyProfile_ChangePass"></a>
    </div>
    <div class="my-info__active-member">
        <h3>{{myProfile.MembershipString}} {{localModel.lbl_myprofile_member}}</h3>
        <p>{{myProfile.MemberStatusString}} {{localModel.lbl_myprofile_memberhousehold}}</p>
    </div>
</div>