<section class="help-center">
  <div class="container">
    <ul class="accordion">
      <li class="accordion__item">
        <button type="button" class="accordion__heading" [ngClass]="isOpenIndex == 1 ? 'open' : ''"
          (click)="onClick(1,$event)" data-target="sectionPanel">
         {{localModel.lbl_HelpCenter_Panel1}}
        </button>
        <div id="sectionPanel" class="accordion__description" [ngClass]="isOpenIndex == 1 ? 'open' : ''">
          <ul class="list-faq">
            <li>
              <a class="modal-link" (click)="onAnsClick(1,$event)">{{localModel.lbl_HelpCenter_Panel1_Ques1}}</a>
              <div class="answer" id="ans1">
                <p>{{localModel.lbl_HelpCenter_Panel1_Ques1_descr}}</p>
                <p>{{localModel.lbl_HelpCenter_Panel1_Ques1_descr2}}</p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(2,$event)">{{localModel.lbl_HelpCenter_Panel1_Ques2}}</a>
              <div class="answer" id="ans2">
                <p>
                 {{localModel.lbl_HelpCenter_Panel1_Ques2_para1}}
                <p>
                  {{localModel.lbl_HelpCenter_Panel1_Ques2_para2}} <a href="google-panel-privacy-policy"> {{localModel.lbl_HelpCenter_Panel1_Ques2_para3}}</a> {{localModel.lbl_HelpCenter_Panel1_Ques2_para4}}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </li>



      <li class="accordion__item">
        <button type="button" class="accordion__heading" [ngClass]="isOpenIndex == 12 ? 'open' : ''"
          (click)="onClick(12,$event)" data-target="sectionPanel">
         {{localModel.lbl_HelpCenter_Panel12}}
        </button>
        <div id="sectionPanel" class="accordion__description" [ngClass]="isOpenIndex == 12 ? 'open' : ''">
          <ul class="list-faq">
            <li>
              <a class="modal-link" (click)="onAnsClick(52,$event)">{{localModel.lbl_HelpCenter_Panel12_Ques1}}</a>
              <div class="answer" id="ans52">
                <p>{{localModel.lbl_HelpCenter_Panel12_Ques1_para1}} <a  routerLink="/myprofile" [innerHTML]="localModel.Lbl_footer_myprofileTitle"></a></p>
                <p>{{localModel.lbl_HelpCenter_Panel12_Ques1_para2}}</p>
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel12_Ques1_para3"></p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(53,$event)">{{localModel.lbl_HelpCenter_Panel12_Ques2}}</a>
              <div class="answer" id="ans53">
                <p [innerHTML]="localModel.lbl_HelpCenter_Panel12_Ques2_para1">
                <p>
                 {{localModel.lbl_HelpCenter_Panel12_Ques2_para2}}
                </p>
                <p [innerHTML]="localModel.lbl_HelpCenter_Panel12_Ques2_para3">
                 </p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(54,$event)">{{localModel.lbl_HelpCenter_Panel12_Ques3}}</a>
              <div class="answer" id="ans54">
                <p [innerHTML]="localModel.lbl_HelpCenter_Panel12_Ques3_para1"></p>
                <p [innerHTML]="localModel.lbl_HelpCenter_Panel12_Ques3_para3"></p>
              </div>
            </li>
          </ul>
        </div>
      </li>






      <li class="accordion__item">
        <button type="button" class="accordion__heading" [ngClass]="isOpenIndex == 2 ? 'open' : ''"
          (click)="onClick(2,$event)" data-target="sectionParticipation">
          {{localModel.lbl_HelpCenter_Panel2_title}}
        </button>
        <div id="sectionParticipation" class="accordion__description" [ngClass]="isOpenIndex == 2 ? 'open' : ''">
          <ul class="list-faq">
            <li>
              <a class="modal-link" (click)="onAnsClick(3,$event)"> {{localModel.lbl_HelpCenter_Panel2_Ques1}} </a>
              <div class="answer" id="ans3">
                <p>
                 {{localModel.lbl_HelpCenter_Panel2_Ques1_para1}}
                </p>
                <p>
                  {{localModel.lbl_HelpCenter_Panel2_Ques1_para2}}
                </p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(4,$event)">
                {{localModel.lbl_HelpCenter_Panel2_Ques2}}
              </a>
              <div class="answer" id="ans4">
                <p>
                 {{localModel.lbl_HelpCenter_Panel2_Ques2_Para1}}
                </p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(5,$event)">
                {{localModel.lbl_HelpCenter_Panel2_Ques3}}
              </a>
              <div class="answer" id="ans5">
                <p>
                  {{localModel.lbl_HelpCenter_Panel2_Ques3_para1}}
                  <a  [routerLink]="['/dashboard']">{{localModel.lbl_HelpCenter_Panel2_Ques3_para2}}</a>
                  {{localModel.lbl_HelpCenter_Panel2_Ques3_para3}}


                </p>
              </div>
            </li>
          </ul>
        </div>
      </li>

      <li class="accordion__item" [ngClass]="isOpenIndex == 3 ? 'open' : ''" (click)="onClick(3,$event)">
        <button type="button" class="accordion__heading" data-target="sectionDataAndPrivacy">
          {{localModel.lbl_HelpCenter_Panel3_title}}
        </button>
        <div id="sectionDataAndPrivacy" class="accordion__description" [ngClass]="isOpenIndex == 3 ? 'open' : ''">
          <ul class="list-faq">
            <li>
              <a class="modal-link" (click)="onAnsClick(6,$event)">
                {{localModel.lbl_HelpCenter_Panel3_Ques1}}
              </a>
              <div class="answer" id="ans6">
                <p>
                 {{localModel.lbl_HelpCenter_Panel3_Ques1_para1}}
                </p>
              </div>
            </li>
            <li> <a class="modal-link" (click)="onAnsClick(7,$event)">{{localModel.lbl_HelpCenter_Panel3_Ques2}}</a>
              <div class="answer" id="ans7">
                <p>
                 {{localModel.lbl_HelpCenter_Panel3_Ques2_para1}}
                </p>
              </div>
            </li>

            <li>
              <a class="modal-link" (click)="onAnsClick(8,$event)">{{localModel.lbl_HelpCenter_Panel3_Ques3}}</a>
              <div class="answer" id="ans8">
                <p>
                  {{localModel.lbl_HelpCenter_Panel3_Ques3_para1}}
                  <a [routerLink]="['/policies-legal']">{{ localModel.lbl_HelpCenter_Panel3_Ques3_para2}}</a>
                  {{ localModel.lbl_HelpCenter_Panel3_Ques3_para3}}
                </p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(9,$event)">{{localModel.lbl_HelpCenter_Panel3_Ques4}}</a>
              <div class="answer" id="ans9">
                  <p [innerHTML]="localModel.lbl_HelpCenter_Panel3_Ques4_para2">
                  <!-- {{ localModel.lbl_HelpCenter_Panel3_Ques4_para3}}  -->
                </p>
              </div>
            </li>
          </ul>
        </div>
      </li>

      <li class="accordion__item">
        <button type="button" class="accordion__heading" [ngClass]="isOpenIndex == 4 ? 'open' : ''" (click)="onClick(4,$event)">
          {{localModel.lbl_HelpCenter_Panel4_title}}
        </button>
        <div id="sectionGeneralInfo" class="accordion__description" [ngClass]="isOpenIndex == 4 ? 'open' : ''">
          <ul class="list-faq">
            <li>
              <a href="javascript:;" class="modal-link" (click)="onAnsClick(10,$event)">{{localModel.lbl_HelpCenter_Panel4_Ques1}}</a>

              <div class="answer" id="ans10">
                <p>
               {{localModel.lbl_HelpCenter_Panel4_Ques1_para1}}
                </p>
                <p>
                  {{localModel.lbl_HelpCenter_Panel4_Ques1_para2}}
                </p>
              </div>
            </li>

            <li>
              <a class="modal-link" (click)="onAnsClick(11,$event)">{{localModel.lbl_HelpCenter_Panel4_Ques2}}</a>
              <div class="answer" id="ans11">
                <p>
                 {{localModel.lbl_HelpCenter_Panel4_Ques2_para1}}
                  <a [routerLink]="['/contact-us']">{{localModel.Lbl_contactUS}}</a>.
                </p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(12,$event)">{{localModel.lbl_HelpCenter_Panel4_Ques3}}</a>
              <div class="answer" id="ans12">
                <p>
                 {{localModel.lbl_HelpCenter_Panel4_Ques3_para1}}
                  <a [routerLink]="['/contact-us']">{{localModel.Lbl_contactUS}}</a>.
                </p>
              </div>
            </li>

          </ul>
        </div>
      </li>


      <li class="accordion__item">
        <button type="button" class="accordion__heading" [ngClass]="isOpenIndex == 5 ? 'open' : ''" (click)="onClick(5,$event)">{{localModel.lbl_HelpCenter_Panel6_Ques2_para3}}</button>
        <div id="sectionRouter" class="accordion__description" [ngClass]="isOpenIndex == 5 ? 'open' : ''" >
          <ul class="list-faq">
            <li>
              <a  class="modal-link" (click)="onAnsClick(13,$event)">
                {{localModel.lbl_HelpCenter_Panel5_Ques1}}
              </a>
              <div class="answer" id="ans13">
                <p>
                  {{localModel.lbl_HelpCenter_Panel5_Ques1_para1}}
                  <a [routerLink]="['/contact-us']">{{localModel.lbl_Gppp_IAgree_Content2}}</a>.
                </p>
              </div>
            </li>

            <li>
              <a  class="modal-link" (click)="onAnsClick(14,$event)">
                {{localModel.lbl_HelpCenter_Panel5_Ques2}}
              </a>
              <div class="answer" id="ans14">
                <p>
                  {{localModel.lbl_HelpCenter_Panel5_Ques2_para1}}
                  <a [routerLink]="['/contact-us']">{{ localModel.lbl_Gppp_IAgree_Content2}}</a> {{ localModel.lbl_HelpCenter_Panel5_Ques2_para2}}
                </p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(15,$event)"> {{localModel.lbl_HelpCenter_Panel5_Ques3}} </a>
              <div class="answer" id="ans15">
                <p>
                  {{localModel.lbl_HelpCenter_Panel5_Ques3_para1}}
                        <a [innerHtml]="localModel.lbl_HelpCenter_Panel5_Ques3_para2"></a
                        >
                </p>
                <p>{{localModel.lbl_HelpCenter_Panel5_Ques3_para3}}</p>
                <ol>
                  <li>
                    {{localModel.lbl_HelpCenter_Panel5_Ques3_para4}}
                  </li>
                  <li>
                    {{localModel.lbl_HelpCenter_Panel5_Ques3_para5}}
                    <ol type="a">
                      <p [innerHtml]="localModel.lbl_HelpCenter_Panel5_Ques3_para6"></p>
                    </ol>
                  </li>
                  <li>
                    {{localModel.lbl_HelpCenter_Panel5_Ques3_para7}}
                    <ol type="a">
                      <p [innerHtml]="localModel.lbl_HelpCenter_Panel5_Ques3_para8"></p>
                      <li>
                        {{localModel.lbl_HelpCenter_Panel5_Ques3_para9}}
                        <a href="http://myrouter.local/signin" target="_blank" rel="noopener noreferrer">{{localModel.lbl_HelpCenter_Panel5_Ques3_para10}}</a>
                        {{localModel.lbl_HelpCenter_Panel5_Ques3_para11}}
                      </li>
                    </ol>
                  </li>
                </ol>
              </div>
            </li>

            <li>
              <a class="modal-link" (click)="onAnsClick(16,$event)"> {{localModel.lbl_HelpCenter_Panel5_Ques4}} </a>
              <div class="answer" id="ans16">
                <p>
                  {{localModel.lbl_HelpCenter_Panel5_Ques4_para1}}
                  <a href="http://myrouter.local/signin" target="_blank" rel="noopener noreferrer">{{localModel.lbl_HelpCenter_Panel5_Ques3_para10}}</a>
                  {{localModel.lbl_HelpCenter_Panel5_Ques4_para4}}
                </p>
                <p>
                  {{localModel.lbl_HelpCenter_Panel5_Ques4_para2}}
                </p>
                <p>
                  {{localModel.lbl_HelpCenter_Panel5_Ques4_para3}}
                  <a [innerHtml]="localModel.lbl_HelpCenter_Panel5_Ques4_para5"></a
                  >.
                </p>
              </div>
            </li>
            <li>
              <a  class="modal-link" (click)="onAnsClick(17,$event)">
                {{localModel.lbl_HelpCenter_Panel5_Ques5}}
              </a>
              <div class="answer" id="ans17">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel5_Ques5_para1"></p>
              </div>
            </li>

            <li>
              <a  class="modal-link" (click)="onAnsClick(18,$event)">
                {{localModel.lbl_HelpCenter_Panel5_Ques6}}
              </a>
              <div class="answer" id="ans18">
                <p>
                 {{localModel.lbl_HelpCenter_Panel5_Ques6_para1}}
                </p>
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel5_Ques6_para2"></p>
              </div>
            </li>
          </ul>
        </div>
      </li>

      <li class="accordion__item">
        <button type="button" class="accordion__heading" [ngClass]="isOpenIndex == 6 ? 'open' : ''" (click)="onClick(6,$event)">{{localModel.Lbl_ScreenWiseTVMeter}}</button>
        <div id="sectionTVMeter" class="accordion__description" [ngClass]="isOpenIndex == 6 ? 'open' : ''">
          <ul class="list-faq">
            <li>
              <a class="modal-link" (click)="onAnsClick(55,$event)" data-question="whatIsScreenwiseTvMeter">
                {{localModel.lbl_HelpCenter_Panel6_WhatistheScreenwiseTVMeter}}
              </a>
              <div class="answer" id="ans55">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel6_ScreenwiseTVMeter_para1"></p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(19,$event)" data-question="howDoISetUp">
                {{localModel.lbl_HelpCenter_Panel6_Ques1}}
              </a>
              <div class="answer" id="ans19">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel6_Ques1_para1"></p>
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel6_Ques1_para2">

                </p>

              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(56,$event)" data-question="ManageSmartTv">
                {{localModel.lbl_HelpCenter_Panel6_ManageSmartTv}}
              </a>
              <div class="answer" id="ans56">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel6_ManageSmartTv_para1"></p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(20,$event)" data-question="howDoIUse">
                {{localModel.lbl_HelpCenter_Panel6_Ques2}}
              </a>
              <div class="answer" id="ans20">
                <p>{{localModel.lbl_HelpCenter_Panel6_Ques2_para1}}</p>
                <p>
                  {{localModel.lbl_HelpCenter_Panel6_Ques2_para2}}
                </p>
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel6_Ques2_para3_1"></p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(21,$event)"  data-question="howDoIPause">
                {{localModel.lbl_HelpCenter_Panel6_Ques3}}
              </a>
              <div class="answer" id="ans21">
                <p>
                 {{localModel.lbl_HelpCenter_Panel6_Ques3_para1}}
                </p>
                <p>
                  {{localModel.lbl_HelpCenter_Panel6_Ques3_para2}}
                  <a  [innerHtml]="localModel.lbl_HelpCenter_Panel6_Ques3_para3"></a
                  >
                </p>
              </div>
            </li>
            <li>
              <a  class="modal-link" (click)="onAnsClick(22,$event)" data-question="howDoIFix">
                {{localModel.lbl_HelpCenter_Panel6_Ques4}}
              </a>
              <div class="answer" id="ans22">
                <p>
                 {{localModel.lbl_HelpCenter_Panel6_Ques4_para1}}
                </p>
                <p>
                  {{localModel.lbl_HelpCenter_Panel6_Ques4_para2}}
                  <a [innerHtml]="localModel.lbl_HelpCenter_Panel6_Ques4_para3"></a
                  >
                </p>
              </div>
            </li>

            <li>
              <a class="modal-link" (click)="onAnsClick(23,$event)" data-question="howDoIStop">
                {{localModel.lbl_HelpCenter_Panel6_Ques5}}
              </a>
              <div class="answer" id="ans23">
                <p>
                 {{localModel.lbl_HelpCenter_Panel6_Ques5_para1}}
                </p>
                <p>
                  {{localModel.lbl_HelpCenter_Panel6_Ques5_para2}}
                  <a [innerHtml]="localModel.lbl_HelpCenter_Panel6_Ques5_para3"></a
                  >
                </p>
              </div>
            </li>

          </ul>
        </div>
      </li>

      <li class="accordion__item">
        <button type="button" class="accordion__heading" [ngClass]="isOpenIndex == 7 ? 'open' : ''" (click)="onClick(7,$event)">
          {{localModel.lbl_HelpCenter_Panel7_title}}
        </button>
        <div id="sectionScreenwiseApp" class="accordion__description" class="accordion__description" [ngClass]="isOpenIndex == 7 ? 'open' : ''">
          <ul class="list-faq">
            <li>
              <a (click)="onAnsClick(24,$event)" class="modal-link" data-question="howDoISetUp"
                >{{localModel.lbl_HelpCenter_Panel7_Ques1}}</a
              >
              <div class="answer" id="ans24">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel7_Ques1_para1"></p>

                <p>
                  {{localModel.lbl_HelpCenter_Panel7_Ques1_para2}}
                  <a [innerHtml]="localModel.lbl_HelpCenter_Panel7_Ques1_para3"></a
                  >
                </p>
              </div>
            </li>
            <li>
              <a (click)="onAnsClick(25,$event)" class="modal-link" data-question="howDoIUse"
                >{{localModel.lbl_HelpCenter_Panel7_Ques2}}</a
              >
              <div class="answer" id="ans25">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel7_Ques2_para1"></p>

                <p>
                  {{localModel.lbl_HelpCenter_Panel7_Ques2_para2}}
                  <a [innerHtml]="localModel.lbl_HelpCenter_Panel7_Ques2_para3"></a
                  >
                </p>
              </div>
            </li>
            <li>
              <a (click)="onAnsClick(26,$event)" class="modal-link"   data-question="howDoIPause"
                >{{localModel.lbl_HelpCenter_Panel7_Ques3}}</a
              >
              <div class="answer"id="ans26">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel7_Ques3_para1"></p>
                <p>
                  {{localModel.lbl_HelpCenter_Panel7_Ques3_para2}}
                  <a [innerHtml]="localModel.lbl_HelpCenter_Panel7_Ques2_para3"></a
                  >
                </p>
              </div>
            </li>
            <li>
              <a (click)="onAnsClick(27,$event)" class="modal-link"  data-question="howDoIFix"
                >{{localModel.lbl_HelpCenter_Panel7_Ques4}}</a
              >
              <div class="answer" id="ans27">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel7_Ques4_para1"></p>

                <p>
                  {{localModel.lbl_HelpCenter_Panel7_Ques4_para2}}
                  <a [innerHtml]="localModel.lbl_HelpCenter_Panel7_Ques4_para3"></a
                  >
                </p>
              </div>
            </li>
            <li>
              <a (click)="onAnsClick(28,$event)" class="modal-link"  data-question="howDoIStop"
                >{{localModel.lbl_HelpCenter_Panel7_Ques5}}</a
              >
              <div class="answer" id="ans28">
                <p>
                  {{localModel.lbl_HelpCenter_Panel7_Ques5_para1}}
                </p>
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel7_Ques5_para2"></p>
              </div>
            </li>
          </ul>
        </div>
      </li>

      <li class="accordion__item">
          <button type="button" class="accordion__heading" [ngClass]="isOpenIndex == 8 ? 'open' : ''" (click)="onClick(8,$event)">
            {{localModel.lbl_HelpCenter_Panel8_title}}
          </button>
          <div id="sectionScreenwiseExtension" class="accordion__description" [ngClass]="isOpenIndex == 8 ? 'open' : ''">
          <ul class="list-faq">
            <li>
              <a  class="modal-link" (click)="onAnsClick(29,$event)" data-question="howDoISetUp"
                >{{localModel.lbl_HelpCenter_Panel8_Ques1}}</a
              >
              <div class="answer" id="ans29">
               <p [innerHtml]="localModel.lbl_HelpCenter_Panel8_Ques1_para1"></p>
               <p [innerHTML]="localModel.lbl_HelpCenter_Panel8_Ques1_para3">
              </p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(30,$event)" data-question="howDoIUse"
                >{{localModel.lbl_HelpCenter_Panel8_Ques2}}</a
              >
              <div class="answer" id="ans30">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel8_Ques2_para1"></p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(31,$event)" data-question="howDoIUse"
                >{{localModel.lbl_HelpCenter_Panel8_Ques3}}</a
              >
              <div class="answer" id="ans31">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel8_Ques3_para1"></p>
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel8_Ques3_para2"></p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(32,$event)" data-question="howDoIFix"
                >{{localModel.lbl_HelpCenter_Panel8_Ques4}}</a
              >
              <div class="answer" id="ans32">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel8_Ques4_para1"></p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(33,$event)" data-question="howDoIStop"
                >{{localModel.lbl_HelpCenter_Panel8_Ques5}}</a
              >
              <div class="answer" id="ans33">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel8_Ques5_para1"></p>
              </div>
            </li>
          </ul>
        </div>
      </li>

      <li class="accordion__item">
        <button type="button" class="accordion__heading" [ngClass]="isOpenIndex == 9 ? 'open' : ''" (click)="onClick(9,$event)">
          {{localModel.lbl_HelpCenter_Panel9_title}}</button>
        <div id="sectionSmartHome" class="accordion__description" [ngClass]="isOpenIndex == 9 ? 'open' : ''">
          <ul class="list-faq">
            <li>
              <a class="modal-link" (click)="onAnsClick(34,$event)" data-question="HowDoISetUp"
                >{{localModel.lbl_HelpCenter_Panel9_Ques1}}</a
              >
              <div class="answer" id="ans34">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel9_Ques1_para1"></p>
              </div>
            </li>
            <li>
              <a  class="modal-link" (click)="onAnsClick(35,$event)" data-question="HowDoIUseSmart"
                >{{localModel.lbl_HelpCenter_Panel9_Ques2}}</a
              >
              <div class="answer" id="ans35">
                <p>
                  {{ localModel.lbl_HelpCenter_Panel9_Ques2_para1 }} {{ localModel.lbl_HelpCenter_Panel9_Ques2_para2 }} {{ localModel.lbl_HelpCenter_Panel9_Ques2_para3 }}
                </p>
                <p [innerHtml]="localModel.lbl_HelpCenter_Panel9_Ques2_para4"></p>
              </div>
            </li>
            <li>
              <a  class="modal-link" (click)="onAnsClick(36,$event)" data-question="HowDoIFixIssues"
                >{{localModel.lbl_HelpCenter_Panel9_Ques3}}</a
              >
              <div class="answer" id="ans36">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel9_Ques3_para1"></p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(37,$event)" data-question="HowDoIstop"
                >{{localModel.lbl_HelpCenter_Panel9_Ques4}}</a
              >
              <div class="answer" id="ans37">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel9_Ques4_para1"></p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(57,$event)" data-question="HowDoIstop"
                >{{localModel.lbl_HelpCenter_Panel9_Ques5}}</a>
              <div class="answer" id="ans57">
                <p  [innerHtml]="localModel.lbl_HelpCenter_Panel9_Ques5_para1">
                </p>
              </div>
            </li>
          </ul>
        </div>
      </li>

      <li class="accordion__item">
          <button type="button" class="accordion__heading" [ngClass]="isOpenIndex == 10 ? 'open' : ''" (click)="onClick(10,$event)">
            {{localModel.lbl_HelpCenter_Panel10_title}}</button>
          <div id="sectionManagingAccount" class="accordion__description" [ngClass]="isOpenIndex == 10 ? 'open' : ''">

          <ul class="list-faq">
            <li>
              <a  class="modal-link" (click)="onAnsClick(38,$event)" data-question="HowCanIUpdateMy"
                >{{localModel.lbl_HelpCenter_Panel10_Ques1}}</a>
              <div class="answer" id="ans38">
                <p>
                  {{localModel.lbl_HelpCenter_Panel10_Ques1_para1}}
                  <a  [routerLink]="['/myprofile']">{{localModel.lbl_HelpCenter_Panel2_Ques3_para4}}</a> {{ localModel.lbl_HelpCenter_Panel10_Ques1_para2}}
                </p>
              </div>
            </li>
            <li>
              <a  class="modal-link" (click)="onAnsClick(39,$event)" data-question="HowDoIReview"
                >{{localModel.lbl_HelpCenter_Panel10_Ques2}}</a
              >
              <div class="answer" id="ans39">
                <p>
                  {{localModel.lbl_HelpCenter_Panel10_Ques2_para1}}
                  <a  [routerLink]="['/dashboard']">{{localModel.lbl_HelpCenter_Panel2_Ques3_para2}}</a>{{ localModel.lbl_HelpCenter_Panel10_Ques2_para2}}
                  <a  [routerLink]="['/myprofile']">{{localModel.lbl_HelpCenter_Panel2_Ques3_para4}}</a>
                </p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(40,$event)" data-question="HowDoIchangeor"
                >{{localModel.lbl_HelpCenter_Panel10_Ques3}}</a
              >
              <div class="answer" id="ans40">
                <p>
                {{localModel.lbl_HelpCenter_Panel10_Ques3_para1}}
                  <a  [routerLink]="['/myprofile']">{{localModel.lbl_HelpCenter_Panel2_Ques3_para4}}</a> {{localModel.lbl_HelpCenter_Panel10_Ques3_para2}}
                </p>
                <p>
                  {{localModel.lbl_HelpCenter_Panel10_Ques3_para3}}
                </p>
              </div>
            </li>
            <li>
              <a  class="modal-link" (click)="onAnsClick(42,$event)" data-question="whereCanISeeMyPanel"
                >{{localModel.lbl_HelpCenter_Panel10_Ques5}}</a
              >
              <div class="answer" id="ans42">
                <p>
                  {{localModel.lbl_HelpCenter_Panel10_Ques5_para1}}
                  <a  [routerLink]="['/dashboard']">{{localModel.lbl_HelpCenter_Panel2_Ques3_para2}}</a> {{localModel.lbl_HelpCenter_Panel10_Ques5_para2}}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </li>

      <li class="accordion__item">
          <button type="button" class="accordion__heading" [ngClass]="isOpenIndex == 11 ? 'open' : ''" (click)="onClick(11,$event)">
            {{localModel.lbl_HelpCenter_Panel11_title}}</button>
          <div id="sectionEarningRedeeming" class="accordion__description" [ngClass]="isOpenIndex == 11 ? 'open' : ''">
          <ul class="list-faq">
            <li>
              <a class="modal-link" (click)="onAnsClick(43,$event)" data-question="WhatDoIGetFor"
                >{{localModel.lbl_HelpCenter_Panel11_Ques1}}</a
              >
              <div class="answer" id="ans43">
                <p>
                  {{localModel.lbl_HelpCenter_Panel11_Ques1_para1}}
                  <a [routerLink]="['/myrewards']">{{ localModel.Lbl_footer_myrewaTitle}}</a> {{localModel.lbl_HelpCenter_Panel11_Ques1_para2}}
                </p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(44,$event)" data-question="howDoIAccessMyEnrollement"
                >{{localModel.lbl_HelpCenter_Panel11_Ques2}}</a
              >
              <div class="answer" id="ans44">
                <p>
                {{localModel.lbl_HelpCenter_Panel11_Ques2_para1}}
                </p>
                <p>
                  {{localModel.lbl_HelpCenter_Panel11_Ques2_para2}}
                  <a  [routerLink]="['/contact-us']">{{localModel.Lbl_contactUS}}</a>.{{localModel.lbl_HelpCenter_Panel11_Ques2_para3}}
                </p>
                <ul class="list-bulleted">
                  <li>{{localModel.lbl_HelpCenter_Panel11_Ques2_para4}}</li>
                  <li>{{localModel.lbl_HelpCenter_Panel11_Ques2_para5}}</li>
                </ul>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(45,$event)" data-question="WhatIfIHaveaQuestion"
                >{{localModel.lbl_HelpCenter_Panel11_Ques3}}</a
              >
              <div class="answer" id="ans45">
                <p>
                  {{localModel.lbl_HelpCenter_Panel11_Ques3_para1}}
                  <a  [routerLink]="['/contact-us']">{{localModel.Lbl_contactUS}}</a>.
                </p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(46,$event)" data-question="howDoIRedemMyReward"
                >{{localModel.lbl_HelpCenter_Panel11_Ques4}}</a
              >
              <div class="answer" id="ans46">
                <p>
                  {{localModel.lbl_HelpCenter_Panel11_Ques4_para1}}
                  <a [routerLink]="['/myrewards']">{{ localModel.Lbl_footer_myrewaTitle}}</a> {{localModel.lbl_HelpCenter_Panel11_Ques4_para2}}
                </p>
                <ul class="list-bulleted">
                  <li>{{localModel.lbl_HelpCenter_Panel11_Ques4_para3}}</li>
                  <li>{{localModel.lbl_HelpCenter_Panel11_Ques4_para4}}</li>
                </ul>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(47,$event)" data-question="whoDOIContactIf"
                >{{localModel.lbl_HelpCenter_Panel11_Ques5	}}</a
              >
              <div class="answer" id="ans47">
                <p>
                  {{localModel.lbl_HelpCenter_Panel11_Ques5_para1}}
                  <a  [routerLink]="['/contact-us']">{{localModel.Lbl_contactUS}}</a>. {{ localModel.lbl_HelpCenter_Panel11_Ques5_para2}}
                </p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(48,$event)" data-question="WhoDoIContact"
                >{{localModel.lbl_HelpCenter_Panel11_Ques6}}</a
              >
              <div class="answer" id="ans48">
                <p>
                  {{localModel.lbl_HelpCenter_Panel11_Ques6_para1}}
                  <a href="mailto:customersupport@alldigitalrewards.com">customersupport@alldigitalrewards.com</a
                  >{{localModel.lbl_HelpCenter_Panel11_Ques6_para2}} <a  [routerLink]="['/contact-us']">{{localModel.Lbl_contactUS}}</a>.
                </p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(49,$event)" data-question="WhoDOICOntactIfIHaveNot"
                >{{localModel.lbl_HelpCenter_Panel11_Ques7}}</a
              >
              <div class="answer" id="ans49">
                <p>
                  {{localModel.lbl_HelpCenter_Panel11_Ques7_para1}}
                  <a  [routerLink]="['/contact-us']">{{localModel.Lbl_contactUS}}</a> {{localModel.lbl_HelpCenter_Panel11_Ques7_para2}}
                </p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(50,$event)" data-question="WhereCanIFindMyPOints"
                >{{localModel.lbl_HelpCenter_Panel11_Ques8}}</a
              >
              <div class="answer" id="ans50">
                <p>
                  {{localModel.lbl_HelpCenter_Panel11_Ques8_para2}}
                  <a [routerLink]="['/myrewards']">{{ localModel.Lbl_footer_myrewaTitle}}</a>. {{ localModel.lbl_HelpCenter_Panel11_Ques8_para3}}
                </p>
              </div>
            </li>
            <li>
              <a class="modal-link" (click)="onAnsClick(51,$event)" data-question="HowDoRewardAffect"
                >{{localModel.lbl_HelpCenter_Panel11_Ques9}}</a
              >
              <div class="answer" id="ans51">
                <p>
                 {{localModel.lbl_HelpCenter_Panel11_Ques9_para1}}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </li>



    </ul> <!-- list end tag -->
  </div>
</section>

<div id="myModal" name="myModel" class="modal">
  <div class="modal__dropsheet"></div>

  <div class="modal-content" id="custom-model-content" >
    <div class="close" data-modal="close" (click)="onCloseModel()">
      <img src="assets/images/icons/icon_close.svg" alt="" />
    </div>
    <div class="modal-body">
      <div class="modal-hc-content">
        <h2 id="hcQuestion">Modal Header</h2>
        <div id="hcAnswer">
          <!-- Answer Here -->
        </div>
      </div>
      <div class="sidebar">
        <h3>{{localModel.NavLbl_contact_us_help}}</h3>
        <p class="sidebar__prompt">{{localModel.lbl_ContactUs_HereToHelp}}</p>
        <p>
          <a  [routerLink]="['/contact-us']" class="btn btn__secondary btn--small">{{localModel.lbl_Gppp_IAgree_Content2}}</a>
        </p>

        <ul class="contact-options">
          <li>
            <img src="assets/images/icons/icon_mobile.svg" class="contact-options__icon icon-phone" alt="" />
            <p [innerHtml]="localModel.lbl_HelpCenter_Panel1_Ques1_callus"></p>
          </li>
          <li>
            <img src="assets/images/icons/icon_clock.svg" class="contact-options__icon icon-schedule" alt="" />
            <div class="contact-options__schedule">
              <div [innerHtml]="localModel.NavLbl_contact_us_Hours"></div>
            </div>
          </li>
          <li>
            <img src="assets/images/icons/icon_mail.svg" class="contact-options__icon icon-email" alt="" />
            <p>
              {{localModel.lbl_HelpCenter_Panel1_Ques1_Emailto}}
              <a href="mailto:support@screenwisepanel.com">support@screenwisepanel.com </a>
            </p>
          </li>
        </ul>
      </div>
    </div>
    <div class="modal-footer"></div>
  </div>
</div>
