import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-sow-panel-hardware-policy-header',
  templateUrl: './policy-header.component.html'
})
export class PolicyHeaderComponent implements OnInit {

  localModel : any;
  constructor(private localizationService:LocalizationService) { 
    
    
    
  }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
  }

}
