export class CommunicationPrefRequest{
    PanelMemberId :string;

    MNO:number;

    HHNO:number;

    consentSMS:number;

    consentIVR:number;

    consentBlog:number;

    consentNewsletter:number;

    consentOtherEmails:number;

    OperationType:number = 1;

    OperationMessage:string = "Communication Pref updated via UMP self-service";

    constructor(personalConsentIVR:number,personalConsentSMS:number,panelMemberId:string,mno:number,hhno:number){
        this.consentIVR = personalConsentIVR;
        this.consentSMS = personalConsentSMS;
        this.PanelMemberId = panelMemberId;
        this.MNO = mno;
        this.HHNO=hhno;
    }
}