import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  chatWindowOpened: boolean;
  chatWindow: Window | null = null;
  constructor() { }
}
