import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AwardPointsDetails } from 'src/app/model/AwardPointsDetails';
import { RedeemPointsDetails } from 'src/app/model/RedeemPointsDetails';
import { TransactionDetailsRequest } from 'src/app/model/Request/TransactionDetailsRequest';
import { RewardsData } from 'src/app/model/rewardsData.model';
import { Transaction } from 'src/app/model/transaction.model';
import { UserLogInfo } from 'src/app/model/UserInfo';
import { AdrService } from 'src/app/services/adr/adr.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { RewardsInfoService } from 'src/app/services/my-rewards/rewardsInfo.service';
import { TransactionType } from 'src/app/shared/shared.global';

@Component({
  selector: 'app-rewards-history',
  templateUrl: './rewards-history.component.html',
  styleUrls: ['./rewards-history.component.css']
})
export class RewardsHistoryComponent implements OnInit {

  loginDetails: UserLogInfo;
  mno: number;
  transactionHistory: Transaction[];
  filteredTransactions: Transaction[];
  awardDetails: AwardPointsDetails;
  redeemDetails: RedeemPointsDetails;
  transactionId: string;
  transactionDate: Date;
  point_type: string;
  transactionType:number;
  error: string;
  panelName: string;
  localModel: any;
  isButtonVisible= false;
  isShowButtonVisible = true;
  requestData: any;
 
  constructor(private rewardInfoSrv: RewardsInfoService,private localizationService: LocalizationService, private spinnerSrv: NgxSpinnerService,private adrService: AdrService) { 
    if (localStorage.currentuser !== null) {
      this.loginDetails = this.localizationService.getCurrentUserVariable();
      this.mno = this.loginDetails.MNo;
    }
    this.panelName = 'SOW2';
    
  }

  ngOnInit(): void {
    this.spinnerSrv.show();
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
    this.getTransactionHistory();
  }

  onSummaryDetail(transData:Transaction,event){ 
    this.spinnerSrv.show();
    var reqData = new TransactionDetailsRequest();
    reqData.MNO = this.mno
    reqData.PanelMemberId = JSON.parse(localStorage.currentuser).PanelMemberId;
    reqData.TransactionId = transData.Transactionid;
    reqData.Panel = this.panelName;

    if (transData.TransactionType == TransactionType.Reward) {
      // fetch reward summary details
      this.rewardInfoSrv.getAwardPointsDetails(reqData).subscribe((responseData: AwardPointsDetails) => {
        this.awardDetails = responseData;
        this.showDetails(TransactionType.Reward,null, this.awardDetails);
        this.spinnerSrv.hide();
      }, 
      errorResponse => {
        this.error = errorResponse.error.Message;
      });
    }
    else if (transData.TransactionType == TransactionType.Redemption) {
      this.rewardInfoSrv.getRedeemPointsDetails(reqData).subscribe((responseData: RedeemPointsDetails) => {
        this.redeemDetails = responseData;
        this.showDetails(TransactionType.Redemption,this.redeemDetails, null);
        this.spinnerSrv.hide();
      }, 
      errorResponse => {
        this.error = errorResponse.error.Message;
        this.spinnerSrv.hide();
      });
    }
    document.getElementById("myModal").style.display="block"; 
  }

  onCloseModel(){
    this.transactionId = null;
    this.transactionDate = null;
    this.point_type = null;
    this.transactionType = null;
    document.getElementById("myModal").style.display="none";  
   }

   
   getTransactionHistory() {
     this.requestData = new RewardsData();
     this.requestData.MNO = this.mno;
     this.spinnerSrv.show();
    this.rewardInfoSrv.getTransactionHistory(this.requestData).subscribe((responseData: Transaction[]) => {
      this.filteredTransactions = this.transactionHistory = responseData["transactionHistory"];
      console.log(this.filteredTransactions);
      this.spinnerSrv.hide();

    }, 
    errorResponse => {
      this.error = errorResponse.error.Message;
      this.spinnerSrv.hide();
    });
  }

  showAllTransaction(){
    this.spinnerSrv.show();
    this.isButtonVisible = true;
    this.isShowButtonVisible = false;
    const transactionsList = document.querySelector('.table--transactions tbody');
    transactionsList.classList.add('show-all');
    this.spinnerSrv.hide();
  }
  HideTransaction(){
    this.spinnerSrv.show();
    this.isButtonVisible = false;
    this.isShowButtonVisible = true;
    const transactionsList = document.querySelector('.table--transactions tbody');
    transactionsList.classList.remove('show-all');
    window.scrollTo(0,0);6644
    this.spinnerSrv.hide();
  }

  showDetails(orderType : number,redeemDetails ? : RedeemPointsDetails, awardDetails ? : AwardPointsDetails){
    if(TransactionType.Reward == orderType){
      this.transactionId = awardDetails.TransactionId;
      this.transactionDate = awardDetails.TransactionDate;
      this.point_type = awardDetails.IncentiveType;
      this.transactionType = TransactionType.Reward;
    }
    else if(TransactionType.Redemption == orderType){
      this.transactionId = redeemDetails.TransactionId;
      this.transactionDate = redeemDetails.TransactionDate;
      this.point_type = redeemDetails.IncentiveType;
      this.transactionType = TransactionType.Redemption;
    }
  }

  RedirecttoADRPIN(transData: Transaction){
    this.spinnerSrv.show();
    this.adrService.getADRPINURL(this.mno).subscribe((responseData:any)=>{
      var url = responseData + transData.PIN;
      window.location.href = url;
    this.spinnerSrv.hide();
    },
    errorResponse => {
      this.error = errorResponse.error.Message;
      this.spinnerSrv.hide();
    });
  }
}
