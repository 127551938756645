import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';
import { NotificationService } from 'src/app/services/Notifications/notification.service';

@Component({
  selector: 'app-important-notification',
  templateUrl: './important-notification.component.html',
  styleUrls: ['./important-notification.component.css']
})
export class ImportantNotificationComponent implements OnInit {
 
  localModel: any;
  
  @Input() header: string;
  @Input() content: string;
  @Input() showExtraButton: boolean;
  @Output() closeNotification = new EventEmitter();

  constructor(private localizationService: LocalizationService,private notificationService:NotificationService) {

    // initialize variables
    this.showExtraButton = true;
   }

  ngOnInit(): void { 

    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });    
  }
  
  onCloseButton() {
    this.closeNotification.emit();
    this.notificationService.notification.next();
  }

}
