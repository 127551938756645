<section class="my-phone">
    <img
        src="images/people/bg_change_phone_numbers.png"
        alt=""
        class="my-phone__bg-img"
    />

    <div class="container">
        <div class="container--form">
            <h1>{{localModel.lbl_HelpCenter_Panel2_Ques3_para4}}</h1>

            <h2>{{localModel.lbl_ChangePhone_Title}}</h2>

            <form class="form" action="my_profile.html" (ngSubmit)="onSubmit(f)" #f="ngForm">
                <div class="form--2col">
                    <div class="form--2col-col">
                        <h3>{{localModel.lbl_dash_myInfoHHPhone}}</h3>

                        <div class="form-control">
                            <div class="form-control__label">{{localModel.lbl_ChangePhone_PhoneN}}</div>
                            <p id="householdPhone"><strong>(123) 456-7890</strong></p>
                        </div>
                        <div class="form-control">
                            <div class="form-control__label">
                                {{localModel.lbl_Profile_PhonePopupTypeLabel}}
                            </div>
                            <p id="isHouseholdMobilePhoneChecked">
                                <strong>{{localModel.lbl_myprofile_no}}</strong>
                            </p>
                        </div>
                        <p>
                            {{localModel.lbl_ChangePhone_PrimaryCanCng}}
                        </p>
                    </div>
                    <div class="form--2col-col">
                        <h3>{{localModel.lbl_ChangePhone_PersonalPhoneN}}</h3>
                        <div class="form-control">
                            <div class="custom-checkbox custom-checkbox--right">
                                <input
                                    type="checkbox"
                                    id="sameAsHouseholdPhone"
                                    checked
                                />
                                <label
                                    for="sameAsHouseholdPhone"
                                    class="custom-checkbox__label"
                                >
                                   {{localModel.lbl_ChangePhone_PersonalPhoneN_Chk}
                                </label>
                            </div>
                            <label for="personalPhoneNumber" class="form-control__label disabled"
                                >{{localModel.lbl_ChangePhone_PhoneN}}</label
                            >
                            <input
                                type="text"
                                id="personalPhoneNumber"
                                [ngModel]="'(123) 456-7890'"
                                name="personalPhoneNumber"
                                required
                                maxlength="10"
                                (keypress)="numberOnly($event)"                                
                                #personalPhoneNumber="ngModel"                                                              
                                disabled                               

                            />
                            <span *ngIf="!personalPhoneNumber.valid && personalPhoneNumber.touched">
                                {{getRequiredErrorMessage('personalphone')}}                                
                            </span>
                        </div>

                        <div class="form-control">
                            <div class="form-control__label disabled">
                                {{localModel.lbl_Profile_PhonePopupTypeLabel}}
                            </div>
                            <div class="btn-group-radio disabled">
                                <input
                                    type="radio"
                                    name="isPersonalMobile"
                                    id="isPersonalMobileYes"
                                    value="Yes"
                                    disabled
                                />
                                <label for="isPersonalMobileYes">{{localModel.lbl_myprofile_yes}</label>
                                <input
                                    type="radio"
                                    name="isPersonalMobile"
                                    id="isPersonalMobileNo"
                                    value="No"
                                    checked
                                    disabled
                                />
                                <label for="isPersonalMobileNo">{{localModel.lbl_myprofile_no}}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form__actions">
                    <button type="submit" class="btn btn__primary" [disabled] ="!f.valid">{{localModel.btn_ChangeNameEmail_Save}}</button>
                    <button
                        type="button"
                        class="btn btn__secondary"
                        data-goto="my_profile.html"                    >
                        {{localModel.lbl_forgot_shared_content9}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</section>