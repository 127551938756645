import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-google-panel-privacy-policy-header',
  templateUrl: './google-panel-privacy-policy-header.component.html'
})
export class GooglePanelPrivacyPolicyHeaderComponent implements OnInit {

 localModel : any;
  constructor(private localizationService:LocalizationService) { 
    
    
  }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
  }

}
