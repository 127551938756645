<section class="devices angular-1">
  <div class="container">
    <h2 [innerHtml]="localModel.lbl_dashboard_swdevicetitle"></h2>
    <p >{{localModel.lbl_dashboard_swtitleinfo}}</p>
    <div class="devices__wrapper" >
      
      <!-- Device 1: Router -->
      <div class="device-flip" *ngFor="let device of devices;let i = index" (click)="onDeviceSelected(i)" [ngClass]="flipCard(i)">
        <div class="device-container" >
          <!-- Front -->
          <div class="device-front">
            <div class="device">
              <div class="device__status">
                <img  [src]="device.deviceStatus ? 'assets/images/icons/icon_ok.svg' : 'assets/images/icons/icon_question.svg'" alt="" />
              </div>
              <div class="device__img">
                <img [src]="device.deviceImageSrc" alt="" />
              </div>
              <div class="device__title">
                <h3>{{device.deviceTitle}}</h3>
              </div>
              <div class="device__update">
                {{device.deviceUpdate}}
              </div>
            </div>
          </div>
          <!-- Back -->
          <div class="device-back">
            <div class="device">
              <div class="device__status">
                <img src="assets/images/icons/icon_close.svg" alt="" />
              </div>
              <div class="device__title">
                <h3>{{device.deviceTitle}}</h3>
              </div>
              <div class="device__update">{{device.deviceUpdate}}</div>
              <div class="device__actions">
                <a [innerHTML]="device.deviceAction" href="help_center.html?section=sectionRouter" class="btn btn__primary btn--device">
                </a>
              </div>
            </div>
          </div>
        </div>



      </div>

    </div>
    <app-your-devices></app-your-devices>    
  </div>
  
</section>