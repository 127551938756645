export class AddressInfoData {
    HouseholdId: string;
    PanelMemberId: string;  
    AddressLine1: string;
    AddressLine2: string;
    City: string;
    StateId: number;
    StateCode: string;
    Zip: string;
    AddressType: number; 
  }