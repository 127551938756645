import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AddressDetails, UpdateAddress } from 'src/app/model/memberAddressInfo.model';
import { StateDetails } from 'src/app/model/stateList.model';
import { MemberAddressService } from 'src/app/services/memberAddress.service';
import { MyProfileModel } from 'src/app/model/myProfile.model';
import { MyProfileService } from 'src/app/services/myProfile.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from 'src/app/Common/popup/popup.component';
import { APIResponse } from 'src/app/model/apiResponse.model';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-change-address',
  templateUrl: './change-address.component.html',
  styleUrls: ['./change-address.component.css']
})
export class ChangeAddressComponent implements OnInit {

  @ViewChild('f',{static:false}) changeAddressForm : NgForm;

  addressDetails: AddressDetails;
  stateList: Array<StateDetails> = [];
  IsResidenceAddressChecked : boolean = true;
  IsShippingAddressChecked : boolean = true;
    myProfile: MyProfileModel;
    localModel: any;
    openpopup: boolean=false;
    constructor(private memberAddressService: MemberAddressService, 
      private myProfileService: MyProfileService,
       private localizationService: LocalizationService,
      private router:Router,
      private spinnerSrv: NgxSpinnerService,private dialog:MatDialog,private titleService:Title) { 
        
  }

  ngOnInit(): void {
    this.spinnerSrv.show();
    this.localModel = this.localizationService.getLocalization();
    this.titleService.setTitle(this.localModel.lbl_changeadd_browsertitle)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_changeadd_browsertitle)
      })

    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
    this.myProfile = this.myProfileService.getProfile();
    //console.log("myProfile");
    //console.log(this.myProfile);
    this.memberAddressService.addressChanged.subscribe(res => {
        this.addressDetails = res;
        
        this.memberAddressService.getStates().subscribe(res1 => {
          this.stateList = res1.Data.StateList;

          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.spinnerSrv.hide();
        }, 500);
        });
    });
  
    this.memberAddressService.getMemberAddress(this.myProfile.MNO);

    
  }

  shippingSameAsDropDownValue;

  onSameAsAddressChange(event){
      this.shippingSameAsDropDownValue = event.target.value;
  }

  onSubmit(form : NgForm){
    var updateResidenceAddress = new UpdateAddress();
    var updateMailingAddress = new UpdateAddress();
    var updateShippingAddress = new UpdateAddress();
    
    if(this.myProfile.IsPrimary)
    {
      updateResidenceAddress.AddressLine1 = this.changeAddressForm.value.residentialAddressLine1;
      updateResidenceAddress.AddressLine2 = this.changeAddressForm.value.residentialAddressLine2;
      updateResidenceAddress.AddressType = 0;//this.changeAddressForm.value.address1;
      updateResidenceAddress.City = this.changeAddressForm.value.residentialCity;
      updateResidenceAddress.StateId = this.changeAddressForm.value.residentialState;
      updateResidenceAddress.ZipCode = this.changeAddressForm.value.residentialZip;
      updateResidenceAddress.PanelMemberId = this.myProfile.PanelMemberId;
      updateResidenceAddress.HouseholdId = this.myProfile.AccountId;
      updateResidenceAddress.MNO = this.myProfile.MNO;

    }

    if(this.changeAddressForm.value.mailingSameAsResidence)
    {

      if(!this.myProfile.IsPrimary){
        this.getResidenceData(updateResidenceAddress);
      }

       updateMailingAddress={...updateResidenceAddress}; 
      
       updateMailingAddress.SameAsResidenceForMailing = this.changeAddressForm.value.mailingSameAsResidence;
       
    }
    else
    {
     
      updateMailingAddress.AddressLine1 = this.changeAddressForm.value.mailingAddressLine1;
      updateMailingAddress.AddressLine2 = this.changeAddressForm.value.mailingAddressLine2;
      updateMailingAddress.City = this.changeAddressForm.value.mailingCity;
      updateMailingAddress.StateId = this.changeAddressForm.value.mailingState;
      updateMailingAddress.ZipCode = this.changeAddressForm.value.mailingZip;
      updateMailingAddress.PanelMemberId = this.myProfile.PanelMemberId;
      updateMailingAddress.HouseholdId = this.myProfile.AccountId;
      updateMailingAddress.MNO = this.myProfile.MNO;
      
      updateMailingAddress.SameAsResidenceForMailing = this.changeAddressForm.value.mailingSameAsResidence;
      
    }
    
    updateMailingAddress.AddressType = 2;//Mailing



    if(this.changeAddressForm.value.shippingSameAs){
      if(this.shippingSameAsDropDownValue==null ||this.shippingSameAsDropDownValue==undefined){
        this.shippingSameAsDropDownValue=this.addressDetails.Shipping.SameAsMailingForShipping==true?2:1;  
      }
      switch(+this.shippingSameAsDropDownValue)
      {
          case 1:
            if(!this.myProfile.IsPrimary){
              this.getResidenceData(updateResidenceAddress);
            }
      
          updateShippingAddress = {...updateResidenceAddress};

          updateShippingAddress.SameAsResidenceForMailing = false;
          updateShippingAddress.SameAsResidenceForShipping = true;
          updateShippingAddress.SameAsMailingForShipping = false;
                break;
          case 2:
            updateShippingAddress = {...updateMailingAddress};

            updateShippingAddress.SameAsResidenceForMailing = false; // not applicable for shipping address
      
            updateShippingAddress.SameAsResidenceForShipping = false;
            updateShippingAddress.SameAsMailingForShipping = true;
                break;
      }
    }
    else{
      updateShippingAddress.AddressLine1 = this.changeAddressForm.value.shippingAddressLine1;
      updateShippingAddress.AddressLine2 = this.changeAddressForm.value.shippingAddressLine2;
      updateShippingAddress.City = this.changeAddressForm.value.shippingCity;
      updateShippingAddress.StateId = this.changeAddressForm.value.shippingState;
      updateShippingAddress.ZipCode = this.changeAddressForm.value.shippingZip;
      updateShippingAddress.PanelMemberId = this.myProfile.PanelMemberId;
      updateShippingAddress.HouseholdId = this.myProfile.AccountId;
      
      updateShippingAddress.SameAsResidenceForShipping = false;
      updateShippingAddress.SameAsMailingForShipping = false;
      updateShippingAddress.MNO=this.myProfile.MNO;
    }
    
    updateShippingAddress.AddressType = 1;//Shipping

      

      // console.log(form);
      // console.log(updateResidenceAddress);
      // console.log(updateMailingAddress);
      // console.log(updateShippingAddress);
      this.spinnerSrv.show();
    this.memberAddressService.saveAddress(updateMailingAddress)
    .subscribe((res:APIResponse) => {
      this.memberAddressService.saveAddress(updateShippingAddress).subscribe((res1:APIResponse) => {
        if(this.myProfile.IsPrimary)
        {
            this.memberAddressService.saveAddress(updateResidenceAddress).subscribe((res2:APIResponse) => {
              if(res2!=null && res2.Code=='105' && !this.openpopup){
                this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Invalid_MNO);  
              }
              setTimeout(() => {
                /** spinner ends after 5 seconds */
                this.router.navigate(["myprofile"]);
                this.spinnerSrv.hide();
            }, 500);
            });
        }
        else
        {
          if(res1!=null && res1.Code=='105' && !this.openpopup){
            this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Invalid_MNO);  
          }

          setTimeout(() => {
            /** spinner ends after 5 seconds */
            this.router.navigate(["myprofile"]);
            this.spinnerSrv.hide();
            }, 500);
          
        }        
        });

        if(res!=null && res.Code=='105' && !this.openpopup){
          this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Invalid_MNO);  
        }
    });   
     
  }

  private showPopup(heading: string, message: string) {
  this.openpopup=true;
    this.dialog.open(PopupComponent, {

      disableClose: true,
      data: {
        header: heading,
        content: message
      }
    });
  }

  getResidenceData(updateResidenceAddress){
    
      updateResidenceAddress.AddressLine1 = this.addressDetails.Residential.AddressLine1;
      updateResidenceAddress.AddressLine2 = this.addressDetails.Residential.AddressLine2;
      updateResidenceAddress.AddressType = 0;//this.changeAddressForm.value.address1;
      updateResidenceAddress.City = this.addressDetails.Residential.City;
      updateResidenceAddress.StateId = this.addressDetails.Residential.StateInfo.StateId;
      updateResidenceAddress.ZipCode = this.addressDetails.Residential.ZipCode;
      updateResidenceAddress.PanelMemberId = this.myProfile.PanelMemberId;
      updateResidenceAddress.HouseholdId = this.myProfile.AccountId;
      updateResidenceAddress.MNO = this.myProfile.MNO;
  }
  
}
