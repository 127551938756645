<section class="about-screenwise">
    <div class="container">
      <h2>{{localModel.Lbl_sign_in_aboutmsg}}</h2>
      <p class="prompt">{{localModel.Lbl_sign_in_helpmsg}}</p>
      <div class="grid grid-50x50">
        <div class="grid__img">
          <img src="assets/images/illustrations/on_couch.png" alt="" />
        </div>
        <div class="grid__content">
          <ul class="list-bulleted list-bulleted--secondary">
            <li [innerHtml]="localModel.Lbl_sign_in_abouthelpcontent1">
              
            </li>
            <li [innerHtml]="localModel.Lbl_sign_in_abouthelpcontent2"></li>
            <li [innerHtml]="localModel.Lbl_sign_in_abouthelpcontent3"></li>
            <li [innerHtml]="localModel.Lbl_sign_in_abouthelpcontent4"></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
