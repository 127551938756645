export class Device{
    deviceStatus:boolean;
    deviceImageSrc:string;
    deviceTitle:string;
    deviceUpdate:string;
    deviceAction:string;
    
    constructor(deviceStatus:boolean,deviceImageSrc:string,deviceTitle:string,deviceUpdate:string,deviceAction:string){
        this.deviceStatus = deviceStatus;
        this.deviceImageSrc = deviceImageSrc;
        this.deviceTitle = deviceTitle;
        this.deviceUpdate = deviceUpdate;
        this.deviceAction = deviceAction;
    }
}