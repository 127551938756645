import { DatePipe } from '@angular/common';
import { Component,  EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptcha2Component } from 'ngx-captcha';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { ForgotPassword } from 'src/app/model/forgotPassword.model';
import { IResponse } from 'src/app/model/Response/IResponse';
import { ForgotPasswordService } from 'src/app/services/forgotPassword.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shared-email',
  templateUrl: './shared-email.component.html',
  styleUrls: ['./shared-email.component.css']
})
export class SharedEmailComponent implements OnInit {
  showdob:boolean=false;
  Email:string;
  isfirstName:boolean=true;
  isDob:boolean=false;
  myDateValue:any;
  
  @ViewChild('captchaElem', { static: true }) recaptcha: ReCaptcha2Component
  @Output() closeSharedEmail = new EventEmitter<void>();
    public forgotPassword: ForgotPassword;
    localModel: any;
  response: IResponse;
  forgotPasswordChanged = new Subject<ForgotPassword>();
  isFailed: boolean;
  showCaptchaValidation:boolean=false;
  sitekey:string;
  screenwidth:number;
  public theme: 'light' | 'dark' = 'light';
  public size: string;
  public lang = 'en';
  public type: 'image' | 'audio';
  showcaptcha: boolean=true;
  constructor(private forgotPasswordService:ForgotPasswordService,private router:Router,private route:ActivatedRoute,
      private spinner: NgxSpinnerService, private dtPipe: DatePipe, private localizationService: LocalizationService) {
      

    var emailfromsession=sessionStorage.getItem('email');
  if(emailfromsession!==null){
    this.Email=emailfromsession
  } 
  this.sitekey = this.localizationService.decryptValue(localStorage.getItem("SiteValue"));    
}

  ngOnInit(): void {  
    this.myDateValue=null;
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
      this.lang=this.localizationService.getlocalizationfromSession();
      this.lang=this.lang=='english'?'en':'es';
    });
  }
 
  handleReload(){
    this.lang = this.localizationService.getlocalizationfromSession();
    if(this.lang==null){
      this.lang='en';
      return;
    }
    this.lang=this.lang=='english'?'en':'es';
  }
  handler(): void{
    this.myDateValue=this.dtPipe.transform(this.myDateValue, "yyyy-MM-dd");
  }
  onCloseSharedEmail(){
    this.closeSharedEmail.emit();
  }
  
  onSharedEmailSendMeLink(sharedemail:NgForm){
    this.showCaptchaValidation = false;
    if(sharedemail.invalid){
      return;
    }
    if(sharedemail.value.recaptcha==="" || sharedemail.value.recaptcha=== undefined){
      this.showCaptchaValidation = true; 
      this.scrollOnTheView();  
        return;
    }
    this.forgotPassword = new ForgotPassword();
    this.forgotPassword.email=sharedemail.value.email;
    this.forgotPassword.firstName=sharedemail.value.firstName;
    this.forgotPassword.lastName=sharedemail.value.lastName;
    this.forgotPassword.dob= this.showdob == true ? this.dtPipe.transform(sharedemail.value.dateOfBirth, "yyyy-MM-dd") : '';
    this.forgotPassword.panelName=environment.PanelName;
    this.forgotPassword.isshared=true;
    this.forgotPassword.captcha=sharedemail.value.recaptcha;
    this.getForgotPasswordData( this.forgotPassword);
  }
  async getForgotPasswordData(data:any){
    this.spinner.show()
    this.response= await this.forgotPasswordService.getForgotPassword(data);
    if (this.forgotPassword.dob != '' && this.response.Data["isshared"] == false && this.showdob) {
      this.isDob=false;
      this.isFailed=true;
      this.scrollOnTheView();
      this.spinner.hide();
    }
    if (this.response != null && this.response.Data["isshared"] == true){
      this.forgotPassword.isSharedEmailId=false;
      this.forgotPasswordService.forgotPasswordChanged.next(this.forgotPassword);
    }else{
     this.isfirstName=false;
     this.isDob=true;
     this.showdob=true;
    this.showcaptcha=false;
    }
    this.spinner.hide()
  }
  onContactUs(){
    this.router.navigate(['../contact-us'],{relativeTo:this.route})
  }
  scrollOnTheView(){
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
}
@HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenwidth = window.innerWidth;
    this.size = this.screenwidth <= 400 ? "compact" : "normal";
  }
}
