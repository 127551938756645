<section class="redeem-rewards">
    <div class="container">
      <h2>{{localModel.Lbl_sign_in_redeemgiftmsg}}</h2>
      <p class="subtitle">
        {{localModel.lbl_eachmemberof_yourhousehold}}
      </p>

      <ul class="rewards">
        <li class="rewards__type">
          <div class="rewards__img">
            <img src="assets/images/home/prepaid_cards.svg" alt="" class="rewards__img--prepaid" />
          </div>

          <h3>{{localModel.lbl_MyReward_PrepaidCards}}</h3>
          <p [innerHtml]="localModel.lbl_MyReward_RedeemRewards_LineFour"></p>
        </li>
        <li class="rewards__type">
          <div class="rewards__img">
            <img src="assets/images/home/gift_cards.svg" alt="" class="rewards__img--giftcards" />
          </div>
          <h3>{{localModel.lbl_MyReward_GiftCards}}</h3>
          <p [innerHtml]="localModel.Lbl_sign_in_giftcard"></p>
        </li>
        <li class="rewards__type">
          <div class="rewards__img">
            <img src="assets/images/home/gift_choices.svg" alt="" class="rewards__img--giftchoices" />
          </div>
          <h3>{{localModel.lbl_MyReward_GiftChoices}}</h3>
          <p [innerHtml]="localModel.lbl_hundredsofgift">
            
          </p>
        </li>
      </ul>
    </div>
  </section>