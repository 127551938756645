import { Injectable } from '@angular/core';
import { HelpCenterQuestionAnwser, HCAnwsers, HCQuestions } from 'src/app/model/help-center.model';

@Injectable({providedIn:'root'})
export class HelpCenterService {
    private titileQuestions: HelpCenterQuestionAnwser[] = [];
    private hcquestionListAdd1: HCQuestions[] = [];
    private hcanswerListAdd1: HCAnwsers[] = [];

    private hcquestionListAdd2: HCQuestions[] = [];
    private hcanswerListAdd2: HCAnwsers[] = [];

    constructor(){}

    getQuestions(){
        
        const hcquestionsadd1 = new HCQuestions("What is the Ipsos Screenwise Panel?");
        const hcquestionsadd2= new HCQuestions("What will Google do with the information gathered from my household?");
        this.hcquestionListAdd1.push(hcquestionsadd1);
        this.hcquestionListAdd1.push(hcquestionsadd2);

        const hcanswersadd1 = new HCAnwsers("We recruit volunteers like you to learn about media habits. When you use a browser, mobile device, or TV with the Screenwise Meter, we learn from this.");
        const hcanswersadd2= new HCAnwsers("Our goal is to measure how U.S. adults and teens (age 13+) use the internet and TV.");
        this.hcanswerListAdd1.push(hcanswersadd1);
        this.hcanswerListAdd1.push(hcanswersadd2);

        const hcquestionsadd3 = new HCQuestions("How can I participate?");
        const hcquestionsadd4= new HCQuestions("Can other members of my household join Screenwise?");
        const hcquestionsadd5= new HCQuestions("How do I view the list of Screenwise participants in my household?");
        this.hcquestionListAdd2.push(hcquestionsadd3);
        this.hcquestionListAdd2.push(hcquestionsadd4);
        this.hcquestionListAdd2.push(hcquestionsadd5);

        const hcanswersadd3 = new HCAnwsers("All members of your household who are at least 13 years old are able to participate in the Screenwise Panel. However, we cannot enroll anyone outside of your household.");
        const hcanswersadd4= new HCAnwsers("The more you use devices that have the meter installed, the more rewards you'll earn. We recommend you install the meter on all the devices you use so you can earn as many rewards as possible.");
       // const hcanswersadd5= new HCAnwsers("You can find a list of the Screenwise participants in your household on the");
        this.hcanswerListAdd2.push(hcanswersadd3);
        this.hcanswerListAdd2.push(hcanswersadd4);
        this.hcquestionListAdd2.push(hcquestionsadd5);
        
          const titileQuestion1 = new  HelpCenterQuestionAnwser(["sectionpanel"],this.hcquestionListAdd1,this.hcanswerListAdd1,"About the Ipsos Screenwise Panel");
          const titileQuestion2 = new  HelpCenterQuestionAnwser(["sectionpanel"],this.hcquestionListAdd2,this.hcanswerListAdd2,"Participating in the panel");

        this.titileQuestions.push(titileQuestion1);
        this.titileQuestions.push(titileQuestion2);
        return this.titileQuestions;
    }

}