<section class="messaging signed-out" *ngIf="showValidationMessages">    
  <div class="container">  
  <div class="messaging__close" (click)="onClose(reset)">
      <img src="assets/images/icons/icon_close.svg" alt="" />
  </div>
  <div class="messaging__body">
      <h2 *ngIf="isUserResetLinkExpired">{{localModel.lbl_reset_valid_7}}</h2>
      <h2 *ngIf="isSuccess">{{localModel.lbl_reset_valid_8}}</h2>
      <h2 *ngIf="isFailed">{{localModel.lbl_reset_valid_9}}</h2>
      <h2 *ngIf="isCombinationExists">{{localModel.lbl_profile_userpassCombExists}}</h2>
      <h2 *ngIf="IsInvalid">{{localModel.lbl_Invalid_MNO}}</h2>
      <h2 *ngIf="showCaptchaValidation">{{localModel.lbl_enrollement_valid_2}}</h2>
    </div>
  <div class="messaging__actions">
      <button class="btn btn__primary" (click)="onClose(reset)">
          <img src="assets/images/icons/icon_check.svg" alt="icon check" />
         {{localModel.btn_Notification_One}}
      </button>
  </div>
</div>
</section>
<section class="messaging signed-out" *ngIf="showCaptchaValidationMessages">    
  <div class="container">  
  <div class="messaging__close" (click)="onCloseSection()">
      <img src="assets/images/icons/icon_close.svg" alt="" />
  </div>
  <div class="messaging__body">     
      <h2 *ngIf="showCaptchaValidation">{{localModel.lbl_enrollement_valid_2}}</h2>
    </div>
  <div class="messaging__actions">
      <button class="btn btn__primary" (click)="onCloseSection()">
          <img src="assets/images/icons/icon_check.svg" alt="icon check" />
         {{localModel.btn_Notification_One}}
      </button>
  </div>
</div>
</section>
<section class="change-password">
    <img src="assets/images/people/bg_change_password.png" alt="change password" class="change-password__bg-img" />

  <div class="container">
    <div class="container--form">
      <div class="form-layout">
        <div class="col-form col-form--change-password">

          <h2>{{localModel.lbl_ChangePass_ResetPassHead}}</h2>
          <form class="form" appPassword appPasswordPolicy (ngSubmit)="onSubmit(reset)" #reset="ngForm">
              <div class="form-control">
                <label for="newPassword" class="form-control__label">{{localModel.lbl_ChangePass_NewPass}}</label>
                <div class="password-wrapper">
                  <input [type]="show ?'text':'password'" id="newPassword" [(ngModel)]="value" name="newPassword" (keyup)="onkeyUp($event)" required #newPassword="ngModel" />                  
                  <button type="button" class="toggle-password" (click)="onbtnClick()">
                    <img [src]="show ? 'assets/images/icons/icon_hide_password.svg' : 'assets/images/icons/icon_show_password.svg'" alt="" class="toggle-password__img" />
                  </button>
                </div>
                <span class=".form-control__error-text" *ngIf="!newPassword.valid && newPassword.touched">{{localModel.lbl_reset_valid_1}}</span>    
              </div>
              <div class="form-control">
                <label for="confirmNewPassword" class="form-control__label">{{localModel.lbl_reset_valid_10}}</label>
                <div class="password-wrapper">
                  <input [type]="retype ? 'text':'password'" id="confirmNewPassword" ngModel name="confirmNewPassword" required #confirmNewPassword="ngModel" />
                  <button type="button" class="toggle-password" (click)="onbtnRetype()">
                    <img [src]="retype ? 'assets/images/icons/icon_hide_password.svg' : 'assets/images/icons/icon_show_password.svg'" alt="" class="toggle-password__img" />
                  </button>
                </div>
                <span class=".form-control__error-text" *ngIf="!confirmNewPassword.valid && confirmNewPassword.touched">{{localModel.lbl_reset_valid_2}}</span> 
                <span *ngIf="reset.errors?.passwordsNotEqual && newPassword.valid && (confirmNewPassword.touched || confirmNewPassword.dirty)">{{localModel.lbl_reset_valid_3}}</span>   
                </div>
              <div class=form-control>
                <label for="resetkey" class="form-control__label">{{localModel.lbl_ResetPass_ResetKey}}
                    <div class="tooltip" id="tooltip">
                        <img style="height:20px;" src="assets/images/icons/icon_question.svg" alt="Question icon" >
                        <span class="tooltiptext"> {{localModel.lbl_reset_valid_4}}</span>
                      </div>
                </label>
                <input type="text" class="form-control__text" ngModel #resetkey='ngModel' name="resetkey" required/>
                <span class=".form-control__error-text" *ngIf="!resetkey.valid && resetkey.touched">{{localModel.lbl_reset_valid_5}}</span>    
              </div>
              <div class="g-recaptcha" style="padding: 0 10%; padding-top: 5px;">
                <ngx-recaptcha2 #captchaElem [siteKey]="sitekey"
                 [size]="size"
              [hl]="lang"(load)="handleReload()"[theme]="theme" [type]="type" name="recaptcha" ngModel>
                </ngx-recaptcha2>    
            </div>
              <div class="form__actions">
                <button type="submit" class="btn btn__primary" [disabled]="!reset.valid">{{localModel.btn_ChangeNameEmail_Save}}</button>
                <button type="button" class="btn btn__secondary" routerLink="/">
                  {{localModel.BtnLbl_forgot_password_cancel}}
                </button>
              </div>
            </form>
        </div>
          <!-- Password Requirements -->
        <div class="sidebar password__requirements">

          <h2 class="mobile-only">{{localModel.lbl_reset_valid_6}}</h2>

          <p class="sidebar__prompt" [innerHtml]="localModel.lbl_ChangePass_NewPassReq"></p>

            <ul class="list-requirements" id="listRequirements">
              <li [ngClass]="toggleLen ? 'met' : 'not-met' " data-check="length">
                {{ localModel.lbl_ChangePass_NewPassReq_Atlest}}  <span class="status">{{ localModel.lbl_ChangePass_NewPassReq_One}}   </span> 
              </li>
              <li [ngClass]="toggleUp ? 'met' : 'not-met' " data-check="uppercase">
                {{ localModel.lbl_ChangePass_NewPassReq_Atlest}}  <span class="status">  {{ localModel.lbl_ChangePass_NewPassReq_Two}} </span>
              </li>
              <li [ngClass]="toggleLow ? 'met' : 'not-met' " data-check="lowercase">
                {{ localModel.lbl_ChangePass_NewPassReq_Atlest}}  <span class="status"> {{ localModel.lbl_ChangePass_NewPassReq_Three}}</span>
              </li>
              <li [ngClass]="toggleNum ? 'met' : 'not-met' " data-check="number">
                {{ localModel.lbl_ChangePass_NewPassReq_Atlest}}  <span class="status"> {{ localModel.lbl_ChangePass_NewPassReq_Four}} </span>
              </li>
              <li [ngClass]="toggleSpch ? 'met' : 'not-met' " data-check="specialcharacter">
                {{ localModel.lbl_ChangePass_NewPassReq_Atlest}}  <span class="status"> {{ localModel.lbl_ChangePass_NewPassReq_Five}}</span>
                <div class="example" [innerHtml]="localModel.lbl_ChangePass_NewPassReq_six"> </div>
              </li>
            </ul>

        </div>
      </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.4)"></ngx-spinner>