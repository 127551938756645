import { ValidationErrors, FormGroup, ValidatorFn } from "@angular/forms";
export const passwordValidator: ValidatorFn = (
    control: FormGroup
): ValidationErrors | null => {
    const password = control.value['emailAddress'];
    const confirmPassword = control.value['confirmEmailAddress'];
    return password && confirmPassword && password === confirmPassword
        ? null
        : { passwordsNotEqual: true };
};

