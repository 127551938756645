import { StateDetails } from './stateList.model';

//use for POST request
export class AddressInfo{
    MNO : number;
}

//USE for result desearlize 
export class AddressDetails {
    Residential:Address;
    Mailing:Address;
    Shipping:Address;
}

export class Address{
    AddressLine1: string;
    AddressLine2: string;
    City: string;
    StateInfo: StateDetails;
    ZipCode: string;
    AddressType: number;
    CompleteAddress:string;
    SameAsMailingForShipping:boolean;
    SameAsResidenceForShipping:boolean;
    SameAsResidenceForMailing:boolean;
    IsShippingAddressChecked:boolean;
}

//Update address Model
export class UpdateAddress{
    HouseholdId:string;
    PanelMemberId:string;
    MNO:number;
    AddressLine1:string;
    AddressLine2:string;
    City:string;
    StateId:number;
    ZipCode:string;
    AddressType:number;
    SameAsMailingForShipping:boolean;
    SameAsResidenceForShipping:boolean;
    SameAsResidenceForMailing:boolean;
    OperationType:number = 1;
    OperationMessage:string = 'Address updated via UMP self-service';
}

export class AddressType{
    name:string;
    Id:number;

    constructor(Id:number,name:string){
        this.Id = Id;
        this.name = name;
    }
}