import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
import { PolicyData } from 'src/app/model/PolicyData';
import { IResponse } from 'src/app/model/Response/IResponse';
import { UserLogInfo } from 'src/app/model/UserInfo';
import { LocalizationService } from 'src/app/services/localization.service';
import { PolicyService } from 'src/app/services/policy/policy.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})

export class PolicyComponent implements OnInit {

  localModel : any;
  response:IResponse;
  userDetails:UserLogInfo;
  mno:number;
  policydata:PolicyData
  isPrivacyPolicyConsented:boolean=false;
  isGooglePrivacyPolicyConsented:boolean=false;
  isTNCPolicyConsented:boolean=false;
  isCookiePolicyConsented:boolean=false;
  isEulaPolicyConsented:boolean=false;
  isUserLogin:boolean=true;
  beforeLogin:boolean=false;
  beforeLogin1:boolean=false;
  teenMinAge: any;
  constructor(private titleService:Title,private localizationService:LocalizationService,private policySrv:PolicyService,private dtPipe:DatePipe) { 
    //this.titleService.setTitle("My Profile: Screenwise");
    // var userDataFromSession= localStorage.getItem('currentuser'); 
    // this.userDetails = JSON.parse(userDataFromSession);
    // this.teenMinAge= AppConfig.configurations.appSettings.TeenMinimumAge;
    this.teenMinAge= environment.TeenMinimumAge;
    var userDataFromSession= this.localizationService.getCurrentUserVariable(); 
    this.userDetails = userDataFromSession;
    if(this.userDetails!==null){
      this.beforeLogin=true;
      if(this.userDetails.Age > this.teenMinAge){
        this.isUserLogin=false;
        this.beforeLogin1=true;
        this.mno=this.userDetails.MNo;
      }
    }
  
  }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
    if(this.userDetails!==null){
      this.getPolicyData();
    }
  }
  async getPolicyData() {
    this.policySrv.getPolicyData(this.mno).subscribe(res => {
      this.policydata=res;
      if(this.policydata.privacyPolicyAccepted===1){
        this.isPrivacyPolicyConsented=true;
      }
      if(this.policydata.googlePrivacyPolicyAccepted===1){
        this.isGooglePrivacyPolicyConsented=true;
      }
      if(this.policydata.termsnConditionAccepted===1){
        this.isTNCPolicyConsented=true;
      }
      if(this.policydata.cookiePolicyAccepted===1){
        this.isCookiePolicyConsented=true;
      }
      if(this.policydata.eulaAccepted===1){
        this.isEulaPolicyConsented=true;
      }
    });
  
  }
}
