<section class="messaging transient-notification">
    <div class="container">
        <div class="messaging__close">
            <a href="javascript:;" data-messaging-action="close">
                <img src="assets/images/icons/icon_close.svg" alt="" />
            </a>
        </div>
        <div class="messaging__body">
            <h2>{{localModel.lbl_Transient_page_level}}</h2>

            <h3>{{localModel.lbl_when_to_usethis}</h3>
            <p>
               {{localModel.lbl_usethistonotify}}
            </p>

            <h3>{{localModel.lbl_howitwork}}</h3>
            <p>
                {{localModel.lbl_itcanbedismissed}}
            </p>
        </div>

        <div class="messaging__actions">
            <button class="btn btn__primary" data-messaging-action="close">
                <img src="assets/images/icons/icon_check.svg" alt="" />
                {{localModel.lbl_gotit}}
            </button>
        </div>
    </div>
</section>
<app-sign-in></app-sign-in>