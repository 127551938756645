 <!-- Main Content Here -->
 <section class="change-address">

    <img src="assets/images/people/bg_change_address.png" alt="" class="change-address__bg-img" />

  <div class="container">
    <div class="container--form" *ngIf="addressDetails">
      <h1 [innerHtml]="localModel.lbl_MyProfile_title"></h1>

      <h2 [innerHtml]="localModel.lbl_ChangeAdd_Title"></h2>


      <form class="form" (ngSubmit)= "onSubmit(f)" #f="ngForm">
        <h3 [innerHtml]="localModel.lbl_ChangeAdd_ResidAddress"> </h3>
        <p class="read-only" *ngIf="!myProfile.IsPrimary" [innerHtml]="addressDetails.Residential.CompleteAddress">
          
        </p>

        <p class="note" *ngIf="!myProfile.IsPrimary" [innerHtml]="localModel.lbl_ChangeAdd_ResiCanCng"> </p>
        <div class="form-control" *ngIf="myProfile.IsPrimary"> 
          <label for="residentialAddressLine1" class="form-control__label" [innerHtml]="localModel.lbl_ChangeAdd_AddressLine1"></label>
          <input type="text" [(ngModel)]="addressDetails.Residential.AddressLine1" name="residentialAddressLine1" required #residentialAddressLine1 ="ngModel" maxlength="192" pattern="^[^<>]+$"/>
          <span *ngIf="!residentialAddressLine1.valid && residentialAddressLine1.touched">{{this.localModel.lbl_ChangeAdd_AddReq}}</span>
          <br>
          <span *ngIf="residentialAddressLine1.errors?.pattern">{{localModel.lbl_changeaddress_valid_3}}</span> 
        </div>
        <div class="form-control" *ngIf="myProfile.IsPrimary">
          <label for="residentialAddressLine2" class="form-control__label" [innerHtml]="localModel.lbl_ChangeAdd_AddressLine2"></label>
          <input type="text" [(ngModel)]="addressDetails.Residential.AddressLine2" name="residentialAddressLine2" #residentialAddressLine2 = "ngModel" maxlength="192" pattern="^[^<>]+$"/>
          <span *ngIf="residentialAddressLine2.errors?.pattern">{{localModel.lbl_changeaddress_valid_3}}</span> 
        </div>
        <div class="form-control form-control--row" *ngIf="myProfile.IsPrimary">
          <div class="form-control form-control--wide">
            <label for="residentialCity" class="form-control__label" [innerHtml]="localModel.lbl_ChangeAdd_City"></label>
            <input type="text" id="residentialCity" [(ngModel)]="addressDetails.Residential.City" name="residentialCity" required #residentialCity="ngModel" maxlength="120" pattern="^[^<>]+$"/>
            <span *ngIf="!residentialCity.valid && residentialCity.touched">{{this.localModel.lbl_ChangeAdd_CityReq}}</span>
            <br/>
            <span *ngIf="residentialCity.errors?.pattern">{{localModel.lbl_changeaddress_valid_3}}</span> 
          </div>

          <div class="form-control">
            <label for="residentialState" class="form-control__label" [innerHtml]="localModel.lbl_ChangeAdd_State"></label>
            <select id="residentialState" [(ngModel)]='addressDetails.Residential.StateInfo.StateId' name="residentialState" required #residentialState="ngModel">
              <option *ngFor="let state of stateList"  [value]="state.StateId">{{state.StateCode}}</option>
              
            </select>
          </div>

          <div class="form-control">
            <label for="residentialZip" class="form-control__label" [innerHtml]="localModel.lbl_ChangeAdd_Zip"></label>
            <input type="text" [(ngModel)]="addressDetails.Residential.ZipCode" ngModel name="residentialZip" maxlength="5"
              required  pattern="\d{5}-?(\d{4})?$" #residentialZip="ngModel"/>
            <span *ngIf="residentialZip.errors?.pattern">{{localModel.lbl_changeaddress_valid_1}}</span> 
            <br/>
            <span *ngIf="!residentialZip.valid && residentialZip.touched">{{localModel.lbl_changeaddress_valid_2}}</span>
          </div>
        </div>

        <h3 [innerHtml]="localModel.lbl_ChangeAdd_MailingAdd"></h3>
        <div class="custom-checkbox custom-size1">
          <input type="checkbox" id="mailingSameAsResidence" [(ngModel)]="addressDetails.Mailing.SameAsResidenceForMailing" name="mailingSameAsResidence"/>
          <label for="mailingSameAsResidence" class="custom-checkbox__label" [innerHtml]="localModel.lbl_ChangeAdd_MailingAdd_Chk">
            </label>
        </div>

        <div id="mailingAddress" *ngIf="!addressDetails.Mailing.SameAsResidenceForMailing">
          <div class="form-control">
            <label for="mailingAddressLine1" class="form-control__label" [innerHtml]="localModel.lbl_ChangeAdd_AddressLine1"></label>
            <input type="text" [(ngModel)]="addressDetails.Mailing.AddressLine1" name="mailingAddressLine1" maxlength="192" required #mailingAddressLine1="ngModel" pattern="^[^<>]+$"/>
            <span *ngIf="!mailingAddressLine1.valid && mailingAddressLine1.touched">{{this.localModel.lbl_ChangeAdd_AddReq}}</span>
            <br/>
            <span *ngIf="mailingAddressLine1.errors?.pattern">{{localModel.lbl_changeaddress_valid_3}}</span> 
          </div>
          <div class="form-control">
            <label for="mailingAddressLine2" class="form-control__label" [innerHtml]="localModel.lbl_ChangeAdd_AddressLine2"></label>
            <input type="text" [(ngModel)]="addressDetails.Mailing.AddressLine2" name="mailingAddressLine2" #mailingAddressLine2="ngModel" maxlength="192" pattern="^[^<>]+$"/>
            <span *ngIf="mailingAddressLine2.errors?.pattern">{{localModel.lbl_changeaddress_valid_3}}</span> 
          </div>
          <div class="form-control form-control--row">
            <div class="form-control form-control--wide">
              <label for="mailingCity" class="form-control__label" [innerHtml]="localModel.lbl_ChangeAdd_City"></label>
              <input type="text" [(ngModel)]="addressDetails.Mailing.City" ngModel name="mailingCity" required #mailingCity="ngModel" maxlength="120" pattern="^[^<>]+$"/>
              <span *ngIf="!mailingCity.valid && mailingCity.touched">{{this.localModel.lbl_ChangeAdd_CityReq}}</span>
              <br/>
              <span *ngIf="mailingCity.errors?.pattern">{{localModel.lbl_changeaddress_valid_3}}</span> 
            </div>

            <div class="form-control">
              <label for="mailingState" class="form-control__label" [innerHtml]="localModel.lbl_ChangeAdd_State"></label>
              <select id="mailingState" [(ngModel)]='addressDetails.Mailing.StateInfo.StateId' name="mailingState" 
              required #mailingState="ngModel">
                <option *ngFor="let state of stateList"  [value]="state.StateId">{{state.StateCode}}</option>
              </select>
            </div>

            <div class="form-control">
              <label for="mailingZip" class="form-control__label" [innerHtml]="localModel.lbl_ChangeAdd_Zip"></label>
              <input type="text" [(ngModel)]="addressDetails.Mailing.ZipCode" name="mailingZip" maxlength="5" 
              required  pattern="\d{5}-?(\d{4})?$" #mailingZip="ngModel"/>
              <span *ngIf="mailingZip.errors?.pattern">{{localModel.lbl_changeaddress_valid_1}}</span>
              <br/>
              <span *ngIf="!mailingZip.valid && mailingZip.touched">{{localModel.lbl_changeaddress_valid_2}}</span>
            </div>
          </div>
        </div>


        <h3 [innerHtml]="localModel.lbl_ChangeAdd_ShippingAdd"> </h3>
        <div class="custom-checkbox custom-size2">
          <input type="checkbox" id="shippingSameAs" [(ngModel)]="addressDetails.Shipping.IsShippingAddressChecked" #shippingSameAs name="shippingSameAs"/>
          <label for="shippingSameAs" class="custom-checkbox__label" >{{ localModel.lbl_ChangeAdd_Shipping_Chk}}
                <select class="auto" id="addressType" (change)="onSameAsAddressChange($event)" name="addressType" [disabled]="!addressDetails.Shipping.IsShippingAddressChecked">
                  <option [value]="1" [selected]="addressDetails.Shipping.SameAsResidenceForShipping">{{this.localModel.lbl_ChangeAdd_Resd}}</option>
                  <option [value]="2" [selected]="addressDetails.Shipping.SameAsMailingForShipping">{{this.localModel.lbl_ChangeAdd_Mail}}</option>
                </select>
            </label>
        </div>

        <div id="shippingAddress" *ngIf="!addressDetails.Shipping.IsShippingAddressChecked">
          <div class="form-control">
            <label for="shippingAddressLine1" class="form-control__label" [innerHtml]="localModel.lbl_ChangeAdd_AddressLine1"></label>
            <input type="text" [(ngModel)]="addressDetails.Shipping.AddressLine1" name="shippingAddressLine1" 
            maxlength="250" required #shippingAddressLine1="ngModel" maxlength="192" pattern="^[^<>]+$"/>
            <span *ngIf="!shippingAddressLine1.valid && shippingAddressLine1.touched">{{this.localModel.lbl_ChangeAdd_AddReq}}</span>
            <br/>
            <span *ngIf="shippingAddressLine1.errors?.pattern">{{localModel.lbl_changeaddress_valid_3}}</span> 
          </div>
          <div class="form-control">
            <label for="shippingAddressLine2" class="form-control__label" [innerHtml]="localModel.lbl_ChangeAdd_AddressLine2"></label>
            <input type="text" [(ngModel)]="addressDetails.Shipping.AddressLine2" #shippingAddressLine2="ngModel" 
            name="shippingAddressLine2" maxlength="192" pattern="^[^<>]+$" />
            <span *ngIf="shippingAddressLine2.errors?.pattern">{{localModel.lbl_changeaddress_valid_3}}</span> 
          </div>
          <div class="form-control form-control--row">
            <div class="form-control form-control--wide">
              <label for="shippingCity" class="form-control__label" [innerHtml]="localModel.lbl_ChangeAdd_City"></label>
              <input type="text" [(ngModel)]="addressDetails.Shipping.City" name="shippingCity" required #shippingCity="ngModel" maxlength="120" pattern="^[^<>]+$"/>
              <span *ngIf="!shippingCity.valid && shippingCity.touched">{{this.localModel.lbl_ChangeAdd_CityReq}}</span>
              <br/>
              <span *ngIf="shippingCity.errors?.pattern">{{localModel.lbl_changeaddress_valid_3}}</span> 
            </div>

            <div class="form-control">
              <label for="shippingState" class="form-control__label" [innerHtml]="localModel.lbl_ChangeAdd_State"></label>
              <select id="shippingState" [(ngModel)]='addressDetails.Shipping.StateInfo.StateId' name="shippingState" 
              required #shippingState="ngModel">
                <option *ngFor="let state of stateList"  [value]="state.StateId">{{state.StateCode}}</option>
              </select>
            </div>

            <div class="form-control">
              <label for="shippingZip" class="form-control__label" [innerHtml]="localModel.lbl_ChangeAdd_Zip"></label>
              <input type="text" [(ngModel)]="addressDetails.Shipping.ZipCode" name="shippingZip" maxlength="5"
              required pattern="\d{5}-?(\d{4})?$" #shippingZip="ngModel"/>
              <span *ngIf="shippingZip.errors?.pattern">{{localModel.lbl_changeaddress_valid_1}}</span>
              <br/>
              <span *ngIf="!shippingZip.valid && shippingZip.touched">{{localModel.lbl_changeaddress_valid_2}}</span>
            </div>
          </div>
        </div>
        
        <div class="form__actions">
          <button type="submit" class="btn btn__primary" [disabled] ="!f.valid" [innerHtml]="localModel.btn_ChangeNameEmail_Save"> </button>
          <button type="button" class="btn btn__secondary" routerLink="/myprofile" [innerHtml]="localModel.btn_ChangeNameEmail_Cancel"> </button>
        </div>
      </form>
    </div>
  </div>
</section>
<!-- loading spinner -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.4)"></ngx-spinner>