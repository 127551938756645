import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { PopupComponent } from 'src/app/Common/popup/popup.component';
import { EmailVerificationData, EmailVerificationResponse, MyProfileModel } from 'src/app/model/myProfile.model';
import { EmailVerificationService } from 'src/app/services/email-verification/emailVerification.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { MyProfileService } from 'src/app/services/myProfile.service';
import { Globals } from 'src/app/shared/shared.global';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-my-meter-account',
  templateUrl: './my-meter-account.component.html'
})
export class MyMeterAccountComponent implements OnInit {
  myProfile : MyProfileModel;
  localModel : any;
  
  isError = false;
  constructor(private localizationService:LocalizationService,
    private emailVerificationSrv: EmailVerificationService,
    private myProfileService:MyProfileService,
    private spinnerSrv: NgxSpinnerService,
    private dialog: MatDialog) { 
    
    
  }

  ngOnInit(): void {
    this.myProfile = this.myProfileService.getProfile();

    this.myProfileService.myProfileChanged.subscribe(res => {
      this.myProfile = res;
    })

    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });

    
  }

  onClickCallService(){
    
    this.spinnerSrv.show();

    // create request data
    var verificationData = new  EmailVerificationData();

    verificationData.MNO = this.myProfile.MNO;
    verificationData.HHNO = this.myProfile.HouseHoldNo;
    verificationData.Email = this.myProfile.EmailAddress1;
    verificationData.Language = this.myProfile.Language;
    verificationData.ApplicationName = Globals.ApplicationName;
    verificationData.IsDataValidationRequired = false;
    verificationData.IsMailSendRequired = environment.SendVerificationEmail;

    this.emailVerificationSrv.getEmailVerificationLink(verificationData).subscribe((response: EmailVerificationResponse) => {

      if (response.IsError) {
        this.spinnerSrv.hide();
        this.showPopup(this.localModel.lbl_MyProfile_MeterAc, response.ErrorMessage);
        return; 
      }

      this.spinnerSrv.hide();
      window.location.href = response.VerificationLink;
    }, error => {
      this.spinnerSrv.hide();
    });
  }

  
  private showPopup(heading: string, message: string) {

    this.dialog.open(PopupComponent, {

      disableClose: true,
      data: {
        header: heading,
        content: message
      }
    });
  }
}
