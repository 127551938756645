<section class="policy-header">
    <div class="container">
      <div class="policy-header__text">
        <h1 [innerHtml]="localModel.lbl_Gptc_Heading"></h1>
        <p>{{localModel.lbl_Gptc_Content1}}</p>
        <p class="last-modified" [innerHtml]="localModel.lbl_Gptc_Content2"></p>
      </div>
      <img src="assets/images/illustrations/computers_and_chart.png"
      class="policy-header__img" alt="computers and chart"/>
    </div>
  </section>
