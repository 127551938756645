import { ValidationErrors, FormGroup, ValidatorFn } from "@angular/forms";
export const passwordPolicyValidator: ValidatorFn = (
    control: FormGroup
): ValidationErrors | null => {
    
    const password = control.value['newPassword'];
    var isPasswordPolicy = false;
    if(password === undefined || password === null){
        return null;
    }
    
    var array:string[] = [];
    array.push(password.length >= 7 ? "met" : "not-met");

    
    array.push(password.match(/[A-Z]/) ? "met" : "not-met");

    
    array.push(password.match(/[a-z]/) ? "met" : "not-met");
    array.push(password.match(/\d/) ? "met" : "not-met");
    array.push(password.match(
            /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/
          )
            ? "met"
            : "not-met");   
     if(array.filter(policy => policy == "not-met").length > 0){
       isPasswordPolicy = false;
     }else{
         isPasswordPolicy = true;
     }

     return  isPasswordPolicy == true ? null : { passwordPolicy : true };

};
