<section class="change-address">

    <img src="assets/images/people/bg_change_address.png" alt="" class="change-address__bg-img" />

    <div class="container">
      <div class="container--form">
        <h1>My Profile</h1>

        <h2>Change Address</h2>

        

        <form class="form" action="my_profile.html" (ngSubmit)="onSubmit(f)" #f="ngForm">
          <h3>Residence Address</h3>

          <p class="read-only">
            5 A Street<br />
            Apartment 2A<br />
            Los Angeles, CA 12345 
          </p>

          <p class="note">Only the primary household member can change the residential address.</p>

          <h3>Mailing Address</h3>
          <div class="custom-checkbox">
            <input type="checkbox" id="mailingSameAsResidence" />
            <label for="mailingSameAsResidence" class="custom-checkbox__label">Same as residence address</label>
          </div>

          <div id="mailingAddress">
            <div class="form-control">
              <label for="mailingAddressLine1" class="form-control__label">Address Line 1</label>
              <input type="text" id="mailingAddressLine1" ngModel name="mailingAddressLine1" maxlength="250" required #mailingAddressLine1="ngModel" />
              <span *ngIf="!mailingAddressLine1.valid && mailingAddressLine1.touched">{{getRequiredErrorMessage('add1')}}</span>
            </div>
            <div class="form-control">
              <label for="mailingAddressLine2" class="form-control__label">Address Line 2</label>
              <input type="text" id="mailingAddressLine2" maxlength="250" ngModel name="mailingAddressLine2" />
            </div>
            <div class="form-control form-control--row">
              <div class="form-control form-control--wide">
                <label for="mailingCity" class="form-control__label">City</label>
                <input type="text" id="mailingCity" ngModel name="mailingCity" required #mailingCity="ngModel" />
                <span *ngIf="!mailingCity.valid && mailingCity.touched">{{getRequiredErrorMessage('city')}}</span>
              </div>

              <div class="form-control">
                <label for="mailingState" class="form-control__label">State</label>
                <select id="mailingState" ngModel name="mailingState" required #mailingState="ngModel">
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                </select>
                <span *ngIf="!mailingState.valid && mailingState.touched">{{getRequiredErrorMessage('state')}}</span>
              </div>

              <div class="form-control">
                <label for="mailingZip" class="form-control__label">ZIP</label>
                <input type="text" id="mailingZip" ngModel name="mailingZip" max="10" required (keypress)="numberOnly($event)" #mailingZip="ngModel" />
                <span *ngIf="!mailingZip.valid && mailingZip.touched">{{getRequiredErrorMessage('zip')}}</span>
              </div>
            </div>
          </div>


          <h3>Shipping Address</h3>
          <div class="custom-checkbox">
            <input type="checkbox" id="shippingSameAs" />
            <label for="shippingSameAs" class="custom-checkbox__label"></label>
            Same as 
              <select class="auto" id="addressType" name="addressType" disabled>
                <option>Residence</option>
                <option>Mailing</option>
              </select>
          </div>

          <div id="shippingAddress">
            <div class="form-control">
              <label for="shippingAddressLine1" class="form-control__label">Address Line 1</label>
              <input type="text" id="shippingAddressLine1" ngModel name="shippingAddressLine1" maxlength="250" required #shippingAddressLine1="ngModel" />
              <span *ngIf="!shippingAddressLine1.valid && shippingAddressLine1.touched">{{getRequiredErrorMessage('add1')}}</span>
            </div>
            <div class="form-control">
              <label for="shippingAddressLine2" class="form-control__label">Address Line 2</label>
              <input type="text" id="shippingAddressLine2" maxlength="250" ngModel name="shippingAddressLine2" />
            </div>
            <div class="form-control form-control--row">
              <div class="form-control form-control--wide">
                <label for="shippingCity" class="form-control__label">City</label>
                <input type="text" id="shippingCity" ngModel name="shippingCity" required #shippingCity="ngModel" />
                <span *ngIf="!shippingCity.valid && shippingCity.touched">{{getRequiredErrorMessage('city')}}</span>
              </div>

              <div class="form-control">
                <label for="shippingState" class="form-control__label">State</label>
                <select id="shippingState" ngModel name="shippingState" required #shippingState="ngModel">
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                </select>
                <span *ngIf="!shippingState.valid && shippingState.touched">{{getRequiredErrorMessage('state')}}</span>
              </div>

              <div class="form-control">
                <label for="shippingZip" class="form-control__label">ZIP</label>
                <input type="text" id="shippingZip" ngModel name="shippingZip" max="10" required (keypress)="numberOnly($event)" #shippingZip="ngModel" />
                <span *ngIf="!shippingZip.valid && shippingZip.touched">{{getRequiredErrorMessage('zip')}}</span>
              </div>
            </div>
          </div>
          
          <div class="form__actions">
            <button type="submit" class="btn btn__primary" [disabled]="!f.valid">Save</button>
            <button type="button" class="btn btn__secondary" routerLink="/myprofile">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </section>