<!-- Data Modal: Add Device -->

<div id="addDeviceModal" class="modal" style="display: block;">
    <div class="modal__dropsheet"></div>
    <div class="modal-content modal-content--small">
        <div class="close" (click)="onCloseAddNewDevice()">
            <img src="assets/images/icons/icon_close.svg" alt="" />
        </div>
        <div class="modal-body">
            <div class=" modal-add-device">
                <h2>{{localModel.lbl_dash_selectadevice}}</h2>

                <div class="add-device-actions">
                    <a href="help_center.html?section=sectionTVMeter&topic=howDoISetUp" class="btn btn__primary btn--device">
                        <p  [innerHtml]="localModel.lbl_dash_selectadevice1"></p>
                    </a>
                    <a href="help_center.html?section=sectionScreenwiseExtension&topic=howDoISetUp" class="btn btn__primary btn--device">
                        <p  [innerHtml]="localModel.lbl_dash_selectadevice2"></p>
                    </a>
                </div>
            </div>
        </div>
        <div class="modal-footer"></div>
    </div>
</div>    