export class ProfilePhoneNumber{
    profileTitle :string;
    phoneTitle :string;
    homePhoneNumber: string;  
    isMobilePhoneNo: boolean;
    personalPhoneNumber: string;
    isPersonalMobileYes: boolean;  
    isSMSYes: boolean;  
    communicationPref1: string;
    communicationPref2: string;
    isAutoDialer :boolean;
  }