<section class="policy-header">
    <div class="container">
        <div class="policy-header__text">
            <h1 [innerHtml]="localModel.lbl_IpsosScrPnlCoPolicy_Heading"></h1>
            <p [innerHtml]="localModel.lbl_IpsosScrPnlCoPolicy_Heading_details"></p>
            <p class="last-modified" [innerHtml]="localModel.lbl_IpsosScrPnlCoPolicy_Last_Modified">
                
            </p>
        </div>
        <img src="assets/images/illustrations/computers_and_chart.png" class="policy-header__img" alt="computers_and_chart"/>
    </div>
</section>