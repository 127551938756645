import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizationService } from 'src/app/services/localization.service';


@Component({
  selector: 'app-help-centre-question-modal',
  templateUrl: './help-centre-question-modal.component.html'
})
export class HelpCentreQuestionModalComponent implements OnInit {
  localModel: any;

  constructor(private route:Router,private localizationService: LocalizationService) { }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
    const modalLinks = document.querySelectorAll(".modal-link");
    const closeModals = document.querySelectorAll(".close");

    modalLinks.forEach((modalLink) => {
        modalLink.addEventListener("click", function () {
          if(modalLink instanceof HTMLElement){
            const targetModal = document.getElementById(modalLink.dataset.target);
            //const scrollYAmt = window.scrollY;
            //console.log(scrollYAmt);
            targetModal.style.display = "block";

            if(targetModal.querySelector(".modal-content") instanceof HTMLElement)
            {
              if (window.innerWidth > 1120) {
                  (<HTMLElement>targetModal.querySelector(".modal-content")).style.top =
                      scrollY + window.innerHeight / 2 + "px";
              } else {
                (<HTMLElement>targetModal.querySelector(".modal-content")).style.top =
                      scrollY + window.innerHeight / 20 + "px";
              }
            }

            // Populate Help Data
            populateHelpData(modalLink);
        }});
    });

    closeModals.forEach((closeLink) => {
        closeLink.addEventListener("click", function () {
            this.closest(".modal").style.display = "none";
        });
    });

    const populateHelpData = function (modalLink) {
        const question = modalLink.innerHTML;
        const answer = modalLink.closest("li").querySelector(".answer").innerHTML;

        document.getElementById("hcQuestion").innerHTML = question;
        document.getElementById("hcAnswer").innerHTML = answer;
    };

    window.addEventListener("click", function (e) {
        if ((<HTMLInputElement>e.target).className === "modal") {
            (<HTMLInputElement>e.target).style.display = "none";
        }
    });
  }
  onContactUs(){
    this.route.navigate(["/contact-us"]);
  }
}
