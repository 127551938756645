import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-enrollment-incentive',
  templateUrl: './enrollment-incentive.component.html'
})
export class EnrollmentIncentiveComponent implements OnInit {

  constructor(private router:Router,private route: ActivatedRoute) { }

  ngOnInit(): void {
    switch(this.route.snapshot.url[0].path.toLowerCase())
    {
      case "eredeem":
        window.location.href = environment.CoreProfileURL+"SWEnrollment.aspx?neo_code="+this.route.snapshot.params.p1+"&emailid="+this.route.snapshot.params.p2+"&fmail="+this.route.snapshot.params.p3;
      break;
      case "credeem":
        window.location.href = environment.CoreProfileURL+"SWConsent.aspx?neo_code="+this.route.snapshot.params.p1+"&emailid="+this.route.snapshot.params.p2+"&fmail="+this.route.snapshot.params.p3;
      break;
    }
  }

}
