import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from "src/app/shared/shared.global";
import { tap } from 'rxjs/operators';
import { PolicyData } from 'src/app/model/PolicyData';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class PolicyService{
    apiBasePath: string;
    apiKey: string;
    httpOptions:HttpHeaders;
    panelName:string;
    policydata = new PolicyData()
    constructor(private http:HttpClient,private dtPipe:DatePipe) {
        this.apiBasePath = environment.Privacy_API_Base_Path;
        this.panelName=environment.PanelName;

        this.httpOptions = new HttpHeaders({
            'Content-Type': 'application/json'
        });
   }


  getPolicyData(mno: any) {  
    const httpConfig = {
        headers: this.httpOptions
    };
    return this.http.get<PolicyData>(this.apiBasePath + Globals.MyPrivacyData +'?MNO='+ mno + '&panelName='+this.panelName,httpConfig)
    .pipe(tap(responsedata => {
     
      
        this.policydata = new PolicyData()
        this.policydata.privacyPolicyAccepted = responsedata.privacyPolicyAccepted == null ? -1 : responsedata.privacyPolicyAccepted;
        this.policydata.cookiePolicyAccepted = responsedata.cookiePolicyAccepted == null ? -1 : responsedata.cookiePolicyAccepted;
        this.policydata.termsnConditionAccepted = responsedata.termsnConditionAccepted == null ? -1 : responsedata.termsnConditionAccepted;
        this.policydata.eulaAccepted = responsedata.eulaAccepted == null ? -1 : responsedata.eulaAccepted;
        this.policydata.googlePrivacyPolicyAccepted = responsedata.googlePrivacyPolicyAccepted == null ? -1 : responsedata.googlePrivacyPolicyAccepted; 
        this.policydata.withdrawnrequestinitiated = responsedata.withdrawnrequestinitiated;
        this.policydata.IsPrivacyPolicyUpdated = responsedata.IsPrivacyPolicyUpdated;
        this.policydata.IsCookiePolicyUpdated = responsedata.IsCookiePolicyUpdated;
        this.policydata.IsGooglePrivacyPolicyUpdated = responsedata.IsGooglePrivacyPolicyUpdated;
        this.policydata.IsTermsNConditionPolicyUpdated = responsedata.IsTermsNConditionPolicyUpdated;
        this.policydata.IsEulaPolicyUpdated = responsedata.IsEulaPolicyUpdated;
        this.policydata.Ips_withdrawnstatus=responsedata.Ips_withdrawnstatus;
        //}
    }));
    
  }

  // postpanelpolicydata(user: any): Promise<any> {
  //   const httpConfig = {
  //       headers: this.httpOptions
  //   };
  //   return this.http.post("http://dev-az-sw.knpanel.com/swpolicy/api/PanelPolicy/AddPolicyAudit", user,httpConfig).toPromise();  //https://localhost:44352/ webapi host url  
  // }


  postpanelpolicydata(user: any){
    const httpConfig = {
        headers: this.httpOptions
    };
    return this.http.post(this.apiBasePath + Globals.PostpolicyData , user,httpConfig);  //https://localhost:44352/ webapi host url  
  }
}