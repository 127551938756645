import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-panel-terms-condition',
  templateUrl: './google-panel-terms-condition.component.html'
})
export class GooglePanelTermsConditionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
