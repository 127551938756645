import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ResourcesModel } from '../model/ResourcesModel';
import { AppGlobals } from '../model/app.global';
import { Globals } from '../shared/shared.global';
import { LanguageValue } from '../model/LanguageValue';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
@Injectable({
    providedIn: 'root'
  })
export class LocalizationService{
  //userTestStatus: { id: string, name: string }[] =  [];
  userTestStatus: ResourcesModel[];
  localizationModel: { id: string, name: string }[] =  [];
  apiBasePath: string;
  apiKey: string;
  localModel: ResourcesModel[] =  [];
  Langs:LanguageValue[]=[];
  localizationChanged= new Subject<ResourcesModel[]>();
  titleChanged   =new Subject<string>();   
  httpOptions:any;
  returnvalue: any;

    constructor(private http : HttpClient,private appGlobal:AppGlobals){
      this.apiBasePath = environment.Localization_API_Base_Path;
        this.httpOptions  = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',          
            })
          };
    }

      getResouces(){
        //pipe operator take will only take first subscription and automatically unsubcribe 
         return this.http.post<ResourcesModel[]>(
           this.apiBasePath + Globals.GetResourcesByPanelId,
           {"PanelId":"1"},
           this.httpOptions
         )
     }
     
     getLocalization(){
      //Add code for language check
      this.localModel=this.getLanguage()
      this.titleChanged.next()  
      return this.localModel
}


     setLocalization(res:any){
       //console.log(res[0].attributes);
      localStorage.setItem("english",res[0].attributes);
      localStorage.setItem("spanish",res[1].attributes);
      this.localModel=this.getLanguage()
     
      this.localizationChanged.next(this.localModel);    
     }

     changeLocalization(str:string){
       this.removeLocalStorageVariable('lang')
     
       switch(str){
         case "english":
          this.localModel = JSON.parse(localStorage.getItem("english")); 
           break;
         case "spanish":
          this.localModel = JSON.parse(localStorage.getItem("spanish"));
          break;
         default:
          this.localModel = JSON.parse(localStorage.getItem("english")); 
          break;
       }
       this.setLangVariable(str);
       this.localizationChanged.next(this.localModel);
       this.titleChanged.next()  
     }

    getLanguage(){
      var lang=this.getlocalizationfromSession();
      switch(lang){
        case "english":
          return JSON.parse(localStorage.getItem("english"));
      
        case "spanish":
          return JSON.parse(localStorage.getItem("spanish"));
    
         default:
         return JSON.parse(localStorage.getItem("english"));
      
      } 
    }

    getLanguageArray(){    
    
      return this.Langs= [
           {
             languageId: 1,
             languageName: "English"
           },
           {
             languageId: 2,
             languageName: "Español"
           }
         ];
   }
    
   getlocalizationfromSession(){
     return localStorage.getItem("lang");
   }
   setLangVariable(str){
    return localStorage.setItem("lang",str)
   }

   getCurrentUserVariable(){
    return JSON.parse(localStorage.getItem("currentuser"));
   }

   setCurrentUserVariable(userData){
    return localStorage.setItem('currentuser', JSON.stringify(userData));
   }

   getWithDrawanPopUpVariable(){
    return localStorage.getItem("withdrawnpopup");
   }

   setWithDrawanPopUpVariable(){
   return localStorage.setItem("withdrawnpopup","true")
   }

   setRestrictVariable(){
     return localStorage.setItem('restrict',"true");
   }

   getRestrictVariable(){
    return localStorage.getItem('restrict');
   }
  
   getIsSignOutVariable(){
    return JSON.parse(localStorage.getItem("isSignOut"))
  }

  setIsSignOutVariable(flag){
    if(flag=="true"){
     return localStorage.setItem("isSignOut","true"); 
    }
    return localStorage.setItem("isSignOut","false");   
  }
  
  getRemembermeVariable(){
    return localStorage.getItem("isRememberme");
   }

   setRemembermeVariable(isRememberChecked){
    return   localStorage.setItem("isRememberme", JSON.stringify(isRememberChecked));  
   }

   getUserNameVariable(){
    return  localStorage.getItem("username");
   }

   setUserNameVariable(userName){
    return   localStorage.setItem("username",userName)  ;
   }

   getHeaderVariable(){
    return localStorage.getItem("header")
   }


  removeLocalStorageVariable(str){
  switch(str){
    case "username":
      localStorage.removeItem('username');
     break;
    case "isRememberme" :
      localStorage.removeItem('isRememberme');
      break;
    case "withdrawnpopup":
      localStorage.removeItem("withdrawnpopup"); 
      break;
    case "restrict" :
      localStorage.removeItem("restrict");
      break;
     case "currentuser" :
      localStorage.removeItem('currentuser'); 
      break; 
    case "header" : 
     localStorage.removeItem('header');
     break;
    case "lang":
      localStorage.removeItem('lang'); 
      break;
    case "showviewhelpcenter" : 
      localStorage.removeItem('showviewhelpcenter');
      break;
  }
  }

  removesessionStorageVariable(str){
    switch(str){
      case "loginresult":
        sessionStorage.removeItem('loginresult');
       break;
      case "myProfile" :
        sessionStorage.removeItem('myProfile');
        break;
      case'priviousroute':
      sessionStorage.removeItem('priviousroute');
      break;  
  }

}

setSesionVariables(str){
  switch(str){
    case "loginresult":
    return sessionStorage.setItem('loginresult','true');
  }
}
  getSessionVariables(str){
    switch(str){
      case 'priviousroute':
        return sessionStorage.getItem('priviousroute');
      case'loginresult':
      return sessionStorage.getItem('loginresult');
    }
  
  }

  setViewHelpCenterSession(data){
    localStorage.setItem('showviewhelpcenter', data);
  }
  
  getCaptchaKey(){
    return this.http.post<string>(
      this.apiBasePath + Globals.GetCaptchaValue,
      {"PanelId":"1"},
      this.httpOptions)
    }

    decryptValue(data){
      this.returnvalue= JSON.parse(CryptoJS.AES.decrypt(data, '').toString(CryptoJS.enc.Utf8));
      return this.returnvalue;
    }
}
