<!-- Modal: Check Email Modal -->
<div id="checkEmailModal" class="modal" style="display:block;">
  <div class="modal__dropsheet"></div>
    <div class="modal-content">
      <div class="close" (click)="onCloseCheckEmailId()">
        <img src="assets/images/icons/icon_close.svg" alt="" />
      </div>
      <div class="modal-body">
        <div class="modal-form-content">
          <h2>{{localModel.lbl_forgot_checked_content1}}</h2>
          <div class="form-control__prompt">
            {{localModel.lbl_forgot_checked_content2}}
          </div>
          <div class="form-control__link">
            {{email}}
          </div>
          <div class="form__actions form__actions--return">
            <button
              type="button"
              class="btn btn__primary"
              (click)="onCloseCheckEmailId()"
            >
            
              {{localModel.lbl_forgot_checked_content3}}
            </button>
          </div>
        </div>   
        <div class="sidebar sidebar--form">
          <p class="sidebar__prompt">{{localModel.lbl_didnotgetthemail}}</p>
          <p >{{localModel.lbl_forgot_checked_content4}}</p>
          <p>{{localModel.lbl_forgot_checked_content5}}<a routerLink="/contact-us">{{localModel.lbl_Gppp_IAgree_Content2}}</a> {{localModel.lbl_forgot_checked_content7}}</p>
          

        </div>
      </div>
      <div class="modal-footer"></div>
    </div>    
</div>  
<ngx-spinner bdColor="rgba(51, 51, 51, 0.4)"></ngx-spinner>