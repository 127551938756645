import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APIResponse } from 'src/app/model/apiResponse.model';
import { Globals } from 'src/app/shared/shared.global';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  httpOptions: any;
  apiBaseUrl: string;
  constructor(private httpClient: HttpClient) {
    this.apiBaseUrl = environment.Survey_Connector_API_Base_Path;
    this.httpOptions  = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',          
      })
    };
  }

  captureSurveyResponse(surveyId: string, responseId: string): Observable<any> {
    return this.httpClient.post<APIResponse>(this.apiBaseUrl + Globals.GetSingleSurveyResponse, JSON.stringify({ "surveyId": surveyId, "responseId": responseId }), this.httpOptions);
  }

}
