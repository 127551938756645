import { Component, OnInit } from '@angular/core';
import { Device } from 'src/app/model/device.model';
import { DeviceService } from 'src/app/services/device.service';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-your-devices',
  templateUrl: './your-devices.component.html'
})
export class YourDevicesComponent implements OnInit {

  yourDevices: Device[];  
  index:number = -1;
  indexes: number[] =[];
  css:string;
  
  
   localModel : any;
  constructor(private localizationService:LocalizationService,private deviceService:DeviceService) { 
    
    
    
  }


  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
    
    this.yourDevices = this.deviceService.getDevices();

  }

  flipCard(index){
    if(this.indexes.includes(index)){
      this.css = "flip";
    }
    else
    {
      this.css = " ";
    }
    return this.css;
  }
  onDeviceSelected(index:number){
    if(this.indexes.includes(index)){
      const removedIndex = this.indexes.indexOf(index);
      this.indexes.splice(removedIndex,1);
    }
    else
    {
    this.indexes.push(index);
    }
  }

  onAddNewDevice(){
    this.deviceService.showAddNewDeviceModel.next(1);
  }

  onCloseAddNewDevice(){
    this.deviceService.showAddNewDeviceModel.next(2);
  }
}
