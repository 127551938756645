import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-joinstudyclosed',
  templateUrl: './joinstudyclosed.component.html',
  styleUrls: ['./joinstudyclosed.component.css']
})
export class JoinstudyclosedComponent implements OnInit {
  localModel: any;
  constructor(    private localizationService: LocalizationService,private titleService:Title) { 
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
  }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    this.titleService.setTitle(this.localModel.lbl_home_title)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_home_title)
      })  
  }

}
