import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MyProfileModel, UpdateProfile } from 'src/app/model/myProfile.model';
import { MyProfileService } from 'src/app/services/myProfile.service';
import {Title} from "@angular/platform-browser";
import { LocalizationService } from 'src/app/services/localization.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from 'src/app/Common/popup/popup.component';
import { APIResponse } from 'src/app/model/apiResponse.model';

@Component({
  selector: 'app-change-name-or-email',
  templateUrl: './change-name-or-email.component.html'
})
export class ChangeNameOrEmailComponent implements OnInit {
    myProfile: MyProfileModel;
    localModel: any;
    changeNameOrEmail:FormGroup;
    isConfirmEmailMatching:boolean=false;
    isConfirmEmaildisable:boolean=true;
    constructor(private myProfileService: MyProfileService, 
      private localizationService: LocalizationService,
      private spinnerSrv: NgxSpinnerService,private route:Router,private dialog:MatDialog,private titleService:Title) {  
        
  }
  ngOnInit(): void {
    
    this.localModel = this.localizationService.getLocalization();
    this.titleService.setTitle(this.localModel.lbl_changename_browsertitle)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_changename_browsertitle)
      })
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
    this.spinnerSrv.show();
   
    this.myProfile = this.myProfileService.getProfile();
    this.createForm();
  
    setTimeout(() => {
      
      /** spinner ends after 5 seconds */
      this.spinnerSrv.hide();

  }, 500);  
  
      this.changeNameOrEmail.get('emailAddress')
      .valueChanges
      .subscribe(value => {

       const confirmEmailAddressField = this.changeNameOrEmail.get('confirmEmailAddress');
        if(value != this.myProfile.EmailAddress1){
          this.isConfirmEmaildisable=true;
          confirmEmailAddressField.enable();
          confirmEmailAddressField.setValidators([Validators.required,Validators.email,Validators.maxLength(50)]);
          
        }  else {
          this.isConfirmEmaildisable=false;
          confirmEmailAddressField.setValue(null);
         // confirmEmailAddressField.clearValidators();
          confirmEmailAddressField.disable()    
          }
      }); 


      this.changeNameOrEmail.get('confirmEmailAddress').valueChanges.subscribe(val => {
      
        const confirmEmailAddressField = this.changeNameOrEmail.get('emailAddress');

        if(val === confirmEmailAddressField.value){
          this.changeNameOrEmail.controls["confirmEmailAddress"].setErrors(null);
        }
        else{
          this.changeNameOrEmail.controls["confirmEmailAddress"].setErrors({incorrect:true});
        }
      });
    
  }


  createForm(){
    this.changeNameOrEmail = new FormGroup({
      'firstName': new FormControl(this.myProfile.FirstName,[Validators.required,Validators.maxLength(50),Validators.pattern("^[a-zA-Z0-9\\'\\-]+$")]),
      'lastName': new FormControl(this.myProfile.LastName,[Validators.required,Validators.maxLength(50),Validators.pattern("^[a-zA-Z0-9\\'\\-]+$")]),
      'emailAddress' : new FormControl(this.myProfile.EmailAddress1,[Validators.required,Validators.maxLength(50),Validators.pattern("\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*")]),
      'confirmEmailAddress' : new FormControl(null)
      
    });
    const confirmEmailAddress = this.changeNameOrEmail.get('confirmEmailAddress');
    confirmEmailAddress.disable();
  }

  

  onSubmit(){
    var updateProfile = new UpdateProfile();
    //updateProfile.EmailAddress = this.changeNameOrEmail.value.emailAddress;
    updateProfile.MNO = this.myProfile.MNO;
    updateProfile.PanelMemberId = this.myProfile.PanelMemberId;
    var execution = 0;

    if(this.myProfile.LastName != this.changeNameOrEmail.get("lastName").value 
    || this.myProfile.FirstName != this.changeNameOrEmail.get("firstName").value){
      //call name update api
      updateProfile.FirstName = this.changeNameOrEmail.value.firstName;
      updateProfile.LastName = this.changeNameOrEmail.value.lastName;
      execution = 1;//call only name change api
      //1
    }
    
    if(this.myProfile.EmailAddress1 != this.changeNameOrEmail.get("emailAddress").value){
      //call name update api
      updateProfile.EmailAddress = this.changeNameOrEmail.value.emailAddress;
      //if firstname or lastname change as well as email change then make vairavle 3 here else
      //2
      if(execution == 1){
        execution = 3; //call name change api and email change api
      }
      else{
        execution = 2; //call email change api
      }
      
    }


    this.updateProfileInfo(updateProfile,execution);
    //console.log(form);
  }

  onModelChange(event:any){
    //console.log(event);
  }

  //update firstname lastname and email address
  updateProfileInfo(updateProfile:UpdateProfile,execution:number){
    this.spinnerSrv.show();
    switch(execution){
      case 0:   this.hideSpinner();
      break;
      case 1://call name change api
            this.myProfileService.updateFirstNLastName(updateProfile).subscribe((res1:APIResponse) => {
              if(res1!==null && res1.Code=='105'){
                this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Invalid_MNO);  
              }
              this.hideSpinner();      
            });
        break;
      case 2://call email change api
            this.myProfileService.updateEmailAddress(updateProfile).subscribe((res:APIResponse) => {
              if(res!==null && res.Code=='105'){
                this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Invalid_MNO);  
              }
                this.hideSpinner();                        
            });
            
        break;
      case 3:// call name and email change api
          this.myProfileService.updateEmailAddress(updateProfile).subscribe((res:APIResponse) => {
            this.myProfileService.updateFirstNLastName(updateProfile).subscribe((res1:APIResponse) => {
              if(res1!==null && res1.Code=='105'){
                this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Invalid_MNO);  
              }
              this.hideSpinner();
            });
            if(res!==null && res.Code=='105'){
              this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Invalid_MNO);  
            }
          });
      
        break;
    }
    
  }

  hideSpinner(){
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinnerSrv.hide();
      this.route.navigate(["myprofile"]);
    }, 500);
  }

  getRequiredErrorMessage(fieldName: string) {
    var msg = '';

    switch (fieldName) {
      case 'fname':
        msg =  this.localModel.lbl_ChangeNameEmail_FnameReq
        break;
      case 'lname':
        msg = this.localModel.lbl_ChangeNameEmail_LanameReq
        break;
      case 'mail':
        msg = this.localModel.lbl_ChangeNameEmail_MailReq
        break;
        case 'cnfrmmail':
        msg = this.localModel.lbl_ChangeNameEmail_ConfirMail
        break;
      
      default:
        break;
    }

    return msg;
  }

  private showPopup(heading: string, message: string) {

    this.dialog.open(PopupComponent, {

      disableClose: true,
      data: {
        header: heading,
        content: message
      }
    });
  }
}
