import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-download-meter-notification',
  templateUrl: './download-meter-notification.component.html',
  styleUrls: ['./download-meter-notification.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DownloadMeterNotificationComponent implements OnInit {
  localModel: any;
  messageBody: string;
  messageHeader: string;
  messageButtonTextOk: string;
    constructor(
    private localizationService: LocalizationService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private elementRef: ElementRef
    ) {


    }



  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();

    const firstName = this.localizationService.getCurrentUserVariable().FirstName;
    this.messageBody = this.localModel['lbl_Force_Notification_Body'].replace('[firstname]', firstName);
    this.messageButtonTextOk = this.localModel['lbl_Force_Notification_Button_OK'];

    this.localizationService.localizationChanged.subscribe(result => {
      this.localModel = result;
      const firstName = JSON.parse(localStorage.getItem("currentuser")).FirstName
      this.messageBody = result['lbl_Force_Notification_Body'].replace('[firstname]', firstName);
      this.messageButtonTextOk = result['lbl_Force_Notification_Button_OK'];
      this.bindClickEvents();
    });
  }



  ngAfterViewChecked(): void {
    this.bindClickEvents();
  }



  bindClickEvents() {
    const myProfileLink = this.elementRef.nativeElement.querySelector('.notification-links.myprofile-link');
    if (myProfileLink) {
      myProfileLink.addEventListener('click', this.navigateToMyProfileLink.bind(this));
    }



    const downloadMeterLink = this.elementRef.nativeElement.querySelector('.notification-links.dashboard-link');
    if (downloadMeterLink) {
      downloadMeterLink.addEventListener('click', this.navigateToDownloadMeterLink.bind(this));
    }
  }



  navigateToMyProfileLink() {
    sessionStorage.setItem('scrollToScreewiseMeterSettings', '1');
    this.router.navigateByUrl('/myprofile');
  }



  navigateToDownloadMeterLink() {
    const element = document.getElementById('screenwise-meter');
    if (element) {
      const offsetTop = element.offsetTop;
      window.scroll(0, offsetTop);
    }
  }
}
