<section class="change-password">
    <img src="assets/images/people/bg_change_password.png" alt="" class="change-password__bg-img" />

  <div class="container">
    <div class="container--form">
      <h1 *ngIf="beforeLogin" [innerHtml]="localModel.lbl_MyProfile_title"> </h1>

      <div class="form-layout">
        <div class="col-form col-form--change-password">

          <h2 [innerHtml]="localModel.lbl_ChangePass_Title"></h2>
          <form class="form" appPassword appPasswordPolicy (ngSubmit)="onSubmit(f)" #f="ngForm">
              <div class="form-control">
                <label for="oldPassword" class="form-control__label required-field" [innerHtml]="localModel.lbl_ChangePass_OldPass"></label>
                <div class="password-wrapper">
                  <input [type]="oldPasswordShow ? 'text':'password'" id="oldPassword" autocomplete="off"  ngModel name="oldPassword" required #oldPassword="ngModel"/>
                  <button type="button" class="toggle-password" (click)="onShowHideClick(1)">
                    <img [src]="oldPasswordShow ? 'assets/images/icons/icon_hide_password.svg' : 'assets/images/icons/icon_show_password.svg'" alt="" class="toggle-password__img" />
                  </button>
                </div>
                <span *ngIf="!oldPassword.valid && oldPassword.touched">{{localModel.lbl_oldpassword_required}}</span>
              </div>
              <div class="form-control">
                <label for="newPassword" class="form-control__label required-field" [innerHtml]="localModel.lbl_ChangePass_NewPass"></label>
                <div class="password-wrapper">
                  <input [type]="newPasswordShow ?'text':'password'" id="newPassword" ngModel name="newPassword" (keyup)="onkeyUp($event)" required #newPassword="ngModel" />                  
                  <button type="button" class="toggle-password" (click)="onShowHideClick(2)">
                    <img [src]="newPasswordShow ? 'assets/images/icons/icon_hide_password.svg' : 'assets/images/icons/icon_show_password.svg'" alt="" class="toggle-password__img" />
                  </button>
                </div>
                <span *ngIf="f.errors?.passwordPolicy  && (newPassword.valid)">{{localModel.lbl_password_does_meet}}</span>
                <span *ngIf="!newPassword.valid && newPassword.touched">{{localModel.lbl_password_required}}</span>
              </div>
              <div class="form-control">
                <label for="confirmNewPassword" class="form-control__label required-field"  [innerHtml]="localModel.lbl_ChangePass_ConfirmNewPass"></label>
                <div class="password-wrapper">
                  <input [type]="confirmNewPasswordShow ? 'text':'password'" id="confirmNewPassword" ngModel name="confirmNewPassword" required #confirmNewPassword="ngModel" />
                  <button type="button" class="toggle-password" (click)="onShowHideClick(3)">
                    <img [src]="confirmNewPasswordShow ? 'assets/images/icons/icon_hide_password.svg' : 'assets/images/icons/icon_show_password.svg'" alt="" class="toggle-password__img" />
                  </button>
                  
                </div>
                <span *ngIf="f.errors?.passwordsNotEqual && (confirmNewPassword.valid)">{{localModel.lbl_password_not_match}}</span>
                <br/>
                <span *ngIf="!confirmNewPassword.valid && confirmNewPassword.touched">{{localModel.lbl_confrim_password}}</span>
              </div>
              <div class="form__actions">
                <button type="submit" class="btn btn__primary" [disabled] ="!f.valid" [innerHtml]="localModel.btn_ChangeNameEmail_Save"></button>
                <button type="button" class="btn btn__secondary" (click)="onClickRedirect()" [innerHtml]="localModel.btn_ChangeNameEmail_Cancel">
                  
                </button>
              </div>
            </form>
        </div>
          <!-- Password Requirements -->
        <div class="sidebar password__requirements">

          <h2 class="mobile-only" [innerHtml]="localModel.lbl_ChangePass_Title"></h2>

            <p class="sidebar__prompt break-word" [innerHtml]="localModel.lbl_ChangePass_NewPassReq"></p>
  
            <ul class="list-requirements" id="listRequirements">
              <li [ngClass]="toggleLen ? 'met' : 'not-met' "  data-check="length">
                {{ localModel.lbl_ChangePass_NewPassReq_Atlest}}  <span class="status">{{ localModel.lbl_ChangePass_NewPassReq_One}}   </span> 
              </li>
              <li [ngClass]="toggleUp ? 'met' : 'not-met' " data-check="uppercase">
                {{ localModel.lbl_ChangePass_NewPassReq_Atlest}}  <span class="status">  {{ localModel.lbl_ChangePass_NewPassReq_Two}} </span>
              </li>
              <li [ngClass]="toggleLow ? 'met' : 'not-met' " data-check="lowercase">
                {{ localModel.lbl_ChangePass_NewPassReq_Atlest}}  <span class="status"> {{ localModel.lbl_ChangePass_NewPassReq_Three}}</span>
              </li>
              <li [ngClass]="toggleNum ? 'met' : 'not-met' " data-check="number">
                {{ localModel.lbl_ChangePass_NewPassReq_Atlest}}  <span class="status"> {{ localModel.lbl_ChangePass_NewPassReq_Four}} </span>
              </li>
              <li [ngClass]="toggleSpch ? 'met' : 'not-met' " data-check="specialcharacter">
                {{ localModel.lbl_ChangePass_NewPassReq_Atlest}}  <span class="status"> {{ localModel.lbl_ChangePass_NewPassReq_Five}}</span>
                <div class="example" [innerHtml]="localModel.lbl_ChangePass_NewPassReq_six"> </div>
              </li>
            </ul>

        </div>
      </div>
    </div>
</div>
<!-- loading spinner -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.4)"></ngx-spinner>