<div id="myModal" class="modal">
    <div class="modal__dropsheet"></div>

    <div class="modal-content">
      <div class="close" data-modal="close">
        <img src="assets/images/icons/icon_close.svg" alt="" />
      </div>
      <div class="modal-body">
        <div class="modal-hc-content">
          <h2 id="hcQuestion">Modal Header</h2>
          <div id="hcAnswer">
            <!-- Answer Here -->
          </div>
        </div>
        <div class="sidebar">
          <h3>{{localModel.NavLbl_contact_us_help}}</h3>
          <p class="sidebar__prompt">{{localModel.lbl_MyReward_heretohelp}</p>
          <p>
            <a routerLink="/contact-us" class="btn btn__secondary btn--small">{{localModel.lbl_ContactUs_title}}</a>
          </p>

          <ul class="contact-options">
            <li>
              <img src="assets/images/icons/icon_mobile.svg" class="contact-options__icon icon-phone" alt="" />
              <p [innerHtml]="localModel.lbl_HelpCenter_Panel1_Ques1_callus"></p>
            </li>
            <li>
              <img src="assets/images/icons/icon_clock.svg" class="contact-options__icon icon-schedule" alt="" />
              <div class="contact-options__schedule">
                <div [innerHtml]="localModel.NavLbl_contact_us_Hours"></div>  
              </div>
            </li>
            <li>
              <img src="assets/images/icons/icon_mail.svg" class="contact-options__icon icon-email" alt="" />
              <p>
                {{localModel.lbl_HelpCenter_Panel1_Ques1_Emailto}}
                <a href="mailto:support@screenwisepanel.com">support@screenwisepanel.com </a>
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
