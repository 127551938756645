import { Component, Input, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';


@Component({
  selector: 'app-rewards-status',
  templateUrl: './rewards-status.component.html'
})
export class RewardsStatusComponent implements OnInit {

  localModel: any;

  @Input() balance: number;
    
  constructor(private localizationService: LocalizationService) { }

  ngOnInit(): void {

    this.getLocalizationData();
  }  

  private getLocalizationData() {
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
  }

}
