// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false, 
  envName : 'dev',
      PanelName: "SOW2",
      AppDomainName: "screenwisepanel.com",
      ErrorLink: "https://dev-az-new.screenwisepanel.com/error.html",
      SurveyLink: "//dev-az-sd.ipsossay.com/neologin/Neo.aspx",
      GoogleIntegration_API_Base_Path: "https://dev-az-new.screenwisepanel.com/GoogleIntegrationAPI/",
      Login_API_Base_Path: "https://dev-az-new.knowledgepanel.com/UserAuthentication/",
      Localization_API_Base_Path: "https://dev-az-new.knowledgepanel.com/Localization/",
      Member_Profile_API_Base_Path: "https://dev-az-new.knowledgepanel.com/MemberProfile/",
      Point_API_Base_Path: "https://dev-az-new.knowledgepanel.com/MemberPoints/",
      TeenConsent_API_Base_Path: "https://dev-az-new.screenwisepanel.com/TeenConsent/",
      SmtpEmail_API_Base_Path: "https://dev-az-new.screenwisepanel.com/SmtpEmail/",
      Privacy_API_Base_Path: "https://dev-az-new.screenwisepanel.com/swpolicy/",
      Survey_Connector_API_Base_Path: "https://dev-az-new.screenwisepanel.com/SurveyResponseConnector/",
      counter: 59,
      SessionTimeout: 600,
      CheckForTaxCompliance: true,
      LanguageChangeDropDown: true,
      SendVerificationEmail: false,
      SupportEmail: "support@screenwisepanel.com",
      RecipientEmail: "support@screenwisepanel.com",
      TeenMinimumAge: 18,
      ForcedNotificationSamples: "NAT84C, NAT84D",
      DomainURL:"www.screenwisepanel.com,members.screenwisepanel.com",
      RedirectDomain:"https://screenwisepanel.com",
      CoreProfileURL: "https://dev-az-coreprofileredirect.knpanel.com/",
      CustomMsg_API_Base_Path: "https://dev-az-new.screenwisepanel.com/CustomMessageAPI/",
      AdrUrl:'https://dev-az-new.screenwisepanel.com/ADRAPI/' ,
      ChatBotURL_English: "https://www6.nohold.net/Ipsos/login.aspx?pid=2&source=web&login=1",
      ChatBotURL_Spanish: " https://www6.nohold.net/Ipsos/login.aspx?pid=5&source=web&login=1"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
