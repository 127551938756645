
<div id="sharedEmailModal" class="modal" style="display:block;">
    <div class="modal__dropsheet"></div>
    <div class="modal-content">
      <div class="close" (click)="onCloseSharedEmail()">
        <img src="assets/images/icons/icon_close.svg" alt="" />
      </div>

      <div class="modal-body">
          <div class="modal-form-content">
              <h2>{{localModel.lbl_forgot_shared_content1}}</h2>
              <p>
                  {{localModel.lbl_forgot_shared_content2}}
              </p>
              <p *ngIf="isfirstName">
                {{localModel.lbl_shared_email_valid_1}}
              </p>
              <p *ngIf="isDob">
                  {{localModel.lbl_forgot_shared_content3}}
              </p>

              <p *ngIf="isFailed">
               <strong> {{localModel.lbl_pleaseconfirmthedetails}}</strong>
             </p>

              <p *ngIf="showCaptchaValidation">               
                <strong> {{localModel.lbl_enrollement_valid_2}}</strong>           
              </p>

              <form class="form" #sharedemail="ngForm">
            
                <div class="form-control">
                    <label for="email" class="form-control__label">{{localModel.lbl_shared_email_valid_2}}</label>
                    <input type="email" class="form-control__text form-control__error" placeholder="{{localModel.Lbl_forgot_password_emailInputmsg}}" [(ngModel)]="Email"  name="email" #email="ngModel" required email />
                    <div class="form-control__error-text" *ngIf="!email.valid && email.touched">
                      <strong>{{localModel.lbl_shared_email_valid_3}}</strong>
                      {{localModel.lbl_shared_email_valid_4}}
                    </div>  
                  </div>
                  <div class="form-control">
                      <label for="firstName" class="form-control__label">{{localModel.lbl_forgot_shared_content4}}</label>
                      <input type="text" id="firstName" ngModel name="firstName" #firstName="ngModel" class="form-control__text" placeholder="{{localModel.Lbl_forgot_password_firstnamenputmsg}}" required />
                      <div class="form-control__error-text" *ngIf="!firstName.valid && firstName.touched">
                          <strong>{{localModel.lbl_shared_email_valid_5}}</strong>
                      </div>
                  </div>

                  <div class="form-control">
                      <label for="lastName" class="form-control__label">{{localModel.lbl_forgot_shared_content5}}</label>
                      <input type="text" id="lastName" name="lastName" class="form-control__text" ngModel name="lastName" #lastName="ngModel" placeholder="{{localModel.Lbl_forgot_password_lastnamenputmsg}}" required />
                      <div class="form-control__error-text" *ngIf="!lastName.valid && lastName.touched">
                        <strong>{{localModel.lbl_shared_email_valid_6}}</strong>
                      </div>
                  </div>
                  <div class="form-control" *ngIf="showdob">
                    <label for="dateOfBirth" class="form-control__label ">{{localModel.lbl_forgot_shared_content6}}</label>               
                    <div class="password-wrapper">
                       <input type="text"  id="dateOfBirth"   [(ngModel)]="myDateValue"  name="dateOfBirth" #dateOfBirth="ngModel"  required>
                      <button type="button" class="form-control__date toggle-password1"  #datepickerYMD="bsDatepicker" bsDatepicker  
                      [bsConfig]="{ containerClass : 'theme-default',selectFromOtherMonth: true,showClearButton: false, clearPosition: 'center',showWeekNumbers:false, dateInputFormat: 'YYYY-MM-DD'}" (onHidden)="handler()" [(bsValue)]="myDateValue"  placement="left">
                      </button>    
                     </div>
                     <br/>
                     <div class="g-recaptcha" style="padding: 0 10%; padding-top: 5px;">
                      <ngx-recaptcha2 #captchaElem [siteKey]="sitekey"
                       [size]="size" 
                    [hl]="lang"(load)="handleReload()"[theme]="theme" [type]="type" name="recaptcha" ngModel>
                      </ngx-recaptcha2>    
                  </div>     
                    <div class="form-control__error-text" *ngIf="!dateOfBirth.valid && dateOfBirth.touched">
                      <strong>{{localModel.lbl_shared_email_valid_7}}</strong>
                    </div>
                 </div>    
                 <div class="g-recaptcha" style="padding: 0 10%; padding-top: 5px;" *ngIf="showcaptcha">
                  <ngx-recaptcha2 #captchaElem [siteKey]="sitekey"
                   [size]="size" 
                [hl]="lang"(load)="handleReload()"[theme]="theme" [type]="type" name="recaptcha" ngModel>
                  </ngx-recaptcha2>    
              </div>           
                  <div class="form-control__prompt">{{localModel.lbl_forgot_shared_content7}}</div>
                  <div class="form__actions">
                      <button type="button"
                              [disabled]="!sharedemail.valid"
                              class="btn btn__primary modal-link"
                              (click)="onSharedEmailSendMeLink(sharedemail)">
                              {{localModel.lbl_forgot_shared_content8}}
                      </button>
                      <button type="button" class="btn btn__secondary" (click)="onCloseSharedEmail()">{{localModel.lbl_forgot_shared_content9}}</button>
                  </div>                  
              </form>
          </div>
        <div class="sidebar sidebar--form">
          <p class="sidebar__prompt">{{localModel.lbl_shared_email_valid_8}}</p>
          <p>
            {{localModel.lbl_shared_email_valid_9}}
            <a (click)="onContactUs()" >{{localModel.lbl_Gppp_IAgree_Content2}}</a> {{localModel.lbl_shared_email_valid_10}}
          </p>
          <p>{{localModel.lbl_shared_email_valid_11}}</p>
        </div>
      </div>
    </div>
    </div>