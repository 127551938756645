<section>
    <div class="container">
        <h1>{{localModel.lbl_policiesandlegal}}</h1>
        <p class="prompt">
            {{localModel.lbl_inordertomaintain}}
        </p>
    </div>
</section>
<app-policy></app-policy>
<app-have-questions *ngIf="isQuestion"></app-have-questions>
<ngx-spinner></ngx-spinner>