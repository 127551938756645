import { Directive } from "@angular/core";
import {
  AbstractControl,
  Validator,
  NG_VALIDATORS,
  ValidationErrors
} from "@angular/forms";
import { passwordPolicyValidator } from './password-policy';
@Directive({
  selector: "[appPasswordPolicy]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: PasswordPolicyDirective,
      multi: true
    }
  ]
})
export class PasswordPolicyDirective implements Validator 
{
  validate(control: AbstractControl): ValidationErrors {
    return passwordPolicyValidator(control);
}
}
