<section class="policy-content">
    <!-- Summary -->
    <div class="policy">
            <p [innerHtml]="localModel.lbl_Ipsos_SOW_Panel_Cookie_Policy"></p>
    </div> 
    <!-- You have consented... -->
    <div class="policy policy--consent">
        <div class="policy__title"></div>
        <div [hidden]="isUserLogin" class="policy__content">
            <form *ngIf="!isConsentGiven"class="form form--consent">
                <button type="submit" class="btn btn__primary" [innerHtml]="localModel.lbl_Gppp_IAgree" (click)="insertData(1)"></button>
              </form>
              <p> {{localModel.lbl_Gppp_IAgree_Content}}
              </p>
              <div  *ngIf="isConsentGiven" class="policy-status">
                <img src="assets/images/icons/icon_arrow_right.svg" alt="" />
                {{localModel.lbl_Gptc_You_Have_Already}}
              </div> 
        </div>
    </div>
</section>
<ngx-spinner></ngx-spinner>