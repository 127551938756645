import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { environment } from 'src/environments/environment';
import { APIResponse } from '../model/apiResponse.model';
import { Address, AddressDetails, AddressInfo, UpdateAddress } from '../model/memberAddressInfo.model';
import { Globals } from '../shared/shared.global';


@Injectable({providedIn:'root'})
export class MemberAddressService{
    httpOptions: { headers: HttpHeaders; };
    addressInfo = new AddressInfo();
    addressChanged= new Subject<AddressDetails>();
    addressDetails = new AddressDetails();

    apiBasePath: string;
    apiKey: string;


    constructor(private httpClient:HttpClient){
      this.apiBasePath = environment.Member_Profile_API_Base_Path;
      // this.apiKey = environment.Member_Profile_API_Key;
        
        this.httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'            
            })
        
          };
    }
    
    getMemberAddress(MNO:number){
        
      this.addressInfo.MNO = MNO;
      //pipe operator take will only take first subscription and automatically unsubcribe 
      return this.httpClient.post<APIResponse>(
        this.apiBasePath + Globals.GetMemberAddressDeatails,
        this.addressInfo,this.httpOptions
      ).subscribe(res => {
        this.addressDetails = res.Data.AddressDetails;    
        if (res.Data.AddressDetails?.Residential) {
          this.addressDetails.Residential.CompleteAddress = res.Data.AddressDetails.Residential.AddressLine1 + " "
          + res.Data.AddressDetails.Residential.AddressLine2 + "<br/>" + res.Data.AddressDetails.Residential.City +", " +
          res.Data.AddressDetails.Residential.StateInfo.StateCode + " " + res.Data.AddressDetails.Residential.ZipCode ;
        }
        else {
          this.addressDetails.Residential = new Address();
          this.addressDetails.Residential.CompleteAddress = "<br/>";
        }

        if (res.Data.AddressDetails?.Mailing) {
          this.addressDetails.Mailing.CompleteAddress =  res.Data.AddressDetails.Mailing.AddressLine1 + " "
          + res.Data.AddressDetails.Mailing.AddressLine2 + "<br/>" + res.Data.AddressDetails.Mailing.City + ", " +
          res.Data.AddressDetails.Mailing.StateInfo.StateCode + " " + res.Data.AddressDetails.Mailing.ZipCode ;   
        }
        else {
          this.addressDetails.Mailing = new Address();
          this.addressDetails.Mailing.CompleteAddress = "<br/>";
        }
        
        if (res.Data.AddressDetails?.Shipping) {
          this.addressDetails.Shipping.CompleteAddress =  res.Data.AddressDetails.Shipping.AddressLine1 +" "
          + res.Data.AddressDetails.Shipping.AddressLine2 + "<br/>" + res.Data.AddressDetails.Shipping.City +", " +
          res.Data.AddressDetails.Shipping.StateInfo.StateCode + " " + res.Data.AddressDetails.Shipping.ZipCode ;
        }
        else {
          this.addressDetails.Shipping = new Address();
          this.addressDetails.Shipping.CompleteAddress = "<br/>";
        }
        
        if(this.addressDetails.Mailing?.SameAsResidenceForMailing == null){
          this.addressDetails.Mailing.SameAsResidenceForMailing = false;
        }

      
        if(this.addressDetails.Shipping?.SameAsMailingForShipping == null){
          this.addressDetails.Shipping.SameAsMailingForShipping = false;
        }

        if(this.addressDetails.Shipping?.SameAsResidenceForShipping == null){
          this.addressDetails.Shipping.SameAsResidenceForShipping = false;
        }

        if(this.addressDetails.Shipping.SameAsResidenceForShipping === true ||
          this.addressDetails.Shipping.SameAsMailingForShipping === true){
          this.addressDetails.Shipping.IsShippingAddressChecked = true;
        }

        this.setAddress(this.addressDetails);
      });
    }

    setAddress(res:AddressDetails){
      this.addressChanged.next(this.addressDetails);
    }

    getAddress(){
      return this.addressDetails;
    }

    getStates(){
      //pipe operator take will only take first subscription and automatically unsubcribe 
       return this.httpClient.get<APIResponse>(
         this.apiBasePath + Globals.GetAddressStateList,
         this.httpOptions
       );
    }


    saveAddress(address:UpdateAddress){
        return this.httpClient.post(
          this.apiBasePath + Globals.UpdateMemberAddress,
        address,this.httpOptions);  
    }

}
