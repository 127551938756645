import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';
import { SignInService } from 'src/app/services/signin.service';
import { LanguageValue } from '../../model/LanguageValue';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isUserSignIn:boolean;
  mcafeeAlignment :string= "right";
  isChangeLanguageDropDown:boolean;
  localModel : any;
  fullyear;
  Langs:LanguageValue[]
  
  selected1: number;
  constructor(private localizationService:LocalizationService,private signInService:SignInService) {
    this.Langs=localizationService.getLanguageArray();
  }
 

  ngOnInit(): void {
    let now = new Date();
    var year = now.getFullYear();
    this.fullyear = year;
 
    this.getLanguage();
   // this.isChangeLanguageDropDown = AppConfig.configurations.appSettings.LanguageChangeDropDown;
    // this.isChangeLanguageDropDown = AppConfig.configurations.appSettings.LanguageChangeDropDown;
    this.isChangeLanguageDropDown = environment.LanguageChangeDropDown;
    this.localModel = this.localizationService.getLocalization();
    
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
      this.getLanguage();
    });
    this.isUserSignIn = this.signInService.isSignIn();
    
    this.signInService.userLoginChanged.subscribe(res => {
      this.isUserSignIn = this.signInService.isSignIn();
    });

  }

  onLanguageChange(event){
    this.selected1=event
    const newVal = event==1?'english':'spanish';
    // const newVal = event.target.value;
    this.localizationService.changeLocalization(newVal);
  }

  getLanguage(){
    var lang=this.localizationService.getlocalizationfromSession();
    switch(lang){
      case "english":
        this.selected1 = this.Langs.find(x=>x.languageId == 1).languageId;
        break;
      case "spanish":
        this.selected1 = this.Langs.find(x=>x.languageId == 2).languageId;
        break;
       default:
        this.selected1 = this.Langs.find(x=>x.languageId == 1).languageId;
       break;
    }
  }

}
