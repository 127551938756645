import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.css']
})
export class RewardsComponent implements OnInit {

  localModel: any;

  @Input() balance: number;
  @Output() btnClicked = new EventEmitter();

  constructor(private localizationService: LocalizationService) { }

  ngOnInit(): void {

    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
  }

  onButtonClick() {
    this.btnClicked.emit();
  }

}
