export class MemberInfo{
    MemberInfo:MyProfileModel;
}

export class MyProfileModel{
    MNO : number;
    PanelMemberId:string;
    AccountId:string;
    HouseHoldNo:number;
    EmailAddress1:string;
    EmailAddress2:string; 
    FirstName:string;
    FullName:string;
    Language:number;
    LastName:string;
    Age:number;
    MemberStatus:number;
    Phone:string;
    IsPrimary:boolean;
    HouseholdPhoneNumber:NumberSection;
    PersonalPhoneNumber:NumberSection;
    PersonalIsMobile:number;
    IsPersonalMobile:boolean;
    IsPhonePrefered:string;
    PersonalConsentIVR:number;
    PersonalConsentSMS:number;
    PersonalConsentBlog:string;
    PersonalConsentEmails: string;
    HouseholdIsMobile:number;
    HouseholdConsentIVR: number;
    HouseholdConsentSMS: number;
    HouseholdConsentBlog: string;
    HouseholdConsentNewsletter: string;
    HouseholdConsentEmails: string;
    AvatarImage: string;
    AvatarThemeCode: string;
    ThemeSequenceNumber: string;
    MemberStatusString:string;
    MembershipString:string;
    HHPhoneNumber:string;
    PPPhoneNumber:string;
    PersonalPhoneSameasHH:boolean;
}

export class NumberSection{
    Section1:string;
    Section2:string;
    Section3:string;
}


export class UpdateProfile{
    MNO : number;
    PanelMemberId:string;
    FirstName:string;
    LastName:string;
    EmailAddress:string;
    Language:string;
    ExistingPasswordKey:string;
    OldPassword:string;
    NewPassword:string;
    OperationType:1;
    OperationMessage:"Address updated via UMP self-service";

}


export class GetOtherHHModel{
    HouseHoldNo:number;
    MNO:number;
  }

  export class EmailVerificationData{
    MNO: number;
    HHNO: number;
    Email: string;
    Language: number;
    ApplicationName: string;
    IsDataValidationRequired: boolean;
    IsMailSendRequired: boolean;
  }

  export class EmailVerificationResponse {
    VerificationLink: string;
    IsError: boolean;
    ErrorMessage: string;
  }
  