import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ForgotPassword } from '../model/forgotPassword.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class ForgotPasswordService{
    forgotPasswordChanged = new Subject<ForgotPassword>();
    checkEmailIdChanged=new Subject<ForgotPassword>();
    public forgotPassword:ForgotPassword;
    apiBasePath: string;
    apiKey: string;
    httpOptions:any;
    SendResetPassword:string; 
    constructor(private http:HttpClient) {
          this.apiBasePath = environment.Login_API_Base_Path;
          this.SendResetPassword="api/Login/SendResetPasswordForScreenwise";
          this.httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',         
              'PanelName':environment.PanelName 
            })
          };
     }
    // getForgotPassword(){
    //     //this.forgotPassword = new ForgotPassword("s@gmail.com",true);
    //     this.forgotPasswordChanged.next(this.forgotPassword);
    // }

    getForgotPassword(data :any):Promise<any> {
      this.httpOptions.headers =
      this.httpOptions.headers.set('Recaptcha', data.captcha);
        return this.http.post(this.apiBasePath + this.SendResetPassword, data,this.httpOptions).toPromise();
      }

    getSharedEmail(){
        //call api with firstName,lastName,DOB
        this.forgotPassword = new ForgotPassword();
        this.forgotPassword.firstName = "Suj";
        this.forgotPassword.lastName = "adk";
        this.forgotPassword.dob = "";
        this.forgotPasswordChanged.next(this.forgotPassword);
    }
}
