import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-welcome-blade',
  templateUrl: './welcome-blade.component.html'
})
export class WelcomeBladeComponent implements OnInit {
  localModel: any;

  constructor(private localizationService: LocalizationService) { 

 

  (function () {
    const NUM_IMAGES = 12;
    let numImagesInGrid = 6;
    const IMG_FADE_DELAY = 1000;
  
    const currentImgs = ["img0", "img1", "img2", "img3", "img4", "img5"];
    let newClassName, imgToChange, newImg;
  
    window.addEventListener("resize", function () {
      const windowWidth = this.innerWidth;
      numImagesInGrid = Math.floor(windowWidth / 208);
    });
  
    setInterval(function () {
      const randomNum = Math.floor(Math.random() * NUM_IMAGES);
      const idxImgToChange = Math.floor(Math.random() * numImagesInGrid);
  
      // New random image class
      newImg = "img" + randomNum;
  
      // check if image already showing
      if (currentImgs.indexOf(newImg) > -1) {
        return;
      }
  
      // update image
      currentImgs[idxImgToChange] = newImg;
     var  imgIdToChange = "img" + idxImgToChange;
  
      const divToChange = document.getElementById(imgIdToChange);
      if (divToChange) {
        newClassName = "img change img" + randomNum;
        divToChange.classList.add("changeBefore");
        setTimeout(function () {
          divToChange.setAttribute("class", newClassName);
        }, 250);
      }
    }, IMG_FADE_DELAY);
  })();
}
ngOnInit(): void {
  this.localModel = this.localizationService.getLocalization();

  this.localizationService.localizationChanged.subscribe(res => {
    this.localModel = res;
    
  });
}

}
