<section class="earn-rewards angular-2">
    <div class="container">
      <h2>{{localModel.Lbl_sign_in_earnrewardmsg}}</h2>
      <p>{{localModel.lbl_you_can_earn_easily}}</p>

      <div class="earn-rewards-home">
        <div class="card-reward">
          <div class="card-reward--icon">
            <img src="assets/images/icons/icon_reward.svg" alt="" />
          </div>
          <div class="card-reward--content" [innerHtml]="localModel.Lbl_sign_in_earn20msg">
            
          </div>
          <div class="card-reward--amount">
            <div>
              <span class="reward-currency">$</span>
              <span class="reward-value">20</span>
            </div>
          </div>
        </div>

        <div class="card-reward">
          <div class="card-reward--icon"> 
            <img src="assets/images/devices/router.svg" alt="" />
          </div>
          <div class="card-reward--content" [innerHtml]="localModel.Lbl_sign_in_earn50Routermsg">
            
          </div>
          <div class="card-reward--amount">
            <div>
              <span class="reward-currency">$</span>
              <span class="reward-value">100</span>
            </div>
          </div>
        </div>

        <div class="card-reward">
          <div class="card-reward--icon">
            <img src="assets/images/icons/icon_people_group.svg" alt="" />
          </div>
          <div class="card-reward--content" [innerHtml]="localModel.Lbl_sign_in_earn7msg">
            
          </div>
          <div class="card-reward--amount">
            <div>
              <span class="reward-currency">$</span>
              <span class="reward-value">16</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>