
<section  id="top" class="enrollment">
    <section class="messaging signed-out" *ngIf="showValidationMessages">      
        <div class="messaging__close" (click)="onClose(enrollment)">
            <img src="assets/images/icons/icon_close.svg" alt="" />
        </div>
        <div class="messaging__body">
            <h3 *ngIf="isInvalidNeoCode">   {{localModel.lbl_enrollement_valid_1}}</h3>
            <h3 *ngIf="isInvalidCaptcha">{{localModel.lbl_enrollement_valid_2}}</h3>
          </div>
        <div class="messaging__actions">
            <button class="btn btn__primary" (click)="onClose(enrollment)">
                <img src="assets/images/icons/icon_check.svg" alt="" />
             {{localModel.btn_Notification_One}}
            </button>
        </div>
    </section>
    <br/>
    <img src="assets/images/people/bg_enrollment.png" alt="" class="enrollment__bg-img" />
    <div class="container container--fluid">
        <div class="container--form container--form--large">
            <h1 [innerHtml]="localModel.lbl_Enroll_title"></h1>

            <p class="prompt" [innerHtml]="localModel.lbl_Enroll_GetStarted"></p>

            <div class="inline-flex-mobileplus">
                    <form class="form" (ngSubmit)="onSubmit(enrollment)" #enrollment="ngForm">
                    <div class="form__code">
                        <input type="text" name="code1" id="code1" #code1 ngModel maxlength="1" [appAutoMoveNext]="code2" required/>
                        <input type="text" name="code2" id="code2" #code2 ngModel maxlength="1" [appAutoMoveNext]="code3" required/>
                        <input type="text" name="code3" id="code3" #code3 ngModel maxlength="1" [appAutoMoveNext]="code4" required/>
                        <input type="text" name="code4" id="code4" #code4 ngModel maxlength="1" [appAutoMoveNext]="code5" required/>
                        <input type="text" name="code5" id="code5" #code5 ngModel maxlength="1" [appAutoMoveNext]="code6" required/>
                        <input type="text" name="code6" id="code6" #code6 ngModel maxlength="1" required/>
                    </div>
                    <div class="g-recaptcha" style="padding: 0 10%; padding-top: 5px;">
                        <ngx-recaptcha2 #captchaElem [siteKey]="sitekey"
                        (success)="handleSuccess($event)" [size]="size"
                      [hl]="lang"(load)="handleReload()"[theme]="theme" [type]="type" name="recaptcha" ngModel>
                        </ngx-recaptcha2>    
                    </div>
            <br/>
                    <button
                        type="submit" [disabled]="!enrollment.valid"
                        class="btn btn__primary"
                        id="continueEnrollment"
                        [innerHtml]="localModel.lbl_enrollment_continue">
                        
                    </button>
                </form>
                <img src="assets/images/enrollment/code.png" alt="" class="enrollment__img" />
            </div>

            <div class="subpanel">
                <div class="subpanel__left">
                    <h2 [innerHtml]="localModel.lbl_Enroll_NeedH"></h2>
                    <ul class="list-largetext">
                        <p  [innerHtml]="localModel.lbl_Enroll_NeedH_Queslist"></p>
                    </ul>
                </div>
                <div class="subpanel__right">
                    <div class="cta cta__stacked">
                        <a (click)="onContactUs()" class="btn btn__secondary"
                            >{{localModel.lbl_enrollment_contactus}}</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="posting && formAuthenticateClicked">
        <form ngNoForm  name="testGroup" action="{{surveyLink}}"  method="POST">
            <div class="form__code">
                <input type="hidden"name="txtslc" value="{{neoCode}}">
                <input type="hidden"name="hdduser1Param" value="">
                <input type="hidden"name="hdduser2Param" value="">
                <input type="hidden"name="hdduser3Param" value="{{ddLang}}">
                <input type="hidden"name="hdduser4Param" value="">
                <input type="hidden"name="hdduser5Param" value="">
                <input type="hidden"name="hdduser6Param" value="">
                <input type="hidden"name="hdduser7Param" value="">
                <input type="hidden"name="hdduser8Param" value="">
                <input type="hidden"name="hdduser9Param" value="">
                <input type="hidden"name="error" value="{{errorLink}}">
                <input type="hidden"name="hdnPanelName" value="SOW2">
            </div>
            <button #formauthenticate 
            type="submit" 
            class="btn btn__primary"
            style="display: none;"
            id="continueEnrollment">
            {{localModel.lbl_enrollment_continue}}
        </button>
        </form>
    </div>
</section>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.4)"></ngx-spinner>

