import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommunicationPrefRequest } from 'src/app/model/CommunicationPrefRequest';
import { MyProfileModel } from 'src/app/model/myProfile.model';
import { UpdatePhoneInfo } from 'src/app/model/phone-info.model';
import { MyProfileService} from 'src/app/services/myProfile.service';
import { LocalizationService } from 'src/app/services/localization.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PopupComponent } from 'src/app/Common/popup/popup.component';
import { MatDialog } from '@angular/material/dialog';
import { APIResponse } from 'src/app/model/apiResponse.model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-change-phone-numbers',
  templateUrl: './change-phone-numbers.component.html',
  styles: [
    `button:disabled, 
    .disabled label {
        cursor: default;
    }`
  ]
})
export class ChangePhoneNumbersComponent implements OnInit {

  myProfile : MyProfileModel;
  interimidiateProfile:MyProfileModel;
    localModel: any;
  openpopup: boolean=false;
  PersonalConsentIVRFlag : boolean = false;
  PersonalConsentSMSFlag : boolean = false;
    constructor(private myProfileService: MyProfileService,
       private localizationService: LocalizationService,
       private route:Router,
       private spinnerSrv: NgxSpinnerService,private dialog: MatDialog,private titleService:Title) {
        
   }
   sameAsHouseholdPhone:boolean=false;
   
  ngOnInit(): void {   
    this.localModel = this.localizationService.getLocalization();
    this.titleService.setTitle(this.localModel.lbl_changephone_browsertitle)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_changephone_browsertitle)
      })
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
    this.getMemberProfile();  
  }

  getMemberProfile(){
    this.spinnerSrv.show();
    this.myProfile = this.myProfileService.getProfile();
    this.interimidiateProfile = {...this.myProfile};
      setTimeout(() => {
        /** spinner ends after 5 seconds */
        this.spinnerSrv.hide();
    }, 500);
    
    if (!this.myProfile.PPPhoneNumber) {
      this.isPersonalPhoneEmpty = true;
    }
    // this.myProfileService.getMemberProfile().subscribe(res =>{
      
    //   this.myProfileService.setProfile(res.Data.MemberInfo);

    //   });
    
    //   this.myProfileService.myProfileChanged.subscribe(res => {
    //     this.myProfile = res;
    //     this.interimidiateProfile = {...this.myProfile};
    //       setTimeout(() => {
    //         /** spinner ends after 5 seconds */
    //         this.spinnerSrv.hide();
    //     }, 500);
        
    //     // console.log("profile change");
    //     // console.log(this.myProfile);
    //     //this.myProfile.PPPhoneNumber = "9860414431";
    //   });
  }

  public isPPValidFlg:boolean = true;
  public isHHValidFlg:boolean = true;
  public isPersonalPhoneEmpty = false;
  validatePhoneNo(field) {
    var isValidFlg = true;
    
    var phoneNumDigits = field.value.replace(/\D/g, '');

  
    isValidFlg = (phoneNumDigits.length == 10);
  
    var formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length >= 6)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
    else if (phoneNumDigits.length >= 3)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);
  
    field.value = formattedNumber;

    switch(field.name){
      case "personalPhoneNumber":
        this.isPPValidFlg = isValidFlg;
        break;
      case "homePhoneNumber":
        this.isHHValidFlg = isValidFlg;
          break;
    }
  }

  onSubmit(form : NgForm){         
    // console.log(form.value);
    // console.log("PersonalConsentSMS " + this.myProfile.PersonalConsentSMS);
    // console.log("PersonalConsentIVR " + this.myProfile.PersonalConsentIVR);
    // console.log("PersonalIsMobile " + this.myProfile.PersonalIsMobile);
    // console.log("HouseholdIsMobile " + this.myProfile.HouseholdIsMobile);
    if(this.PersonalConsentIVRFlag === false){
      this.myProfile.PersonalConsentIVR = null;
    }
    if(this.PersonalConsentSMSFlag === false){
      this.myProfile.PersonalConsentSMS = null;
    }
   
    var communicationPrefRequest = new CommunicationPrefRequest(this.myProfile.PersonalConsentIVR,
      this.myProfile.PersonalConsentSMS,
      this.myProfile.PanelMemberId,
      this.myProfile.MNO,this.myProfile.HouseHoldNo,);
     
      //personal update
     var updatePPhoneInfo = new UpdatePhoneInfo(this.myProfile.MNO,
      this.myProfile.PanelMemberId,
      this.myProfile.HouseHoldNo,
      form.value.sameAsHouseholdPhone === true ? +this.myProfile.HHPhoneNumber.replace(/\D/g, '') : +this.myProfile.PPPhoneNumber.replace(/\D/g, ''),
      +this.interimidiateProfile.PPPhoneNumber.replace(/\D/g, ''),
      this.interimidiateProfile.PersonalIsMobile,
      this.myProfile.PersonalIsMobile,null,this.myProfile.PersonalPhoneSameasHH
      );
     
    //this.myProfileService.u
    //console.log(updatePPhoneInfo);
      //HH update
    var updateHPhoneInfo = new UpdatePhoneInfo(this.myProfile.MNO,
      this.myProfile.PanelMemberId,
      this.myProfile.HouseHoldNo,
      +this.myProfile.HHPhoneNumber.replace(/\D/g, ''),
      +this.interimidiateProfile.HHPhoneNumber.replace(/\D/g, ''),
      this.interimidiateProfile.HouseholdIsMobile,
      this.myProfile.HouseholdIsMobile,null,null
      );
     
    //this.myProfileService.u
    //console.log(updateHPhoneInfo);
    
    this.updateCommunication(updatePPhoneInfo,updateHPhoneInfo,communicationPrefRequest,this.myProfile.IsPrimary);
    

  }

  updateCommunication(updatePPhoneInfo:UpdatePhoneInfo,updateHPhoneInfo:UpdatePhoneInfo,
    communicationPrefRequest : CommunicationPrefRequest,isPrimary:boolean)
  {
    this.spinnerSrv.show();
    if(isPrimary)
    {
      return this.myProfileService.updatePersonalPhoneInfo(updatePPhoneInfo).subscribe((res:APIResponse) => 
      {
        this.myProfileService.updateHoseholdPhoneInfo(updateHPhoneInfo).subscribe((res1:APIResponse) =>
        {
          this.myProfileService.updateCommunicationPref(communicationPrefRequest).subscribe((res2:APIResponse) => 
          {
            this.myProfileService.updateHoseholdCommunicationPref(communicationPrefRequest).subscribe((res3:APIResponse)=>{
              if(res3!==null && res3.Code=='105' && !this.openpopup){
                this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Invalid_MNO);  
              }
              setTimeout(() => {
                /** spinner ends after 5 seconds */
                this.spinnerSrv.hide();
                this.route.navigate(["myprofile"]);
            }, 500);                   
              });   
              if(res2!==null && res2.Code=='105' && !this.openpopup){
                this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Invalid_MNO);  
              }   
            });
            if(res1!==null && res1.Code=='105' && !this.openpopup){
              this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Invalid_MNO);  
            }      
          });
          if(res!==null && res.Code=='105' && !this.openpopup){
            this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Invalid_MNO);  
          }      
        });
    }
    else{
      return this.myProfileService.updatePersonalPhoneInfo(updatePPhoneInfo).subscribe((res:APIResponse) => 
      {
        this.myProfileService.updateCommunicationPref(communicationPrefRequest).subscribe((res2:APIResponse) => 
          {
              setTimeout(() => {
                /** spinner ends after 5 seconds */
                this.spinnerSrv.hide();
                this.route.navigate(["myprofile"]);
            }, 500);
            if(res2!==null && res2.Code=='105' && !this.openpopup){
              this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Invalid_MNO);  
            }                       
        });
        if(res!==null && res.Code=='105' && !this.openpopup){
          this.showPopup(this.localModel.lbl_Popup_Heading_Failure, this.localModel.lbl_Invalid_MNO);  
        }    
    });
  }
}

  onItemChange(value:number,source:number){
    
    switch(source)
    {
        case 1:
          if(this.myProfile.PersonalPhoneSameasHH == true){
            this.myProfile.PersonalIsMobile = value;
            this.myProfile.HouseholdIsMobile = value;
          }else{
            this.myProfile.HouseholdIsMobile = value;
          }
          break;
        case 2:
          this.myProfile.PersonalIsMobile = value;
          break;
        case 3:
          this.myProfile.PersonalConsentSMS = value;
          this.PersonalConsentSMSFlag = true;
            break;
        case 4:
          this.myProfile.PersonalConsentIVR = value;
          this.PersonalConsentIVRFlag = true;
          break;
    }

//    this.myProfile.PersonalIsMobile = value;
  }

  
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  getRequiredErrorMessage(fieldName: string) {
    var msg = '';

    switch (fieldName) {
      case 'homePhone':
        msg = this.localModel.lbl_ChangePhone_Hpnolength
        break;
      case 'personalphone':
        msg = this.localModel.lbl_ChangePhone_Ppnolength 
        break;
      
      default:
        break;
    }

    return msg;
  }

  private showPopup(heading: string, message: string) {
    this.openpopup=true;
    this.dialog.open(PopupComponent, {

      disableClose: true,
      data: {
        header: heading,
        content: message
      }
    });
  }
}
