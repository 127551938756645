<!-- Blade: Question -->
<section class="questions">
<div class="container">
    <h2 [innerHtml]="localModel.lbl_MyReward_HaveQuestions"></h2>
    <p [innerHtml]="localModel.lbl_MyReward_heretohelp" ></p>
    <div class="cta">
    <a routerLink="/contact-us" class="btn btn__primary" [innerHtml]="localModel.lbl_ContactUs_title" > </a>
    <a routerLink="/helpcenter" class="btn btn__primary">{{localModel.lbl_helpcenter_label}}</a>
    </div>
</div>
</section>
