import { Component, OnInit } from '@angular/core';
import { DeviceService } from 'src/app/services/device.service';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-add-new-device',
  templateUrl: './add-new-device.component.html'
})
export class AddNewDeviceComponent implements OnInit {

  
  
 localModel : any;
 constructor(private localizationService:LocalizationService,private deviceService:DeviceService) { 
   
   
   
 }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
  }
  onCloseAddNewDevice(){
    this.deviceService.showAddNewDeviceModel.next(2);
  }
}
