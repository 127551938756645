<section class="welcome">
    <div class="container container--fluid">
      <h2>{{localModel.lbl_welcomeblade_content}}</h2>

      <div class="img-grid">
        <div class="img img0" id="img0"></div>
        <div class="img img1" id="img1"></div>
        <div class="img img2" id="img2"></div>
        <div class="img img3" id="img3"></div>
        <div class="img img4" id="img4"></div>
        <div class="img img5" id="img5"></div>
      </div>
    </div>
  </section>