import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html'
})
export class PopupComponent implements OnInit {

  header: string;
  content: string;
  localModel: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {header: string, content: string}, private dialogRef: MatDialogRef<PopupComponent>,
    private localizationService:LocalizationService) { 

    this.header = data.header;
    this.content = data.content;
  }

  ngOnInit(): void { 

    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(result => {
      this.localModel = result;
    });
  }

  onClose() {
    if(this.localizationService.getWithDrawanPopUpVariable()){
      this.dialogRef.close(false); 
    }else{
      this.dialogRef.close(true);
    }
  }

}
