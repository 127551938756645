import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({providedIn:'root'})
export class PostNeoCodeService{

    apiBasePath: string;
    apiKey: string;
    httpOptions:any;
    errorLink:string;
    neoCode:string;
    // GetAuthenticateUserDetails ="api/Login/GetAuthenticateUserDetails";
    // GetOffPanelDetails ="api/Login/AuthenticateNeoCode";
    // isSignIn(){
    //     return true;
    // }
    constructor(private http:HttpClient) {
        this.apiBasePath = environment.Login_API_Base_Path;
        this.errorLink="//172.25.11.140:9021/enroll/new-error.html";
        if (sessionStorage.getItem("neoCode") !== null && sessionStorage.getItem("neoCode") !== undefined){
            this.neoCode=sessionStorage.getItem("neoCode");
        }
       //this.apiKey =  AppConfig.configurations.appSettings.UserAuthenticationAPIKey;
   
        this.httpOptions = {
          headers: new HttpHeaders({
            'txtslc': this.neoCode,
            "hdduser1Param": "",
            "hdduser2Param": "",
            "hdduser3Param": "1",
            "hdduser4Param": "",
            "hdduser5Param": "",
            "hdduser6Param": "",
            "hdduser7Param": "",
            "hdduser8Param": "",
            "hdduser9Param": "",
            "hdnPanelName":"SOW2",
            "error":this.errorLink
          })
        };
   }
    
      postNeoCode(): Promise<any> {
        return this.http.post("http://localhost:51430/Neo.aspx",this.httpOptions).toPromise();  //https://localhost:44352/ webapi host url  
      }
      
}