<!-- Modal: Forgot Password -->
<div id="forgotPasswordModal" class="modal" style="display: block;">

    <div class="modal__dropsheet"></div>

    <div class="modal-content">
      <div class="close" (click)="onClose()">
        <img src="assets/images/icons/icon_close.svg" alt="" />
      </div>

      <div class="modal-body">
        <div class="modal-form-content">
          <h2 [innerHtml]="localModel.Lbl_forogt_password_Title"></h2>
          <section class="messaging signed-out" *ngIf="showCaptchaValidation" id="top">       
            <div class="messaging__close" (click)="onCloseSection()">
                <img src="assets/images/icons/icon_close.svg" alt="" />
            </div>
            <div class="messaging__body">   
                <h3>{{localModel.lbl_enrollement_valid_2}}</h3>
              </div>
            <div class="messaging__actions">
                <button class="btn btn__primary" (click)="onCloseSection()">
                    <img src="assets/images/icons/icon_check.svg" alt="" />
                 {{localModel.btn_Notification_One}}
                </button>
            </div>
        </section>

          <form class="form" #passwordin="ngForm">
            <div class="form-control">
              <label for="email" class="form-control__label" [innerHtml]="localModel.Lbl_forgot_password_emailMsg"></label>
              <input type="email" class="form-control__text form-control__error" placeholder={{localModel.Lbl_forgot_password_emailInputmsg}} ngModel name="email" #email="ngModel" required email />
              <div class="form-control__error-text" *ngIf="!email.valid && email.touched" [innerHtml]="localModel.Lbl_forgot_password_emailValidationmsg">
               
              </div>
            </div>
            <div class="form-control__prompt" [innerHtml]="localModel.Lbl_forgot_password_resetpassMsg"></div>
            
            <div class="g-recaptcha" style="padding: 0 10%; padding-top: 5px;">
              <ngx-recaptcha2 #captchaElem [siteKey]="sitekey"
               [size]="size"
            [hl]="lang"(load)="handleReload()"[theme]="theme" [type]="type" name="recaptcha" ngModel>
              </ngx-recaptcha2>    
          </div>
            
            <div class="form__actions">
              <button
                type="button"
                class="btn btn__primary modal-link"
                [disabled]="!passwordin.valid"
                (click)="onSendMeLink(passwordin)" [innerHtml]="localModel.BtnLbl_forgot_password_sendlink"></button>
              <button type="button" class="btn btn__secondary"  (click)="onClose()" [innerHtml]="localModel.BtnLbl_forgot_password_cancel"></button>
            </div>
          </form>
        </div>

        <div class="sidebar sidebar--form">
          <p class="sidebar__prompt" [innerHtml]="localModel.Lbl_forgot_password_navQuesMsg"></p>

          <p [innerHtml]="localModel.Lbl_forgot_password_NavMsg1">
            
          </p>
          <p [innerHtml]="localModel.Lbl_forgot_password_NavMsg2"></p>
        </div>
      </div>
      <div class="modal-footer"></div>
    </div>
  </div>
  <ngx-spinner bdColor="rgba(51, 51, 51, 0.4)"></ngx-spinner>
