export class HCQuestions{
    hcQuestionid :number;
    hcQuestion : string;

    constructor(helpcenterQuestion:string){
        this.hcQuestion =helpcenterQuestion;
    }
} 

export class HCAnwsers{
    hcQuestionid :number;
    hcAnswerid : number;    
    hcAnswer : string;

    constructor(helpcenterAnswer:string){
        this.hcAnswer =helpcenterAnswer;
    }
}

export class HelpCenterQuestionAnwser {
    sectionid : string[];
    hcQquestionsList:HCQuestions[];
    hcAnwsersList : HCAnwsers[];    
    hcPanelTitle : string;
    
    

    constructor(helpcenterSectionid:string[],helpCenteQquestions:HCQuestions[],helpCenterAnwsers:HCAnwsers[],helpCenterPanelTitle:string){
        this.sectionid =helpcenterSectionid;
        this.hcQquestionsList=helpCenteQquestions;
        this.hcAnwsersList=helpCenterAnwsers;
        this.hcPanelTitle=helpCenterPanelTitle;
        
    }
}

