<section class="messaging signed-out" *ngIf="showCaptchaValidation" id="top">    
    <div class="container">  
        <div class="messaging__close clickable" (click)="onClose()">
            <img src="assets/images/icons/icon_close.svg" alt="" />
        </div>
        <div class="messaging__body" >
            <h3>{{ localModel.lbl_ContactUs_CaptchaMsg }}</h3>
        </div>
        <div class="messaging__actions">
            <button class="btn btn__primary" (click)="onClose()">
                <img src="assets/images/icons/icon_check.svg" alt="" />
                {{ localModel.btn_Notification_One }}
            </button>
        </div>
  </div>
</section>

<section class="contact-us">

    <img src="assets/images/people/bg_contact_us.png" alt="" class="contact-us__bg-img" />

    <div class="container">
        <div class="container--form container--form--large">
            <h1>{{ localModel.lbl_ContactUs_title }}</h1>

            <div class="form-layout">
                <div class="col-form">

                    <h2>{{ localModel.lbl_ContactUs_SendMsg }}</h2>
                    
                    <!-- Contact Us Form -->
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">

                        <!-- Name -->
                        <div class="form-control">
                            <label for="name" class="form-control__label">{{ localModel.Lbl_contact_us_Name }}</label>                          
                            <input type="text" id="name" formControlName="name" maxlength="100" />
                            <span *ngIf="contactForm.get('name').invalid && contactForm.get('name').touched">
                                <span *ngIf="contactForm.get('name').errors?.required">{{ localModel.lbl_ContactUs_Val_Name_Req }}</span>
                                <span *ngIf="contactForm.get('name').errors?.pattern">{{ localModel.lbl_ContactUs_Val_Name_Pattern }}</span>                               
                            </span>                       
                        </div>

                        <!-- Email -->
                        <div class="form-control">
                            <label for="email" class="form-control__label">{{ localModel.Lbl_contact_us_Email }}</label>                            
                            <input type="email" id="email" formControlName="email" maxlength="50" />
                            <span *ngIf="contactForm.get('email').invalid && contactForm.get('email').touched">
                                <span *ngIf="contactForm.get('email').errors?.required">{{ localModel.lbl_ContactUs_Val_Email_Req }}</span>
                                <span *ngIf="contactForm.get('email').errors?.email">{{ localModel.lbl_ContactUs_Val_Email_Pattern }}</span>                               
                            </span>
                        </div>

                        <!-- Phone Number -->
                        <div class="form-control">
                            <label for="phoneNumber" class="form-control__label">{{ localModel.Lbl_contact_us_Phone }}</label>                            
                            <input type="tel" id="phone" formControlName="phone" maxlength="10" />
                            <span *ngIf="contactForm.get('phone').invalid && contactForm.get('phone').touched">
                                <span *ngIf="contactForm.get('phone').errors?.required">{{ localModel.lbl_ContactUs_Val_Phone_Req }}</span>
                                <span *ngIf="contactForm.get('phone').errors?.pattern">{{ localModel.lbl_ContactUs_Val_Phone_Pattern }}</span> 
                            </span>
                        </div>

                        <!-- Contact Option -->
                        <fieldset>
                            <legend>{{ localModel.Lbl_contact_us_WayofContact }}</legend>

                            <div class="form-control">

                                <input type="radio" id="emailContact" formControlName="howToContact" [value]="0" />
                                <label for="emailContact">{{ localModel.RadioLbl_contact_us_Email }}</label>
                                                                   
                                <input type="radio" id="phoneContact" formControlName="howToContact" [value]="1" />                                
                                <label for="phoneContact">{{ localModel.RadioLbl_contact_us_Phone }}</label>

                            </div>
                        </fieldset>

                        <!-- Message -->
                        <div class="form-control">
                            <label class="form-control__label" for="message">{{ localModel.Lbl_contact_us_Message }}</label>
                            <textarea class="form-control__field" id="message" formControlName="message" 
                                [placeholder]="localModel.lbl_ContactUs_Msg_MaxLimit" maxlength="5000">
                            </textarea>
                            <span *ngIf="contactForm.get('message').invalid && contactForm.get('message').touched">
                                {{ localModel.lbl_ContactUs_Val_Msg_Req }}
                            </span>
                        </div>

                        <!-- Recaptcha -->
                        <div *ngIf="!isUserSignIn" class="g-recaptcha container-recaptcha">
                            <ngx-recaptcha2 #captcha id="recaptcha" formControlName="recaptcha" 
                                [siteKey]="sitekey" [hl]="lang" (load)="handleReload()" [theme]="theme" [type]="type" [size]="size">
                            </ngx-recaptcha2>    
                        </div>

                        <!-- Submit button -->
                        <div class="form__actions">
                            <button type="submit" class="btn btn__primary" [disabled]="contactForm.invalid">{{ localModel.BtnLbl_contact_us_Send }}</button>                                                                                  
                        </div>

                        <p class="supplemental-text">
                            {{ localModel.Lbl_contact_us_ResponceMsg }}                            
                        </p>

                    </form>
                </div>

                <div class="sidebar">

                    <h3>{{ localModel.NavLbl_contact_us_help }}</h3>
                    <p class="sidebar__prompt">{{ localModel.lbl_ContactUs_HereToHelp }}</p>

                    <ul class="contact-options">
                        <li>
                            <img src="assets/images/icons/icon_mobile.svg" class="contact-options__icon icon-phone" alt="" />                          
                            <p>
                                <span [innerHtml]="localModel.NavLbl_contact_us_callus_line1"></span><br>
                                <span class="nowrap">{{ localModel.NavLbl_contact_us_callus_line2 }}</span>
                            </p>
                        </li>

                        <li>
                            <img src="assets/images/icons/icon_clock.svg" class="contact-options__icon icon-schedule" alt="" />
                            <div class="contact-options__schedule">
                                <div [innerHtml]="localModel.NavLbl_contact_us_Hours"></div>                                
                            </div>                             
                        </li>

                        <li>
                            <img src="assets/images/icons/icon_mail.svg" class="contact-options__icon icon-email" alt="" />                            
                            <p [innerHtml]="localModel.NavLbl_contact_us_SupportEmail" class="line-break"></p>                            
                        </li>
                    </ul>

                </div>               
            </div>
        </div>
    </div>

</section>

<!-- loading spinner -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.4)"></ngx-spinner>
