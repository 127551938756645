<section class="devices angular-1" >


  <h2 class="panel__header" style="text-align:center;">{{localModel.lbl_screenwise_meters}}</h2>
  
  <div class="devices__wrapper" >
    
    <!-- Device 1: Router -->
    <div class="device-flip" (click)="onDeviceSelected(0)" [ngClass]="flipCard(0)">
      <div class="device-container" >
        <!-- Front -->
        <div class="device-front">
          
          <div class="device">
            <br/>
            <br/>
           <div class="device__title">
            <h3>{{localModel.lbl_screenwise_meters_phone}}</h3>
            <br/>
           <br/><br/><br/>
          </div>
          
              <div class="device__img">
              <img src="assets/images/devices/smartphone.svg" alt="" />
            </div>                         
          </div>
        </div>
        <!-- Back -->
        <div class="device-back">
          <div class="device">
            <div class="device__status">
              <img src="assets/images/icons/icon_close.svg" alt="" />
            </div>
            <div class="device__title">
              <h3 style="text-align:center;">{{localModel.lbl_screenwise_meters_phone}}</h3>
              <br>
              <div> 
                <a href= "{{MigratePanel !== null ? localModel.lbl_migratedPanel : localModel.lbl_phone_link }}" target="_blank">
                  <img style="width: 14rem;"src="assets/images/deviceslogo/google-play-badge.png" alt="" />
                </a>
                <br>
                <a href="{{ localModel.lbl_iphone_link }}" target="_blank">
                  <img style="width: 12rem;" src="assets/images/deviceslogo/iOS_app_Store.svg" alt="" />
                </a> 
            </div> 
            </div>            
          </div>
        </div>
      </div>
    </div>
    <div class="device-flip" (click)="onDeviceSelected(1)" [ngClass]="flipCard(1)">
        <div class="device-container" >
          <!-- Front -->
          <div class="device-front">
            <div class="device">    
              <br/>
              <br/>       
              <div class="device__title">
                <h3>{{localModel.lbl_screenwise_meters_browser}}</h3>
                <br/>
                <br/>
              </div> 
             
              <div class="device__img">
                <img src="assets/images/devices/laptop.svg" alt="" />
              </div>              
            </div>
          </div>
          <!-- Back -->
          <div class="device-back">
            <div class="device">
              <div class="device__status">
                <img src="assets/images/icons/icon_close.svg" alt="" />
              </div>
              <div class="device__title">
                <h3 style="text-align:center;">{{localModel.lbl_screenwise_meters_browser}}</h3>
                  <br>
                  <div>
                    <a href="{{MigratePanel !== null ? localModel.lbl_screenwise_meters_title_beta : localModel.lbl_screenwise_meters_browser_link}}" target="_blank">
                      <img style="width: 9rem;"src="assets/images/deviceslogo/screenwise_meter_browser.png" alt="" />
                    </a> 
                  </div>
                </div>               
            </div>
          </div>
        </div>



      </div>
      <div class="device-flip" (click)="onDeviceSelected(2)" [ngClass]="flipCard(2)">
        <div class="device-container" >
          <!-- Front -->
          <div class="device-front">
            <div class="device">
              <br/>
              <br/>
              <div class="device__title">
                <h3>{{localModel.lbl_screenwise_meters_tab}}</h3>
                <br/>
                <br/><br/><br/>
              </div> 
              
              <div class="device__img">
                <img src="assets/images/devices/tablet.svg" alt="" />
              </div>
                           
            </div>
          </div>
          <!-- Back -->
          <div class="device-back">
            <div class="device">
              <div class="device__status">
                <img src="assets/images/icons/icon_close.svg" alt="" />
              </div>
              <div class="device__title">
                <h3 style="text-align:center;">{{localModel.lbl_screenwise_meters_tab}}</h3>
                <br>
                <div> 
                  <a href="{{MigratePanel !== null ? localModel.lbl_migratedPanel : localModel.lbl_phone_link}}" target="_blank">
                    <img style="width: 14rem;"src="assets/images/deviceslogo/google-play-badge.png" alt="" />
                  </a>
                  <br>
                  <a href="{{ localModel.lbl_iphone_link }}" target="_blank">
                    <img style="width: 12rem;" src="assets/images/deviceslogo/iOS_app_Store.svg" alt="" />
                  </a> 
              </div> 
              </div>                    
            </div>
          </div>
        </div>
      </div>
  </div>

</section>

