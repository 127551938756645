<!-- Top Notification panel -->
<section class="messaging signed-out" *ngIf="showNotificationMsg">    
    <div class="container"> 

        <div class="messaging__body" >
            <h3>{{ notificationHeader }}</h3>
            <p>{{ notificationContent }}</p>
        </div>

        <div class="messaging__actions">
            <div *ngIf="doubleBtnGrp">
                <button class="btn btn__primary" (click)="onConfirmDecline()">
                    <img src="assets/images/icons/icon_check.svg" alt="" />
                    {{ localModel.lbl_teenconsent_btn_yes }}
                </button>
                <button class="btn btn__primary" (click)="onCloseNotification()">
                    {{ localModel.lbl_teenconsent_btn_no }}
                </button>
            </div>

            <div *ngIf="singleBtnGrp">
                <button class="btn btn__primary" (click)="onCloseNotification()">
                    <img src="assets/images/icons/icon_check.svg" alt="" />
                    {{ localModel.lbl_teenconsent_btn_ok }}
                </button>
            </div>            
        </div>

    </div>
</section>

<!-- Teen Consent panel-->
<section class="messaging important-notification">
    <div class="container">

        <div class="messaging__body">

            <h2>{{ localModel.lbl_teen_consent_head }}</h2>

            <p>{{ localModel.lbl_teen_consent_body1 }}</p>
            <p>{{ localModel.lbl_teen_consent_body2 }}</p>
            <p>{{ localModel.lbl_teen_consent_body3 }}</p>

            <ul class="teen-content-list">
                <li>{{ localModel.lbl_teen_consent_body4 }}</li>
                <li>{{ localModel.lbl_teen_consent_body5 }}</li>
                <li>{{ localModel.lbl_teen_consent_body6 }}</li>
            </ul>

            <br />

            <p>{{ localModel.lbl_teen_consent_body7 }}</p>

            <div>
                <table class="table table-bordered" summary="teen">
                    <thead>
                        <tr>
                            <th id="teenconsentname">{{ localModel.lbl_teen_consent_name }}</th>
                            <th id="teenconsentdob">{{ localModel.lbl_teen_consent_dob }}</th>
                            <th id="teenconsnetaccept">{{ localModel.lbl_teen_consent_accept }}</th>
                            <th id="teenconsentdecline">{{ localModel.lbl_teen_consent_decline }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let teen of teenInfoList">
                            <td>{{ teen.NAME }}</td>
                            <td>{{ teen.DOB | date: "MMM dd, yyyy" }}</td>
                            <td><input type="radio" id="accept" [name]="teen.TEENMNO" [value]="1" [(ngModel)]="teen.CONSENT" (click)="onRadioBtnClick()" /> Accept</td>
                            <td><input type="radio" id="decline" [name]="teen.TEENMNO" [value]="2" [(ngModel)]="teen.CONSENT" (click)="onRadioBtnClick()" /> Decline</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="form-control">
                <input type="checkbox" name="declineAll" (click)="onCheckboxClick($event)" #chkbox /> {{ localModel.lbl_teen_consent_body8 }}
            </div>

        </div>

        <div class="messaging__actions">           
            <button class="btn btn__important" data-messaging-action="close" (click)="onSubmit()">
                {{ localModel.lbl_teen_consent_submit }}
            </button>
        </div>

    </div>
</section>

<!-- loading spinner -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.4)"></ngx-spinner>