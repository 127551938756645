<div class="panel" *ngIf="filteredTransactions.length>0 ">
    <h2>{{localModel.lbl_transHistory}}</h2>
    <table class="table table--transactions" id="transcationrewardhistory" summary="rewardhistory">
        <thead>
            <tr>
                <th scope="col">{{localModel.lbl_transType}}</th>
                <th scope="col"></th>
                <th scope="col">{{localModel.lbl_transDescription}}</th>
                <th scope="col">{{localModel.lbl_transDate}}</th>
                <th scope="col">{{localModel.lbl_transValue}}</th>
                <th class="text-right" scope="col">{{localModel.lbl_transView}}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let trans of filteredTransactions" >
                <td data-label="Type">{{ (trans.TransactionType == 0) ? localModel.lbl_transCredit : localModel.lbl_transDebit }}</td>
                <td></td>
                <td data-label="Description">{{ trans.Description }}</td>
                <td data-label="Date">{{trans.TransactionDate | date:'MM/dd/yyyy'}}
                </td>
                <td data-label="Value" class="transaction-value">
                    <i class="material-icons add" *ngIf="trans.TransactionType == 0">add</i>
                    <em *ngIf="trans.TransactionType == 1 || trans.TransactionType == -1" style="color: red; font-size: 1.25rem; padding-right: .3rem;">—</em> 
                    {{ (trans.TransactionType == 0) ? trans.Points : trans.Points * -1}}
                </td>
                <td class="actions">
                    <button type="button" class="btn btn--icon" data-action="show-modal" (click)="onSummaryDetail(trans,$event)" *ngIf="trans.TransactionType != -1 &&  trans.REDEEM_FLAG == 1" style="margin-left: -2px;">
                        <em class="material-icons">visibility</em>
                        <span>View</span>
                    </button>
                    <a *ngIf="trans.TransactionType != -1 && trans.Description == 'ENROLLMENT INCENTIVE AWARD' &&  trans.REDEEM_FLAG == 0" style="cursor: pointer; text-decoration: underline" (click)="RedirecttoADRPIN(trans)">{{localModel.lbl_Points_Redeem_pin}}</a>

                </td>
            </tr>
        </tbody>
    </table>

    <button type="button" class="btn btn__primary btn--load animate" (click)="showAllTransaction()" *ngIf="filteredTransactions.length > 5 && isShowButtonVisible">
        {{localModel.lbl_transShowal}}
    </button> 
    <button type="button" class="btn btn__primary btn--load animate" (click)="HideTransaction()" *ngIf="isButtonVisible">
        {{localModel.lbl_hidetranscation}}
    </button> 
</div>

<div id="myModal" name="myModel" class="modal">
    <div class="modal__dropsheet"></div>
  
    <div class="modal-content" id="custom-model-content" >
    
        <div class="dialog__close" data-modal="close" (click)="onCloseModel()">
            <img src="assets/images/icons/icon_close.svg" alt="" />
        </div>
        <br>
            <div class="dialog__header">
                <h4>{{localModel.lbl_trans}} <span>{{transactionId}}</span></h4>
            </div>
            <div class="dialog__body">
                <div class="data">
                    <div class="data__label">{{localModel.lbl_transTypee}}</div>
                    <div class="data__value" *ngIf="transactionType == 0">{{localModel.lbl_transCredit}}</div>
                    <div class="data__value" *ngIf="transactionType == 1">{{localModel.lbl_transDebit}}</div>
                </div>
                <div class="data">
                    <div class="data__label">{{localModel.lbl_transID}}</div>
                    <div class="data__value transID">{{transactionId}}</div>
                </div>
                <div class="data" *ngIf="transactionType == 0">
                    <div class="data__label">{{localModel.lbl_transPoint}}</div>
                    <div class="data__value transID">{{point_type}}</div>
                </div>
                <div class="data">
                    <div class="data__label">{{localModel.lbl_transProcessed}}</div>
                    <div class="data__value">
                      {{transactionDate | date: "MM/dd/yyyy"}}
                    </div>
                </div>
            </div>
        <div class="dialog__footer">
            <button type="button" class="btn btn__secondary btn--small" data-action="close-dialog" (click)="onCloseModel()">{{localModel.lbl_transClose}}
            </button>
        </div>
      </div>

</div>