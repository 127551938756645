import { Component, OnInit } from '@angular/core';
import { GetOtherHHModel, MyProfileModel } from 'src/app/model/myProfile.model';
import { MyProfileService } from 'src/app/services/myProfile.service';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-household-info',
  templateUrl: './household-info.component.html'
})
export class HouseholdInfoComponent implements OnInit {

    localModel: any;
    myProfile : MyProfileModel;
    otherHHMembers: MyProfileModel[] = [];
    constructor(private localizationService: LocalizationService, private myProfileService: MyProfileService) { 
    
  }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
    var getOtherHHModel= new GetOtherHHModel();
    this.myProfileService.getMemberProfile().subscribe(res => {
      this.myProfile = res.Data.MemberInfo;
    //  console.log(res.Data);
      
      getOtherHHModel.HouseHoldNo = this.myProfile.HouseHoldNo;
      getOtherHHModel.MNO = this.myProfile.MNO;
      this.myProfileService.getOtherHHMembers(getOtherHHModel).subscribe(res2 =>{
        
        this.otherHHMembers = res2.Data.Members;
      //  console.log(this.otherHHMembers);
      });
    });

    
  }

}
