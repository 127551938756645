import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MemberConsentInfo } from "src/app/model/memberConsentInfo.model";
import { MemberTeenConsentInfo } from "src/app/model/memberTeenConsentInfo.model";
import { Globals } from "src/app/shared/shared.global";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class TeenConsentService {

    apiBasePath: string;
    apiKey: string;
    httpHeaders: HttpHeaders;

    constructor(private http: HttpClient) {

        this.apiBasePath = environment.TeenConsent_API_Base_Path;
      //  this.apiKey = environment.TeenConsentAPIKey;

        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });
    }

    getTeenConsentInfo(mbrConsentInfo: MemberConsentInfo) {

        const httpConfig = {
            headers: this.httpHeaders
        };

        return this.http.post(this.apiBasePath + Globals.GetTeenConsentInfo, mbrConsentInfo, httpConfig);
    }

    postTeenConsentInfo(mbrTeenConsentInfo: MemberTeenConsentInfo) {

        const httpConfig = {
            headers: this.httpHeaders
        };

        return this.http.post(this.apiBasePath + Globals.PostTeenConsentInfo, mbrTeenConsentInfo, httpConfig);
    }

}