import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rewards-summary',
  templateUrl: './rewards-summary.component.html',
})
export class RewardsSummaryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
