<div>
    <h2 [innerHtml]="localModel.lbl_dashboard_yrdevicetitle"></h2>
    <p>{{localModel.lbl_dashboard_swtitleinfo}}</p>
    <div class="devices__wrapper">
        <div class="device-flip" *ngFor="let device of yourDevices;let i = index" (click)="onDeviceSelected(i)" [ngClass]="flipCard(i)">
            <div class="device-container">
                <div class="device-front">
                    <div class="device">
                        <div class="device__status">
                            <img [src]="device.deviceStatus ? 'assets/images/icons/icon_ok.svg' : 'assets/images/icons/icon_question.svg'" alt="" />
                        </div>
                        <div class="device__img">
                            <img [src]="device.deviceImageSrc" alt="" />
                        </div>
                        <div class="device__title">
                            <h3>{{device.deviceTitle}}</h3>
                        </div>
                        <div class="device__update">{{device.deviceUpdate}}
                            
                        </div>
                    </div>
                </div>
                <div class="device-back">
                    <div class="device">
                      <div class="device__status">
                        <img src="assets/images/icons/icon_close.svg" alt="" />
                      </div>
                      <div class="device__title">
                        <h3>{{device.deviceTitle}}</h3>
                      </div>
                      <div class="device__update">{{device.deviceUpdate}}</div>
                      <div class="device__actions">
                        <a [innerHTML]="device.deviceAction" class="btn btn__primary btn--device">
                        </a>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Device - ADD -->
        <div class="device-front modal-link">
            <div class="device device--add" (click)="onAddNewDevice()">
              <img src="assets/images/icons/icon_plus_large.svg" alt="" />
              <a class="modal-link">{{localModel.lbl_addadevice}}</a>
          </div>
        </div>    
    </div>  
</div>