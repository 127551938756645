import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalizationService } from '../services/localization.service';
import { MyProfileService } from '../services/myProfile.service';

@Component({
  selector: 'app-policies-legal',
  templateUrl: './policies-legal.component.html'
})
export class PoliciesLegalComponent implements OnInit {
  localModel: any;
  myProfile: any;
  isQuestion: boolean=false;

  constructor(private spinnerService:NgxSpinnerService,private localizationService: LocalizationService,private myProfileService: MyProfileService,private titleService:Title) { 
    this.localizationService.localizationChanged.subscribe(res => {
      this.spinnerService.show();
      setTimeout(() => {
          /** spinner ends after 5 seconds */
          this.spinnerService.hide();
      }, 500);
      this.localModel = res;
    });
  }

  ngOnInit(): void {   
    this.localModel = this.localizationService.getLocalization();
    this.titleService.setTitle(this.localModel.lbl_policies_browsertitle)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_policies_browsertitle)
      })

    this.myProfile = this.myProfileService.getProfile();
    if(this.myProfile!==null){
      this.isQuestion=true;
    }
  }

}
