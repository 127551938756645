import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";
import { NavigationEnd, Router } from '@angular/router';
import { PolicyData } from 'src/app/model/PolicyData';
import { IResponse } from 'src/app/model/Response/IResponse';
import { UserLogInfo } from 'src/app/model/UserInfo';
import { LocalizationService } from 'src/app/services/localization.service';
import { PolicyService } from 'src/app/services/policy/policy.service';
import {Location} from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter } from 'rxjs/internal/operators/filter';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-google-panel-privacy-policy-conent',
  templateUrl: './google-panel-privacy-policy-conent.component.html',
  styleUrls: ['./google-panel-privacy-policy-conent.component.css']
})
export class GooglePanelPrivacyPolicyConentComponent implements OnInit {

  localModel : any;
  policydata:PolicyData;
  userDetails:UserLogInfo;
  mno:number;
  isConsentGiven:boolean=false;
  isUserLogin:boolean=true;
  response:IResponse;
  previousUrl: any;
  teenMinAge: number;
  constructor(private titleService:Title,private localizationService:LocalizationService,private policySrv:PolicyService,private dtPipe:DatePipe,private route:Router,private location :Location,private spinnerService:NgxSpinnerService) { 
    //this.titleService.setTitle("My Profile: Screenwise");
    // this.teenMinAge= AppConfig.configurations.appSettings.TeenMinimumAge;
    this.teenMinAge= environment.TeenMinimumAge;
  }


  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();

    this.titleService.setTitle(this.localModel.lbl_policies_browsertitle)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_policies_browsertitle)
      })
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
    var userDataFromSession= this.localizationService.getCurrentUserVariable(); 
    this.userDetails = userDataFromSession;
    if(this.userDetails!==null){
      if(this.userDetails.Age > this.teenMinAge){
        this.isUserLogin=false;
        this.mno=this.userDetails.MNo      
        this.getPolicyData();
      }
    }

    this.route.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
      //  console.log('prev:', event.url);
        //this.previousUrl = event.url;
      });
  }
  async getPolicyData() {

    this.policySrv.getPolicyData(this.mno).subscribe(res => {
     // console.log(res);
      this.policydata = res;
      if(this.policydata.googlePrivacyPolicyAccepted === 1){
        this.isConsentGiven = true;
      }
      else{
        this.isConsentGiven = false;
      }
    });
  }
  insertData(status:number){
    this.policydata.respondentID=this.mno;
    this.policydata.PanelName=environment.PanelName;
    this.policydata.RequestFromAppl=environment.PanelName;
    this.policydata.cookiePolicyAccepted=-2;
    this.policydata.eulaAccepted=-2;
    this.policydata.privacyPolicyAccepted=-2
    this.policydata.termsnConditionAccepted=-2;
    this.policydata.googlePrivacyPolicyAccepted=status;
    this.postpanelpolicydata(this.policydata);
  }
  async postpanelpolicydata(data:any){
    this.spinnerService.show();
   
  this.policySrv.postpanelpolicydata(data).subscribe(res => {
    if(this.policydata.googlePrivacyPolicyAccepted === 1){
      this.isConsentGiven = true;
    }
    else{
      this.isConsentGiven = false;
    }

    this.previousUrl= this.localizationService.getSessionVariables('priviousroute');
      // this.location.back();
       this.route.navigateByUrl(this.previousUrl);
       this.localizationService.removesessionStorageVariable('priviousroute');
    this.spinnerService.hide();
  }); 
  }
}
