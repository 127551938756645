<section class="messaging signed-out">
    <div class="container">
        <div class="messaging__close" (click)="onClose()">
            <img src="assets/images/icons/icon_close.svg" alt="" />
        </div>
        <div class="messaging__body">
            <h2>{{localModel.lbl_signout_headerh2}}</h2>
            <p>{{localModel.lbl_signout_headerp}}</p>
        </div>

        <div class="messaging__actions">
            <button class="btn btn__primary" (click)="onClose()">
                <img src="assets/images/icons/icon_check.svg" alt="" />
                {{localModel.lbl_gotit}}
             
            </button>
        </div>
    </div>
</section>