
<!-- Hidden as per Mike's Comment -->
<div class="panel" hidden>
    
    <h2>{{localModel.lbl_yourhouseholdalsogettingstarted_reward}}</h2>

    <div class="earn-rewards-cards">
        <!-- 1 -->
        <div class="card-reward">
            <div class="card-reward--icon">
                <img src="assets/images/icons/icon_inspect.svg" alt="inspect icon"/>
            </div>
            <div class="card-reward--content" [innerHtml]="localModel.lbl_forseeingwheteryou">
                

                <p>{{localModel.lbl_youdidthank}}</p>
            </div>
            <div class="card-reward--amount">
                <p class="desktop-block-only">{{localModel.lbl_youearned}}</p>
                <div>
                    <span class="reward-currency">$</span>
                    <span class="reward-value">20</span>
                </div>
            </div>
        </div>

        <!-- 2 -->
        <div class="card-reward">
            <div class="card-reward--icon">
                <img src="assets/images/icons/icon_reward.svg" alt="icon reward" />
            </div>
            <div class="card-reward--content" [innerHtml]="localModel.lbl_forqualifyingtojoin">
                
                <p>{{localModel.lbl_arehereyouare}</p>
            </div>
            <div class="card-reward--amount">
                <p class="desktop-block-only">{{localModel.lbl_youearned}}</p>
                <div>
                    <span class="reward-currency">$</span>
                    <span class="reward-value">20</span>
                </div>
            </div>
        </div>

        <div class="card-reward">
            <div class="card-reward--icon">
                <img src="assets/images/devices/router.svg" alt="router" />
            </div>
            <div class="card-reward--content" [innerHtml]="localModel.lbl_forinstallingthescreenwisetvmeterrouter">
                
            </div>
            <div class="card-reward--amount">
                <p class="desktop-block-only">{{localModel.lbl_youearned}}</p>
                <div>
                    <span class="reward-value">50,000</span>
                </div>
                <p class="desktop-block-only">{{localModel.lbl_reward_points}</p>
            </div>
        </div>

        <div class="card-reward">
            <div class="card-reward--icon">
                <img src="assets/images/devices/meter.svg" alt="meter" />
            </div>
            <div class="card-reward--content" [innerHtml]="localModel.lbl_forinstallingthescreenwisetvmeter">
                
            </div>
            <div class="card-reward--amount">
                <p class="desktop-block-only">{{localModel.lbl_youearned}}</p>
                <div>
                    <span class="reward-value">50,000</span>
                </div>
                <p class="desktop-block-only">{{localModel.lbl_reward_points}}</p>
            </div>
        </div>
    </div>
</div>