import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-screenwisemeter',
  templateUrl: './screenwisemeter.component.html'
})
export class ScreenwisemeterComponent implements OnInit {
  index:number = -1;
  indexes: number[] =[];
  css:string;
  
  localModel: any;

  constructor(private localizationService: LocalizationService) { }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    this.MigratePanel();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
  }
  MigratePanel() {
    this.MigratePanel = JSON.parse(localStorage.getItem('currentuser')).MigratedPanel;
  }

  flipCard(index){
    if(this.indexes.includes(index)){
      this.css = "flip";
    }
    else
    {
      this.css = " ";
    }
    return this.css;
  }
  onDeviceSelected(index:number){
    if(this.indexes.includes(index)){
      const removedIndex = this.indexes.indexOf(index);
      this.indexes.splice(removedIndex,1);
    }
    else
    {
    this.indexes.push(index);
    }
  }  
}
