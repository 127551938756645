<!-- notification section -->
<app-important-notification *ngIf="showNotification" [header]="notificationHeader" [content]="notificationMsg"
    [showExtraButton]="showExtraNotificationBtn"
    (closeNotification)="onCloseNotification()">  
</app-important-notification>

<section class="my-rewards">
    <div class="container">
        <app-rewards-status [balance]="pointsBalance"></app-rewards-status>
        <app-rewards-redeem (btnClicked)="redeemRewards()"></app-rewards-redeem>
    </div>
</section>
<section class="transactions angular-2">
    <div class="container">
        <app-rewards-history></app-rewards-history>
    </div>
</section>
<section class="rewards-summary angular-2">
    <div class="container">
        <!-- Phase 2 -->
      
        <app-rewards-also-earn></app-rewards-also-earn>
        <app-how-you-can-earn></app-how-you-can-earn>
        <app-rewards-info (btnClicked)="redeemRewards()"></app-rewards-info>
    </div>  
</section>

<app-have-questions></app-have-questions>

<!-- loading spinner -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.4)"></ngx-spinner>