<section class="policy-content">
  <div>
    <p [innerHtml]="localModel.lbl_Ipsos_EULA__Policy"></p>
  </div> 
    <!-- You have consented... -->
    <div class="policy policy--consent">
      <div class="policy__title"></div>
      <div [hidden]="isUserLogin"  class="policy__content">
        <form *ngIf="!isConsentGiven"class="form form--consent">
            <button type="submit" class="btn btn__primary" [innerHtml]="localModel.lbl_Gppp_IAgree" (click)="insertData(1)"></button>
          </form>
          <p> {{localModel.lbl_Gppp_IAgree_Content}}
          </p>
          <div  *ngIf="isConsentGiven" class="policy-status">
            <img src="assets/images/icons/icon_arrow_right.svg" alt="" />
            {{localModel.btn_IpsosScrPnlHrdUsgRtnAgree_CONSENTED}}
          </div> 
    </div>  
    </div>
  </section>
  <ngx-spinner></ngx-spinner>