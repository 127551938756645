import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import{SignInService} from '../services/signin.service'


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private loginservice: SignInService
     
    ) {}
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) :boolean{
        this.loginservice.redirectUrl = state.url;
        var token = this.loginservice.gettoken();
        if(!token){
            // this.router.navigateByUrl("/signin");
            this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
        }
        
        return token;

    }
}