import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizationService } from 'src/app/services/localization.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
  localModel: any;
  isUserSignIn: boolean;
  constructor(private titleService:Title, private localizationService: LocalizationService,
    private route:Router, private activatedRoute:ActivatedRoute
    ) {
    this.localModel = this.localizationService.getLocalization();

    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle("Screenwise")
      })

    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
      this.titleService.setTitle("Screenwise");
    });
    
   }

  ngOnInit(): void {
 
  }
}

