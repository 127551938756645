import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-survey-completed',
  templateUrl: './survey-completed.component.html'
})
export class SurveyCompletedComponent implements OnInit {

  constructor(private activateRoute: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {

    if (sessionStorage.getItem('qToken')) {
      window.location.replace('/setpassword');
    }
    else {
      this.activateRoute.queryParams.subscribe(params => {
        if (params['ResponseId']) {
          sessionStorage.setItem('qResponseId', params['ResponseId']);
        }
        if (params['SurveyId']) {
          sessionStorage.setItem('qSurveyId', params['SurveyId']);
        }
        if (params['token'] && params['token'].length > 0) {
          sessionStorage.setItem('qToken', params['token']);
          window.location.replace('/SurveyCompleted');
        }
        else if (sessionStorage.getItem('qToken')) {
          window.location.replace('/setpassword');
        } 
        else {
          window.location.replace('/home');
        }
      });
    }
  }

}
