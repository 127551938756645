<section class="my-profile">
    <div class="container">
        <h1 [innerHtml]="localModel.Lbl_header_profileTitle"></h1>
        {{localModel.lbl_Gppp_LWC_Content11}}
        <app-my-info></app-my-info>
        <app-my-address-info></app-my-address-info>
        <app-my-meter-account id="google-screenwise-meter-account" fragment="google-screenwise-meter-account"></app-my-meter-account>
        <div ></div>
    </div>
</section>
         
    <app-policy></app-policy>
        <app-have-questions></app-have-questions>

<!-- loading spinner -->
<ngx-spinner bdColor="rgba(51, 51, 51, 0.4)"></ngx-spinner>

    