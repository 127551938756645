import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html'
})
export class GetStartedComponent implements OnInit {

  localModel: any;
  

  constructor(private localizationService: LocalizationService) { }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();

    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
      
    });
  }


}
