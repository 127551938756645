import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MemberTeenConsentInfo } from 'src/app/model/memberTeenConsentInfo.model';
import { TeenConsent } from 'src/app/model/teenConsent.model';
import { TeenConsentInfo } from 'src/app/model/teenConsentInfo.model';
import { LocalizationService } from 'src/app/services/localization.service';
import { TeenConsentService } from 'src/app/services/teen-consent/teenConsent.service';
import { ConsentOption } from 'src/app/shared/shared.global';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teen-consent',
  templateUrl: './teen-consent.component.html',
  styleUrls: ['./teen-consent.component.css']
})
export class TeenConsentComponent implements OnInit {

  showNotificationMsg: boolean;
  doubleBtnGrp: boolean;
  singleBtnGrp: boolean;
  notificationHeader: string;
  notificationContent: string;
  panelName: string;
  localModel: any;

  @ViewChild('chkbox', { static: false}) declineChkbox: ElementRef;

  @Input() guardianMNO: number;
  @Input() teenInfoList: TeenConsentInfo[];

  @Output() teenConsentMsgClose = new EventEmitter();

  constructor(private localizationService: LocalizationService, private teenConsentSrv: TeenConsentService, private spinnerSrv: NgxSpinnerService) {

    // initialize variables
    this.showNotificationMsg = false;
    this.doubleBtnGrp = false;
    this.singleBtnGrp = false;
    this.panelName = environment.PanelName;
  }

  ngOnInit(): void {

    // get localization content
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(result => {
      this.localModel = result;
    });
  }

  // on radio button click event
  onRadioBtnClick() {
    
    if (this.declineChkbox.nativeElement.checked) {
      this.declineChkbox.nativeElement.checked = false;
    }
  }

  // on checkbox click event
  onCheckboxClick(event: any) {

    if (event.target.checked) {
      this.teenInfoList.forEach(value => {
        value.CONSENT = ConsentOption.NotSelected;
      });
    }
  }

  // on consent submit 
  onSubmit() {

    // if decline all checkbox is checked
    if (this.declineChkbox.nativeElement.checked) {
      this.showNotification(this.localModel.lbl_teenconsent_confirm, this.localModel.lbl_teenconsent_msg_decline, true, false);
    }
    else {

      // if atleast single or more than single teen consent is not selected
      if (this.teenInfoList.filter( x => x.CONSENT == ConsentOption.NotSelected).length > 0) {
        this.showNotification(this.localModel.lbl_teenconsent_alert, this.localModel.lbl_teenconsent_msg_select, false, true);
      }
      // if atleast single or more than single teen consent is declined     
      else if (this.teenInfoList.filter( x => x.CONSENT == ConsentOption.Decline).length > 0) {
        this.showNotification(this.localModel.lbl_teenconsent_confirm, this.localModel.lbl_teenconsent_msg_decline, true, false);
      }
      // else post the teen consent
      else {
        this.postTeenConsent(false);
      }
    }
  }

  // post teen consent using service
  private postTeenConsent(declineAll: boolean) {

    this.spinnerSrv.show();

    let teenConsentList: TeenConsent[] = [];

    if (declineAll) {
      this.teenInfoList.forEach(value => {
        teenConsentList.push( { MNO: value.TEENMNO, Consent: ConsentOption.Decline });
      });
    }
    else {
      this.teenInfoList.forEach(value => {
        teenConsentList.push( { MNO: value.TEENMNO, Consent: value.CONSENT });
      });
    }   

    let mbrTeenConsentInfo = new MemberTeenConsentInfo();
    mbrTeenConsentInfo.GuaridanMNO = this.guardianMNO;
    mbrTeenConsentInfo.PanelIdName = this.panelName;
    mbrTeenConsentInfo.TeenMNO = teenConsentList;

    this.teenConsentSrv.postTeenConsentInfo(mbrTeenConsentInfo).subscribe((responseData: boolean) => {
      this.spinnerSrv.hide();
      this.closeTeenConsentMsg();
    },
    errorResponse => {

      this.spinnerSrv.hide();
      this.closeTeenConsentMsg();
    })
  }

  // dispay top notification banner
  private showNotification(header: string, msg: string, showDoubleBtn: boolean, showSingleBtn: boolean) {

    this.notificationHeader = header;
    this.notificationContent = msg;
    this.doubleBtnGrp = showDoubleBtn;
    this.singleBtnGrp = showSingleBtn;

    this.showNotificationMsg = true;
    window.scrollTo (0, 0);
  }

  // on confirm teen consent decline event
  onConfirmDecline() {
    this.postTeenConsent(this.declineChkbox.nativeElement.checked);
  }

  // hide the top notification banner
  onCloseNotification() {

    this.showNotificationMsg = false;
    this.notificationHeader = '';
    this.notificationContent = '';
    this.doubleBtnGrp = false;
    this.singleBtnGrp = false;    
  }

  // hide the teen consent message component
  private closeTeenConsentMsg() {

    this.onCloseNotification();
    this.teenConsentMsgClose.emit();
  }
}
