<!-- Panel: Other Holusehold Members -->
<div class="panel" *ngIf="otherHHMembers">
    <div class="panel__header">
       <h2>{{localModel.lbl_dash_OtherHHheader}}</h2>
    </div>
    <div class="panel__body">
       <ul class="list-names" *ngFor="let hh of otherHHMembers">
          <li><span class="first-name">{{hh.FirstName}}</span> {{hh.LastName}}</li>
       </ul>

       <p  [innerHtml]="localModel.lbl_dash_OtherHHNote"></p>
    </div>
 </div>