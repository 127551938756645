import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ContactData } from "src/app/model/contact.model";
import { Globals } from "src/app/shared/shared.global";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class SmtpEmailService {

    apiBasePath: string;
    apiKey: string;
    httpHeaders: HttpHeaders;

    constructor(private http: HttpClient) {

        this.apiBasePath = environment.SmtpEmail_API_Base_Path;      
    }

    sendEmail(contactInfo: ContactData,recaptchaValue:string) {
        this.httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Recaptcha'  :   recaptchaValue 
        });
        const httpConfig = {
            headers: this.httpHeaders
        };

        return this.http.post(this.apiBasePath + Globals.SendEmail, contactInfo, httpConfig);
    }

    sendEmailAfterLogin(contactInfo: ContactData) {
        return this.http.post(this.apiBasePath + Globals.SendEmail, contactInfo);
    }

}
