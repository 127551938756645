import { Component,EventEmitter,Output, OnInit } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';

@Component({
  selector: 'app-signout-header',
  templateUrl: './signout-header.component.html'
})
export class SignoutHeaderComponent implements OnInit {
  @Output() closeSignOutHeader = new EventEmitter<void>();
  localModel : any;
  constructor(private localizationService:LocalizationService) { 
    
    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
  
  }

  ngOnInit(): void {
  }

  onClose(){
    this.closeSignOutHeader.emit();
  }

}
