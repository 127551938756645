<section class="get-started">
    <div class="container">
      <div class="get-started__content">
        <h2>
          {{localModel.Lbl_sign_in_footermsg}}
        </h2>
        <a routerLink="/enroll" class="btn btn__primary">{{localModel.BtnLbl_sign_in_getstarted}}</a>
      </div>
      <div class="get-started__img">
        <img src="assets/images/home/get_started_person.png" alt="" />
      </div>
    </div>
  </section>
