import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserLogInfo } from '../model/UserInfo';
import { LocalizationService } from './localization.service';


@Injectable({ providedIn: 'root' })
export class RestrictAuthGuard implements CanActivate {
    userDetails: UserLogInfo;
    constructor(
        private router: Router,
        private localizationService:LocalizationService
    ) {}
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) :boolean{
       // if(this.loginservice.gettoken()){
            var userDataFromSession= this.localizationService.getCurrentUserVariable();
           //var userDataFromSession=sessionStorage.getItem('myProfile')
            this.userDetails = userDataFromSession;
            if (this.userDetails == null)
                return true;

            if (this.userDetails.UserLoginResult === -3) {
                if (!(this.localizationService.getRestrictVariable())) {
                return true;
                }
                this.router.navigate(['dashboard']);
                return false
            }
             if (state.url =='/changepassword'){
                    return true;
                }  
                this.router.navigate(['dashboard']);
                return false;    
    }
}
