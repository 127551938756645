import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LocalizationService } from 'src/app/services/localization.service';


@Component({
  selector: 'app-rewards-info',
  templateUrl: './rewards-info.component.html',
  styleUrls: ['./rewards-info.component.css']
})
export class RewardsInfoComponent implements OnInit {

  localModel: any;

  @Output() btnClicked = new EventEmitter();
    
  constructor(private localizationService: LocalizationService) { }

  ngOnInit(): void { 

    this.localModel = this.localizationService.getLocalization();
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
  }

  onButtonClick() {
    this.btnClicked.emit();
  }

}
