import { Directive } from "@angular/core";
import {
  AbstractControl,
  Validator,
  NG_VALIDATORS,
  ValidationErrors
} from "@angular/forms";
import { passwordValidator } from "./email-validator";
@Directive({
  selector: "[appEmail]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailValidatorDirective,
      multi: true
    }
  ]
})
export class EmailValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    return passwordValidator(control);
  }
}