import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LocalizationService } from '../services/localization.service';

@Component({
  selector: 'app-incentive-error',
  templateUrl: './incentive-error.component.html'
})
export class IncentiveErrorComponent implements OnInit {
  localModel: any;
  constructor(private localizationService: LocalizationService,private titleService:Title) { 
    this.localizationService.localizationChanged.subscribe(res => {
      this.localModel = res;
    });
  }

  ngOnInit(): void {
    this.localModel = this.localizationService.getLocalization();
    this.titleService.setTitle(this.localModel.lbl_home_title)
    
    this.localizationService.titleChanged.subscribe(res=>{
      this.titleService.setTitle(this.localModel.lbl_home_title)
      })
  }

}
